"use client"

import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react"
import { DateTime, Duration } from "luxon"
import { durationIgnoringZoneDifferences } from "../TimeLabels"
import { useDisplayedDateRangeReference } from "./DisplayedDateRangeContext"

// Type definitions for context values
type NavigationDateRefContextType = {
  navigationDateRef: React.MutableRefObject<DateTime>
}
type NavigationDateReadContextType = {
  navigationDate: DateTime
  recentlyManuallyChanged: boolean
}
type NavigationDateWriteContextType = {
  setNavigationDate: (newDate: DateTime, isManual?: boolean) => void
  jumpForwards: (duration: Duration) => void
  jumpBackwards: (duration: Duration) => void
  next: () => void
  previous: () => void
}

// Create the contexts
const NavigationDateRefContext = createContext<
  NavigationDateRefContextType | undefined
>(undefined)
const NavigationDateReadContext = createContext<
  NavigationDateReadContextType | undefined
>(undefined)
const NavigationDateWriteContext = createContext<
  NavigationDateWriteContextType | undefined
>(undefined)

// Provider component
export const NavigationDateProvider: React.FC<{
  now: DateTime
  children: React.ReactNode
}> = ({ now, children }) => {
  const [navigationState, setNavigationState] = useState<{
    navigationDate: DateTime
    recentlyManuallyChanged: boolean
  }>({
    navigationDate: now.startOf("day"),
    recentlyManuallyChanged: false,
  })

  const { dateRangeRef } = useDisplayedDateRangeReference()
  const navigationDateRef = useRef<DateTime>(navigationState.navigationDate)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const setNavigationDate = useCallback(
    (newDate: DateTime, isManual = false) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      setNavigationState({
        navigationDate: newDate.startOf("day"),
        recentlyManuallyChanged: isManual,
      })
      navigationDateRef.current = newDate

      if (isManual) {
        timeoutRef.current = setTimeout(() => {
          setNavigationState((prevState) => ({
            ...prevState,
            recentlyManuallyChanged: false,
          }))
        }, 1000)
      }
    },
    [],
  )

  const jumpForwards = useCallback(
    (duration: Duration) => {
      setNavigationDate(navigationState.navigationDate.plus(duration), false)
    },
    [navigationState.navigationDate, setNavigationDate],
  )

  const jumpBackwards = useCallback(
    (duration: Duration) => {
      setNavigationDate(navigationState.navigationDate.minus(duration), false)
    },
    [navigationState.navigationDate, setNavigationDate],
  )

  const next = useCallback(() => {
    const endDate = dateRangeRef.current[1]
    setNavigationDate(endDate, false)
  }, [dateRangeRef, setNavigationDate])

  const previous = useCallback(() => {
    const startDate = dateRangeRef.current[0]
    const endDate = dateRangeRef.current[1]
    const duration = durationIgnoringZoneDifferences(startDate, endDate)
    jumpBackwards(duration)
  }, [dateRangeRef, jumpBackwards])

  // Memoize context values to ensure stability
  const refContextValue = useMemo(
    () => ({ navigationDateRef }),
    [navigationDateRef],
  )
  const writeContextValue = useMemo(
    () => ({
      setNavigationDate,
      jumpForwards,
      jumpBackwards,
      next,
      previous,
    }),
    [setNavigationDate, jumpForwards, jumpBackwards, next, previous],
  )

  return (
    <NavigationDateRefContext.Provider value={refContextValue}>
      <NavigationDateWriteContext.Provider value={writeContextValue}>
        <NavigationDateReadContext.Provider value={navigationState}>
          {children}
        </NavigationDateReadContext.Provider>
      </NavigationDateWriteContext.Provider>
    </NavigationDateRefContext.Provider>
  )
}

// Hooks for consuming the contexts
export const useNavigationDateRef = () => {
  const context = useContext(NavigationDateRefContext)
  if (!context) {
    throw new Error(
      "useNavigationDateRef must be used within a NavigationDateProvider",
    )
  }
  return context
}

export const useNavigationDateRead = () => {
  const context = useContext(NavigationDateReadContext)
  if (!context) {
    throw new Error(
      "useNavigationDateRead must be used within a NavigationDateProvider",
    )
  }
  return context
}

export const useNavigationDateWrite = () => {
  const context = useContext(NavigationDateWriteContext)
  if (!context) {
    throw new Error(
      "useNavigationDateWrite must be used within a NavigationDateProvider",
    )
  }
  return context
}

/**
 * import { DateTime } from "luxon"
import { useCallback, useEffect, useMemo } from "react"
import { useNavigationDate } from "@daybridge/components"
import { shouldIgnoreEvent } from "../../_components/KeyboardShortcutCoordinator"
import { durationIgnoringZoneDifferences } from "../../../../../lib/datetime"

export const useTimeNavigation = (
  firstDisplayedDate: DateTime,
  lastDisplayedDate: DateTime,
  horizontal?: boolean,
) => {
  const { dispatch } = useNavigationDate()

  // Compute the jump distance in days, ensuring that the last visible element
  // remains visible after the jump (so there is some continuity in the UI).
  const delta = useMemo(() => {
    const diff = durationIgnoringZoneDifferences(
      firstDisplayedDate,
      lastDisplayedDate,
    )
    const adjustedDiff = horizontal
      ? diff.minus({ days: 1 })
      : diff.minus({ weeks: 1 })

    return Math.round(
      !horizontal
        ? adjustedDiff.as("days") < 7
          ? 7
          : adjustedDiff.as("days")
        : adjustedDiff.as("days") < 1
        ? 1
        : adjustedDiff.as("days"),
    )
  }, [firstDisplayedDate, lastDisplayedDate, horizontal])

  const goForward = useCallback(() => {
    dispatch({ type: "JUMP_FORWARDS", payload: { days: delta } })
  }, [dispatch, delta])

  const goBack = useCallback(() => {
    dispatch({ type: "JUMP_BACKWARDS", payload: { days: delta } })
  }, [dispatch, delta])

  const goTo = useCallback(
    (date: DateTime, manual?: boolean) => {
      dispatch({ type: "SET_NAVIGATION_DATE", payload: { date, manual } })
    },
    [dispatch],
  )

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (shouldIgnoreEvent(event)) {
        return
      }

      if (event.key === "ArrowLeft" && horizontal) {
        event.preventDefault()
        goBack()
      } else if (event.key === "ArrowRight" && horizontal) {
        event.preventDefault()
        goForward()
      } else if (event.key === "ArrowUp" && !horizontal) {
        event.preventDefault()
        goBack()
      } else if (event.key === "ArrowDown" && !horizontal) {
        event.preventDefault()
        goForward()
      }
    }

    document.body.addEventListener("keydown", handleKeyDown)
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown)
    }
  }, [horizontal, goBack, goForward])

  return useMemo(
    () => ({
      goForward,
      goBack,
      goTo,
    }),
    [goBack, goForward, goTo],
  )
}

 */

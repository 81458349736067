import { cn } from "@daybridge/cn"
import React from "react"

const EditorToolbarGroupFn = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children }, ref) => {
  const childArr = React.Children.map(children, (c) => c)
  if (!childArr || childArr.length === 0) return null

  return (
    <div ref={ref} className={cn("flex", className)}>
      <div className="mx-1 flex items-center gap-1">{children}</div>
    </div>
  )
})
EditorToolbarGroupFn.displayName = "ToolbarGroup"

export const EditorToolbarGroup = React.memo(
  EditorToolbarGroupFn,
) as typeof EditorToolbarGroupFn

"use client"

import React, { useCallback, useEffect, useRef, useState } from "react"
import { cn } from "@daybridge/cn"
import {
  useCombobox,
  IndexedItem,
  IndexedItemGroup,
  pathsAreEqual,
} from "@daybridge/combo"
import { AnimatePresence, Reorder, motion } from "framer-motion"
import { Root, Trigger, Content } from "@radix-ui/react-collapsible"
import {
  Root as PopoverRoot,
  Content as PopoverContent,
  Anchor as PopoverAnchor,
  Portal as PopoverPortal,
} from "@radix-ui/react-popover"
import { Spinner } from "../spinner/Spinner"
import { Icon } from "../icon/Icon"
import { Button } from "../button/Button"
import { MenuPage, MenuItemGroup, MenuItem } from "../_types/menu"
import { Label } from "../label/Label"
import { groupTitle } from "../_styles/group-title"
import { elevated } from "../_styles/elevated"
import { ring } from "../_styles/ring"
import { ComboboxItem } from "./ComboboxItem"
import { AnimateChangeInHeight } from "./AnimateHeight"

const createLoadingAnimation = (index: number) => {
  return {
    opacity: [0.2, 1, 0.2, 0.2],
    scale: [1, 0.95, 1, 1],
    transition: {
      times: [0, 0.2, 0.5, 1], // Adjust times to account for the initial 50ms
      duration: 1, // total duration including the initial pulse and decay
      delay: 0.1 * index, // stagger each animation by 250ms
      repeat: Infinity, // loop the animation forever
      repeatType: "loop" as const, // use a loop repeat type to start from the beginning each time
      ease: "easeOut", // apply a linear easing to keep the decay smooth
    },
  }
}

export type ComboboxProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "children"
> & {
  inputId?: string
  inputClassName?: string

  root?: MenuPage

  // Maximum height of the combobox
  maxHeight?: React.CSSProperties["maxHeight"]

  // Items to render at the bottom of the combobox, pinned to the bottom.
  pinnedItems?: (MenuItem & {
    selectionMode?: MenuItemGroup["selectionMode"]
  })[]

  // Replace the home icon with a custom icon.
  homeIcon?: string

  // Whether the input should be shown to the right of the breadcrumbs.
  inlineInput?: boolean

  // `onComplete` is a callback that is called when the combobox determines
  // that the user has completed their interaction. Useful for closing
  // comboboxes in a popover.
  onComplete?: () => void

  // The state of the input. Useful for controlled inputs.
  inputState?: [string, React.Dispatch<React.SetStateAction<string>>]

  // `resultsInPopover` is a boolean that determines whether the combobox
  // results should be rendered in a popover.
  resultsInPopover?: boolean

  // `hideSearchBox` is a boolean that determines whether the search box
  // should be hidden.
  hideSearchBox?: boolean

  // `autofocus` is a boolean that determines whether the combobox should
  // autofocus the input when it is rendered.
  autofocus?: boolean
}

const ComboboxFn = React.forwardRef(
  (props: ComboboxProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      className,
      inputId,
      inputClassName,
      root = { items: [] },
      pinnedItems,
      homeIcon,
      inlineInput,
      onComplete: _onComplete,
      maxHeight = `clamp(250px, calc(var(--radix-popper-available-height, 100%) - 4rem - ${
        ((props.pinnedItems?.length || 0) + 1) * 3
      }rem), 30vh)`,
      inputState,
      resultsInPopover,
      hideSearchBox,
      autofocus,
      ...rest
    } = props

    const scrollRef = useRef<HTMLDivElement>(null)

    const [inputFocused, setInputFocused] = useState(false)
    const onClick = useCallback(() => {
      setInputFocused(true)
    }, [])
    const onKeyDown = useCallback((e: React.KeyboardEvent) => {
      if (e.key === "Escape" || e.key === "Tab") {
        setInputFocused(false)
      } else {
        setInputFocused(true)
      }
    }, [])
    const onComplete = useCallback(() => {
      setInputFocused(false)
      _onComplete?.()
    }, [_onComplete])

    // Keep track of dragging
    const draggingRef = useRef(false)

    // Initialise combobox state management
    const combobox = useCombobox(
      root,
      onComplete,
      scrollRef,
      inputState,
      autofocus,
    )

    const onMouseDownOutside = useCallback(
      (e: Event) => {
        if (
          e.target instanceof Element &&
          e.target === combobox.inputRef.current
        ) {
          return
        }
        setInputFocused(false)
      },
      [combobox],
    )

    // Keep track of whether hovering over the pinned items
    const [hoveringPinnedItems, setHoveringPinnedItems] = useState(false)
    useEffect(() => {
      // If pinnedItems unmount, stop hovering
      if (!pinnedItems || pinnedItems.length === 0) {
        setHoveringPinnedItems(false)
      }
    }, [pinnedItems])

    // Get the no results & empty state configs
    const parent =
      combobox.path.length > 0
        ? combobox.path[combobox.path.length - 1]
        : undefined
    const emptyState = {
      icon:
        (parent?.children as MenuPage)?.emptyStateIcon ??
        (parent?.children as MenuPage)?.promptIcon ??
        root.emptyStateIcon ??
        root.promptIcon,
      title:
        (parent?.children as MenuPage)?.emptyStateTitle ?? root.emptyStateTitle,
      description:
        (parent?.children as MenuPage)?.emptyStateDescription ??
        root.emptyStateDescription,
      hue: (parent?.children as MenuPage)?.breadcrumbHue,
    }
    const noResultsState = {
      icon:
        (parent?.children as MenuPage)?.noResultsIcon ??
        (parent?.children as MenuPage)?.promptIcon ??
        root.noResultsIcon ??
        root.promptIcon,
      title:
        (parent?.children as MenuPage)?.noResultsTitle ?? root.noResultsTitle,
      description:
        (parent?.children as MenuPage)?.noResultsDescription ??
        root.noResultsDescription,
      hue: (parent?.children as MenuPage)?.breadcrumbHue,
    }
    const noResultsOrEmptyState =
      combobox.displayedItems.length === 0 && combobox.showingSearchResults
        ? noResultsState
        : combobox.itemsAndGroups.length === 0
        ? emptyState
        : undefined

    // Keep track of previous path when the path changes
    const path = combobox.path
    const previousPathRef = useRef(combobox.path)
    const previousPath = previousPathRef.current
    useEffect(() => {
      previousPathRef.current = combobox.path
    }, [combobox.path])

    // Whenever the path changes, make sure the last path segment is visible
    const breadcrumbsRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
      if (breadcrumbsRef.current) {
        breadcrumbsRef.current.scrollTo({
          left: breadcrumbsRef.current.scrollWidth,
          behavior: "smooth",
        })
      }
    }, [combobox.path])

    const effectivePromptIconBeforeDefault = parent
      ? (parent.children as MenuPage).promptIcon
      : root.promptIcon

    const effectivePromptIcon =
      effectivePromptIconBeforeDefault === null
        ? undefined
        : effectivePromptIconBeforeDefault ?? "Search"

    const renderItem = useCallback(
      (item: IndexedItem, reorderable?: boolean) => {
        const p = combobox.getItemProps(item)
        const menuItem = item as MenuItem

        const i = (
          <ComboboxItem
            {...p}
            key={p.key}
            data-highlighted={
              hoveringPinnedItems ? undefined : p["data-highlighted"]
            }
            onClick={(e) => {
              if (
                combobox.menuOpen ||
                draggingRef.current ||
                menuItem.renaming
              ) {
                // Don't trigger onClick events if the click
                // event came from within the menu or if
                // the user is dragging an item.
                return
              }
              p.onClick?.(e)
            }}
            mode="controlled"
            icon={menuItem.icon}
            title={item.title}
            description={menuItem.description}
            avatarUrl={menuItem.avatarUrl}
            avatarInitials={menuItem.avatarInitials}
            rightContent={
              !combobox.showingSearchResults ? menuItem.rightContent : undefined
            }
            hasSubMenu={
              item.children !== undefined &&
              (!item.expandChildrenAs ||
                item.expandChildrenAs?.mode === "combobox")
            }
            disabled={item.disabled}
            strikethrough={item.strikethrough}
            path={
              item.path
                ? combobox.showingSearchResults &&
                  !pathsAreEqual(item.path, path)
                  ? item.path
                  : undefined
                : undefined
            }
            selectionMode={item.group?.selectionMode}
            theme={menuItem.hue !== undefined ? "adaptive" : undefined}
            style={
              {
                "--hue": menuItem.hue ?? undefined,
              } as React.CSSProperties
            }
            searchStr={
              // Remove excess whitespace from the search string
              combobox.inputValue.replace(/\s+/g, " ").trim()
            }
            renaming={
              menuItem.renaming &&
              !!menuItem.onRename &&
              !!menuItem.onRenameCancel
            }
            onRename={(name: string) => {
              if (combobox.menuOpen || draggingRef.current) return
              combobox.inputRef.current?.focus()

              if (!name && !menuItem.allowEmptyName) {
                menuItem.onRenameCancel?.()
                return
              }

              return menuItem.onRename?.(name)
            }}
            onRenameCancel={() => {
              if (combobox.menuOpen || draggingRef.current) return
              combobox.inputRef.current?.focus()
              menuItem.onRenameCancel?.()
            }}
            maxNameLength={menuItem.maxNameLength}
            showDragHandle={reorderable}
          >
            {item.expandChildrenAs?.mode === "menu" &&
              item.expandChildrenAs?.component &&
              item.children &&
              item.children.items.length > 0 && (
                <item.expandChildrenAs.component
                  open={combobox.menuOpen === item.globalId}
                  onOpenChange={(open: boolean) => {
                    if (!open) {
                      combobox.setMenuOpen(undefined)
                    }
                  }}
                  side="right"
                  alignOffset={-12}
                  root={{ items: item.children.items }}
                  onCloseAutoFocus={(e: React.FocusEvent) => {
                    e.preventDefault()
                    if (menuItem.renaming) {
                      return
                    }
                    combobox.inputRef.current?.focus()
                  }}
                  onContentKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === "ArrowLeft") {
                      e.preventDefault()
                      e.stopPropagation()
                      combobox.setMenuOpen(undefined)
                      combobox.inputRef.current?.focus()
                    }
                  }}
                >
                  <Button
                    icon={
                      menuItem.expandChildrenAs?.icon ?? "EllipsisHorizontal"
                    }
                    variant="translucent"
                    className={cn(
                      "w-5",
                      "ml-2",
                      "opacity-30 group-data-[highlighted]/item:opacity-100 group-hover/item:opacity-100",
                      "aria-expanded:opacity-100",
                      "transition-[opacity] duration-200",
                      "rounded-md",
                      "border",
                      menuItem.hue !== undefined
                        ? "border-adaptive-tint text-adaptive-medium-contrast hover:bg-adaptive-tint-light"
                        : "border-tint",
                    )}
                    style={
                      {
                        "--hue": menuItem.hue ?? undefined,
                      } as React.CSSProperties
                    }
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      combobox.setMenuOpen(item.globalId)
                    }}
                    onMouseDown={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                  />
                </item.expandChildrenAs.component>
              )}
          </ComboboxItem>
        )

        if (reorderable && !menuItem.renaming) {
          return (
            <Reorder.Item
              as="div"
              value={item.id}
              key={p.key}
              onDragStart={() => {
                draggingRef.current = true
              }}
              onDragEnd={() => {
                setTimeout(() => {
                  draggingRef.current = false
                }, 10)
              }}
            >
              {i}
            </Reorder.Item>
          )
        }

        return i
      },
      [combobox, path, hoveringPinnedItems],
    )

    const renderGroup = useCallback(
      (group: IndexedItemGroup, index: number) => {
        const p = combobox.getGroupProps(group)
        const eg = group as MenuItemGroup

        if (group.items.length === 0) return null

        if (group.collapsible) {
          return (
            <Root {...p} key={p.key} className="group">
              {/* Top Separator */}
              {index > 0 && (
                <div
                  key={group.groupId + "-top-separator"}
                  role="separator"
                  className={cn("h-px", "-mx-1 my-1", "bg-tint-light")}
                />
              )}

              {/* Group Title */}
              {(group.title || group.collapsible) && (
                <div
                  key={group.groupId + "-title"}
                  className={cn(
                    groupTitle,
                    "group-data-[state=closed]:mb-3",
                    "transition-[margin] duration-200 ease-in-out",
                    "group",
                  )}
                >
                  {eg.icon && typeof eg.icon === "string" && (
                    <Icon
                      name={eg.icon}
                      className={cn(
                        "flex-shrink-0",
                        "text-low-contrast",
                        "w-3 h-3 mr-1.5",
                      )}
                    />
                  )}
                  <Trigger asChild>
                    <div className="self-stretch flex-1 flex items-center truncate leading-none">
                      <div className={cn("w-full truncate")}>{group.title}</div>
                    </div>
                  </Trigger>
                  <Trigger asChild>
                    <Button
                      variant="translucent"
                      icon="ChevronUp"
                      tabIndex={-1}
                      className={cn(
                        "w-5 flex-shrink-0",
                        "text-low-contrast group-hover:text-high-contrast",
                        "[&>svg]:transform [&>svg]:rotate-0",
                        "[&>svg]:transition-[transform] [&>svg]:duration-200",
                        "[&>svg]:data-[state=closed]:rotate-180",
                      )}
                    />
                  </Trigger>
                </div>
              )}

              <Content
                className={cn(
                  "data-[state=open]:animate-[expand-content_200ms_ease-in-out]",
                  "data-[state=closed]:animate-[collapse-content_200ms_ease-in-out]",
                  "overflow-hidden",
                )}
              >
                {group.onReorder && group.items.length > 1 ? (
                  <Reorder.Group
                    values={group.items.map((item) => item.id)}
                    onReorder={(values) =>
                      group.onReorder?.(group.groupId, values)
                    }
                  >
                    {group.items.map((item) => {
                      return renderItem(item, true)
                    })}
                  </Reorder.Group>
                ) : (
                  group.items.map((item) => {
                    return renderItem(item)
                  })
                )}
              </Content>

              {/* Bottom Separator if needed */}
              {combobox.itemsAndGroups.length > 1 &&
                index < combobox.itemsAndGroups.length - 1 &&
                !("groupId" in combobox.itemsAndGroups[index + 1]) && (
                  <div
                    key={group.groupId + "-bottom-separator"}
                    role="separator"
                    className={cn("h-px", "-mx-1 my-1", "bg-tint-light")}
                  />
                )}
            </Root>
          )
        }

        return (
          <div {...p} key={p.key}>
            {/* Top Separator */}
            {index > 0 && (
              <div
                role="separator"
                className={cn("h-px", "-mx-1 my-1", "bg-tint-light")}
              />
            )}

            {/* Group Title */}
            {(group.title || group.collapsible) && (
              <div className={groupTitle}>
                {eg.icon && typeof eg.icon === "string" && (
                  <Icon
                    name={eg.icon}
                    className={cn("flex-shrink-0", "w-2.5 h-2.5 mr-1")}
                  />
                )}
                <div className="flex-1 truncate leading-none">
                  {group.title}
                </div>
              </div>
            )}

            {group.onReorder && group.items.length > 1 ? (
              <Reorder.Group
                values={group.items.map((item) => item.id)}
                onReorder={(values) => group.onReorder?.(group.groupId, values)}
                initial={false}
              >
                {group.items.map((item) => {
                  return renderItem(item, true)
                })}
              </Reorder.Group>
            ) : (
              group.items.map((item) => {
                return renderItem(item)
              })
            )}

            {/* Bottom Separator if needed */}
            {combobox.itemsAndGroups.length > 1 &&
              index < combobox.itemsAndGroups.length - 1 &&
              !("groupId" in combobox.itemsAndGroups[index + 1]) && (
                <div
                  role="separator"
                  className={cn("h-px", "-mx-1 my-1", "bg-tint-light")}
                />
              )}
          </div>
        )
      },
      [combobox, renderItem],
    )

    const input = (
      <div
        className={cn(
          "relative",
          "transition-[background-color,border] duration-75 ease-in-out",
          inlineInput ? "flex flex-row flex-1" : "flex flex-col",
          !resultsInPopover
            ? ["border-b border-tint-light"]
            : [inputFocused && [elevated, "rounded-t-lg rounded-b-none"]],
        )}
      >
        {resultsInPopover && inputFocused && (
          <div className={cn(ring, "rounded-t-lg rounded-b-none")} />
        )}
        <div
          ref={breadcrumbsRef}
          className={cn(
            inlineInput
              ? [
                  "pl-2.5 self-stretch",
                  combobox.path.length > 0 ? "w-auto" : "w-0",
                ]
              : [
                  "px-2.5 w-full",
                  combobox.path.length > 0 ? "h-6 pb-0.5 mt-2.5" : "h-0",
                ],
            "flex flex-row items-center",
            "overflow-x-auto overflow-y-hidden no-scrollbars",
            "transition-[height,width,margin,padding] duration-200 ease-in-out",
          )}
        >
          {combobox.path.length > 0 && (
            <>
              <Button
                icon={homeIcon || "Home"}
                className="w-6 h-6 text-medium-contrast"
                variant="translucent"
                onClick={() => combobox.clearPath()}
                tabIndex={-1}
              />
              <Icon
                name="ChevronRight"
                className={cn(
                  "mr-1",
                  "w-2",
                  "text-low-contrast",
                  "flex-shrink-0",
                  "animate-[fade-in_200ms_ease-in-out]",
                )}
              />
            </>
          )}
          {combobox.path.map((item, index) => {
            const p = combobox.getBreadcrumbProps(item)
            const ea = item as MenuItem

            return (
              <React.Fragment key={p.key}>
                <Label
                  {...p}
                  theme={
                    ea.children?.breadcrumbHue !== undefined
                      ? "adaptive"
                      : undefined
                  }
                  key={p.key}
                  className={cn(
                    "max-w-32",
                    "flex-shrink-0",
                    "mr-1",
                    "text-sm",
                    p.onClick && "cursor-pointer",
                    "animate-[fade-in_200ms_ease-in-out]",
                  )}
                  style={
                    {
                      "--hue": ea.children?.breadcrumbHue ?? undefined,
                    } as React.CSSProperties
                  }
                />
                {(index < combobox.path.length - 1 || inlineInput) && (
                  <Icon
                    key={`chevron-${index}`}
                    name="ChevronRight"
                    className={cn(
                      index !== combobox.path.length - 1 && "mr-1",
                      "w-2",
                      "text-low-contrast",
                      "animate-[fade-in_200ms_ease-in-out]",
                      "flex-shrink-0",
                    )}
                  />
                )}
              </React.Fragment>
            )
          })}
        </div>
        <div
          className={cn(
            "relative",
            "flex-shrink-0",
            "flex flex-row items-center",
            "text-base",
            inlineInput ? "flex-1 min-w-64" : "w-full",
          )}
        >
          {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
          <input
            id={inputId}
            className={cn(
              "min-w-0",
              "block flex-1",
              "h-10",
              inlineInput ? "px-1" : "px-3.5",
              "bg-transparent",
              "text-high-contrast placeholder:text-low-contrast",
              inputClassName,
            )}
            {...combobox.inputProps}
            aria-expanded={
              resultsInPopover
                ? inputFocused
                : combobox.inputProps["aria-expanded"]
            }
          />
          <div
            aria-hidden={true}
            className={cn(
              "w-4 h-4",
              "mr-2.5",
              "text-low-contrast",
              "flex items-center justify-center",
              "flex-shrink-0",
            )}
          >
            {combobox.loading && combobox.displayedItems.length > 0 ? (
              <Spinner />
            ) : (
              effectivePromptIcon && (
                <Icon
                  name={effectivePromptIcon}
                  className={cn(
                    "w-3 h-3",
                    "mr-1",
                    "text-low-contrast",
                    "flex-shrink-0",
                  )}
                />
              )
            )}
          </div>
        </div>
      </div>
    )

    const content = (
      <AnimateChangeInHeight enabled={combobox.pageTransitioning}>
        <div
          ref={scrollRef}
          className={cn(
            "scroll",
            "w-full",
            "overflow-x-hidden overflow-y-auto",
            "no-scrollbars",
            "scroll-p-1",
          )}
          style={{ maxHeight }}
        >
          <ul
            {...combobox.containerProps}
            className={cn(
              "flex flex-col h-fit",
              "p-1",
              "overflow-hidden",
              "relative z-0",
            )}
          >
            <AnimatePresence
              mode="popLayout"
              custom={[path, previousPath]}
              initial={false}
            >
              <motion.div
                key={path.length > 0 ? path[path.length - 1].globalId : "root"}
                custom={[path, previousPath]}
                variants={{
                  enter: ([path, previousPath]: [
                    IndexedItem[],
                    IndexedItem[],
                  ]) => {
                    return {
                      x:
                        path.length > previousPath.length
                          ? "30%"
                          : path.length === previousPath.length
                          ? 0
                          : "-30%",

                      opacity: 0,
                    }
                  },
                  center: { x: 0, opacity: 1 },
                  exit: ([path, previousPath]: [
                    IndexedItem[],
                    IndexedItem[],
                  ]) => ({
                    x:
                      path.length > previousPath.length
                        ? "-30%"
                        : path.length === previousPath.length
                        ? 0
                        : "30%",
                    opacity: 0,
                  }),
                }}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{ ease: "easeInOut", duration: 0.2 }}
              >
                {combobox.loading && combobox.displayedItems.length === 0 ? (
                  Array.from({ length: 5 }, (_, index) => (
                    <motion.div
                      key={`div-${index}`}
                      initial={{ opacity: 0.2 }}
                      animate={createLoadingAnimation(index)}
                      className={cn(
                        "w-full h-8",
                        "mb-px",
                        "rounded-lg",
                        "bg-tint",
                      )}
                    />
                  ))
                ) : noResultsOrEmptyState ? (
                  <div
                    className={cn(
                      "w-full p-4",
                      "flex flex-col",
                      "items-center justify-center",
                    )}
                  >
                    {noResultsOrEmptyState.icon && (
                      <Icon
                        name={noResultsOrEmptyState.icon}
                        className={cn(
                          "w-6",
                          "mb-2",
                          "-rotate-6",
                          "animate-[wiggle_500ms_ease-in-out]",
                          noResultsOrEmptyState.hue !== undefined
                            ? "text-adaptive-primary"
                            : "text-tint-extra-heavy",
                        )}
                      />
                    )}
                    {noResultsOrEmptyState.title && (
                      <div
                        className={cn(
                          "text-base",
                          "text-medium-contrast",
                          "font-semibold",
                          "text-balance",
                        )}
                      >
                        {noResultsOrEmptyState.title}
                      </div>
                    )}
                    {noResultsOrEmptyState.description && (
                      <div
                        className={cn(
                          "text-sm",
                          "text-low-contrast",
                          "text-center",
                          "text-balance",
                        )}
                      >
                        {noResultsOrEmptyState.description}
                      </div>
                    )}
                  </div>
                ) : (
                  combobox.itemsAndGroups.map((item, index) => {
                    if (React.isValidElement(item)) return item

                    if ("groupId" in item) {
                      return renderGroup(item, index)
                    } else {
                      return renderItem(item as IndexedItem)
                    }
                  })
                )}
              </motion.div>
            </AnimatePresence>
          </ul>
        </div>
      </AnimateChangeInHeight>
    )

    const pinned = pinnedItems && pinnedItems.length > 0 && (
      <div
        className={cn(
          "flex flex-col flex-shrink-0",
          "border-t border-tint",
          "p-1",
        )}
        onMouseEnter={() => setHoveringPinnedItems(true)}
        onMouseLeave={() => setHoveringPinnedItems(false)}
      >
        {pinnedItems.map((item) => {
          return (
            // eslint-disable-next-line jsx-a11y/role-supports-aria-props
            <ComboboxItem
              role="button"
              tabIndex={0}
              key={item.id}
              icon={item.icon}
              title={item.title}
              avatarUrl={item.avatarUrl}
              avatarInitials={item.avatarInitials}
              description={item.description}
              disabled={item.disabled}
              strikethrough={item.strikethrough}
              aria-selected={item.selected}
              selectionMode={item.selectionMode}
              theme={item.hue !== undefined ? "adaptive" : undefined}
              style={
                {
                  "--hue": item.hue ?? undefined,
                } as React.CSSProperties
              }
              className={cn(
                "cursor-default",
                "focus-visible:ring-2 focus-visible:ring-state-info-primary",
              )}
              onClick={(e) => void item.onSelect?.(e, item)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault()
                  void item.onSelect?.(e, item)
                }
              }}
              mode="uncontrolled"
            />
          )
        })}
      </div>
    )

    if (resultsInPopover) {
      return (
        <PopoverRoot open={inputFocused}>
          <PopoverAnchor asChild onClick={onClick} onKeyDown={onKeyDown}>
            {input}
          </PopoverAnchor>
          <PopoverPortal>
            <PopoverContent
              align="start"
              side="bottom"
              sideOffset={0}
              onOpenAutoFocus={(e) => e.preventDefault()}
              onCloseAutoFocus={(e) => e.preventDefault()}
              onInteractOutside={onMouseDownOutside}
              asChild
            >
              <div
                ref={ref}
                className={cn(
                  "flex flex-col",
                  "overflow-hidden",
                  elevated,
                  "origin-top",
                  "rounded-t-none",
                  className,
                )}
                {...rest}
              >
                {content}
                <div className={cn(ring, "rounded-t-none")} />
              </div>
            </PopoverContent>
          </PopoverPortal>
        </PopoverRoot>
      )
    }

    return (
      <div
        ref={ref}
        className={cn(
          "w-full",
          "max-h-full",
          "min-h-0",
          "flex flex-col",
          "overflow-hidden",
          className,
        )}
        {...rest}
      >
        {!hideSearchBox && input}
        {content}
        {pinned}
      </div>
    )
  },
)
ComboboxFn.displayName = "Combobox"

export const Combobox = React.memo(ComboboxFn) as typeof ComboboxFn

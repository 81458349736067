"use client"

import React, { ComponentProps } from "react"
import { Root, Thumb } from "@radix-ui/react-switch"
import { cn } from "@daybridge/cn"
import { cva, VariantProps } from "class-variance-authority"

type SwitchProps = Omit<ComponentProps<typeof Root>, "children"> &
  VariantProps<typeof switchVariants> & {
    // Additional props here...
  }

const switchVariants = cva(
  [
    "w-8 aspect-[8/5]",
    "relative inline-flex flex-shrink-0",
    "p-1 rounded-full",
    "bg-tint-heavy",
    "cursor-pointer data-[disabled]:cursor-not-allowed",
    "data-[disabled]:opacity-50",
    "transition ease-in-out duration-200",
    "focus:outline-none",
    "focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-surface",
  ],
  {
    variants: {
      theme: {
        "brand-pink": [
          "aria-checked:bg-brand-pink-primary",
          "focus-visible:ring-brand-pink-primary",
        ],
        "brand-blue": [
          "aria-checked:bg-brand-blue-primary",
          "focus-visible:ring-brand-blue-primary",
        ],
        error: [
          "aria-checked:bg-state-error-primary",
          "focus-visible:ring-state-error-primary",
        ],
        success: [
          "aria-checked:bg-state-success-primary",
          "focus-visible:ring-state-success-primary",
        ],
        warning: [
          "aria-checked:bg-state-warning-primary",
          "focus-visible:ring-state-warning-primary",
        ],
        info: [
          "aria-checked:bg-state-info-primary",
          "focus-visible:ring-state-info-primary",
        ],
        adaptive: [
          "aria-checked:bg-gradient-to-b from-adaptive-solid-object-highlight to-adaptive-solid-object",
          "focus-visible:ring-adaptive-primary",
        ],
      },
    },
    defaultVariants: {
      theme: "brand-blue",
    },
  },
)

const SwitchFn = React.forwardRef(
  (props: SwitchProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const { className, theme, ...rest } = props
    return (
      <Root
        ref={ref}
        className={cn(switchVariants({ theme }), className)}
        {...rest}
      >
        <Thumb
          className={cn(
            "thumb",
            "relative inline-block",
            "w-[50%] aspect-square",
            "rounded-full",
            "bg-white",
            "pointer-events-none",
            "shadow",
            "transform",
            "translate-x-0",
            "data-[state=checked]:translate-x-[100%]",
            "transition ease-in-out duration-200",
            "text-high-contrast",
          )}
        />
        <div
          className={cn(
            "rounded-full",
            "absolute inset-0",
            "ring-1 ring-inset ring-tint-heavy",
            "pointer-events-none",
          )}
        />
      </Root>
    )
  },
)
SwitchFn.displayName = "Switch"

export const Switch = React.memo(SwitchFn) as typeof SwitchFn

"use client"

import React from "react"
import {
  Root,
  Viewport,
  Scrollbar,
  Thumb,
  Corner,
} from "@radix-ui/react-scroll-area"
import { motion } from "framer-motion"
import { cn } from "@daybridge/cn"

export interface ScrollAreaProps {
  children: React.ReactNode
  className?: string
  rootClassName?: string
}

const ScrollFn = React.forwardRef(
  (
    { children, className, rootClassName }: ScrollAreaProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const scrollbarCommonClassName = "flex p-1 select-none touch-none"
    const thumbClassName = "flex-1 bg-tint-extra-heavy rounded-full"

    return (
      <Root type="scroll" className={cn("flex-1 min-h-0", rootClassName)}>
        <Viewport
          ref={ref}
          className={cn(
            "h-full overflow-hidden",
            "[&>div]:table-fixed [&>div]:w-full",
            className,
          )}
        >
          {children}
        </Viewport>
        <Scrollbar
          asChild
          style={{ zIndex: 9999 }}
          className={`w-3 ${scrollbarCommonClassName}`}
          orientation="vertical"
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2 }}
          >
            <Thumb className={thumbClassName} />
          </motion.div>
        </Scrollbar>
        <Scrollbar
          asChild
          style={{ zIndex: 9999 }}
          className={`flex-col h-3 ${scrollbarCommonClassName}`}
          orientation="horizontal"
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2 }}
          >
            <Thumb className={thumbClassName} />
          </motion.div>
        </Scrollbar>
        <Corner />
      </Root>
    )
  },
)

ScrollFn.displayName = "Scroll"
export const Scroll = React.memo(ScrollFn) as typeof ScrollFn

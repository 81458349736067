import { cn } from "@daybridge/cn"
import React from "react"

type IconProps = {
  name: string
} & React.SVGProps<SVGSVGElement>

const IconFn = React.forwardRef(
  (props: IconProps, ref: React.ForwardedRef<SVGSVGElement>) => {
    const { name: rawName, className, ...rest } = props

    // POLYFILL: Until we can rename all icons to snake case
    const name = rawName
      ?.replace(/([A-Z])/g, "_$1")
      .toLowerCase()
      .replace(/^_/, "")

    return (
      <svg ref={ref} className={cn("aspect-square", className)} {...rest}>
        <use
          className="text-current"
          xlinkHref={`/icons/sprites.svg#${name}`}
        />
      </svg>
    )
  },
)
IconFn.displayName = "Icon"

export const Icon = React.memo(IconFn) as typeof IconFn

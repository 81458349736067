import { cn } from "@daybridge/cn"

export const groupTitle = cn(
  "mx-2.5",
  "h-3",
  "mt-3 mb-2",
  "flex flex-row items-center",
  "text-xs",
  "font-semibold",
  "text-medium-contrast",
  "uppercase",
)

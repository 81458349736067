"use client"

import React, {
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import {
  ReactCrop,
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from "react-image-crop"
import { cn } from "@daybridge/cn"
import { Modal } from "../modal/Modal"
import { Button } from "../button/Button"

export interface ImageCropperImperativeHandle {
  crop: (dataUrl: string) => void
}

type ImageCropperProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "children" | "onError"
> & {
  size: number
  aspectRatio?: number
  circular?: boolean
  onSave?: (image: Blob) => void | Promise<void>
  onError?: (message: string) => void
  t: (
    key: "cancel" | "crop_and_save" | "browser_cropping_error" | "size_error",
    args?: Record<string, string | number>,
  ) => string
}

const ImageCropperFn = React.forwardRef(
  (props: ImageCropperProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      className,
      size,
      aspectRatio,
      circular,
      onSave,
      onError,
      t,
      ...rest
    } = props

    const [dataUrl, setDataUrl] = React.useState<string | null>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [isLoading, setIsLoading] = useState(false)
    const [imageWidth, setImageWidth] = useState(0)
    const [imageHeight, setImageHeight] = useState(0)

    useImperativeHandle(
      ref as React.ForwardedRef<ImageCropperImperativeHandle>,
      () => ({
        crop: (dataUrl: string) => {
          setDataUrl(dataUrl)
        },
      }),
    )

    const onClose = useCallback(() => {
      setDataUrl(null)
      setIsLoading(false)
      setCrop(undefined)
      setCompletedCrop(undefined)
      setImageHeight(0)
      setImageWidth(0)
    }, [])

    const centerAspectCrop = useCallback(
      (mediaWidth: number, mediaHeight: number, aspect: number) => {
        return centerCrop(
          makeAspectCrop(
            {
              // Make the initial crop 98% of the image, to leave some space for
              // the drag handles.
              unit: "%",
              width: 98,
            },
            aspect,
            mediaWidth,
            mediaHeight,
          ),
          mediaWidth,
          mediaHeight,
        )
      },
      [],
    )

    const handleSave = useCallback(async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current) {
        setIsLoading(true)
        try {
          const img = await cropToImage(imgRef.current, completedCrop, size, t)
          if (!img) {
            setIsLoading(false)
            return
          }

          try {
            await onSave?.(img)
            onClose()
          } catch (err) {
            // Catch this separately - this shouldn't invoke the error handler
            // as it should be handled by the upload hook instead.
            setIsLoading(false)
          }
        } catch (err) {
          setIsLoading(false)
          void onError?.(
            typeof err === "string"
              ? err
              : (err as { message: string })?.message,
          )
        }
      }
    }, [completedCrop, onError, onSave, onClose, size, t])

    const onImageLoad = useCallback(
      (e: React.SyntheticEvent<HTMLImageElement>) => {
        const { clientWidth, clientHeight } = e.currentTarget
        setCrop(centerAspectCrop(clientWidth, clientHeight, aspectRatio || 1))
        setImageWidth(clientWidth)
        setImageHeight(clientHeight)
      },
      [centerAspectCrop, aspectRatio],
    )

    return (
      <div ref={ref}>
        <Modal
          open={!!dataUrl}
          content={
            <div className={cn("relative z-[1000]", className)} {...rest}>
              <div className="p-0.5 flex flex-col relative">
                <ReactCrop
                  className="relative overflow-hidden flex-1 rounded-xl"
                  style={{
                    maxHeight: "50vh",
                    aspectRatio: `${imageWidth}/${imageHeight}`,
                  }}
                  ruleOfThirds
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspectRatio || 1}
                  circularCrop={circular === undefined ? true : circular}
                >
                  {dataUrl && (
                    <img
                      ref={imgRef}
                      alt="Avatar"
                      src={dataUrl}
                      onLoad={onImageLoad}
                    />
                  )}
                </ReactCrop>
                <div className="flex justify-end flex-shrink-0 space-x-2 items-center p-4">
                  <Button onClick={onClose} variant="translucent">
                    {t("cancel")}
                  </Button>
                  <Button
                    onClick={() => void handleSave()}
                    variant="solid"
                    theme="brand-blue"
                    disabled={isLoading}
                  >
                    {t("crop_and_save")}
                  </Button>
                </div>
                {isLoading && (
                  <div className="absolute inset-0 flex items-center justify-center rounded-xl bg-white opacity-50" />
                )}
              </div>
            </div>
          }
        />
      </div>
    )
  },
)
ImageCropperFn.displayName = "ImageCropper"

export const ImageCropper = React.memo(ImageCropperFn) as typeof ImageCropperFn

export function cropToImage(
  image: HTMLImageElement,
  crop: PixelCrop,
  size: number,
  t: (
    key: "cancel" | "crop_and_save" | "browser_cropping_error" | "size_error",
    args?: Record<string, string | number>,
  ) => string,
): Promise<Blob | null> {
  const canvas = document?.createElement("canvas")
  const ctx = canvas?.getContext("2d")

  if (!ctx) {
    // Canvas not supported.
    throw new Error(t("browser_cropping_error"))
  }

  // Enable image smoothing.
  ctx.imageSmoothingEnabled = true
  ctx.imageSmoothingQuality = "high"

  // Set the canvas size.
  canvas.width = size
  canvas.height = size

  // Calculate the crop scaling factor.
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height

  const finalWidth = crop.width * scaleX
  const finalHeight = crop.height * scaleY

  if (finalWidth < size || finalHeight < size) {
    throw new Error(t("size_error", { size }))
  }

  // Draw the image to the canvas.
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    finalWidth,
    finalHeight,
    0,
    0,
    size,
    size,
  )

  // Return the base64 data.
  return new Promise((resolve) => {
    canvas.toBlob(resolve, "image/jpeg")
  })
}

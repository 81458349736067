"use client"

import React from "react"
import { Root, Trigger, Content, Arrow, Portal } from "@radix-ui/react-tooltip"
import { cn } from "@daybridge/cn"
import { Shortcut } from "../shortcut/Shortcut"

/**
 * -----------------
 * Tooltip component
 * -----------------
 *
 * This component is a wrapper around the Radix Tooltip component
 * that sets some consistent settings.
 *
 * Component docs:
 * https://www.radix-ui.com/docs/primitives/components/tooltip
 */

export interface TooltipProps {
  open?: boolean
  onOpenChange?: (open: boolean) => void

  // For children we expect a single React element which will
  // be used as the element to which the tooltip is attached.
  children: React.ReactElement

  // The content of the tooltip. If not provided then no
  // tooltip will render.
  content?: React.ReactNode

  // If a tooltip is pointing to an element that has a keyboard
  // shortcut associated with it, you can provide the shortcut hint
  // here and it will be displayed in the tooltip.
  shortcut?: {
    text: string
    keyboard?: string | string[] | string[][]
  }

  // Optional `aria-label`. A description of the tooltip contents.
  // By default, the contents of the tooltip will be read out.
  "aria-label"?: string

  // Optional `side`. Note that this will be reversed if necessary
  // to avoid collisions with the edge of the window.
  // Defaults to bottom.
  side?: "top" | "right" | "bottom" | "left"

  // Optional `sideOffset`. The distance in pixels from the edge of the
  // trigger element to the tooltip.
  sideOffset?: number

  // Optional alignment of the tooltip.
  // Defaults to center
  align?: "start" | "center" | "end"

  // Optional `alignOffset`. The distance from the start/end alignment positions.
  alignOffset?: number

  // Additional classes to apply to the tooltip body. Ignored if `headless` is true.
  className?: string

  // Whether or not to render an arrow on the tooltip.
  arrow?: boolean

  updatePositionStrategy?: "optimized" | "always"
}

// DEFAULT_SIDE_OFFSET is the default distance between the
// tooltip and the trigger element. It can be overriden.
const DEFAULT_SIDE_OFFSET = 4

// COLLISION_TOLERANCE is a buffer around the edge of the screen
// to stop tooltips getting too close to the edge.
const COLLISION_PADDING = 8

const TooltipFn = React.forwardRef(
  (props: TooltipProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      open,
      onOpenChange,
      children,
      content,
      shortcut,
      "aria-label": ariaLabel,
      side,
      sideOffset,
      align,
      alignOffset,
      className,
      arrow = true,
      updatePositionStrategy,
      // Capture the rest of the props. We will need to pass these through
      // to the trigger component in order to make sure we can compose
      // tooltips with popovers etc.
      ...rest
    } = props

    return content ? (
      <Root open={open} onOpenChange={onOpenChange}>
        <Trigger ref={ref} asChild {...rest}>
          {children}
        </Trigger>
        <Portal>
          <Content
            arrowPadding={16}
            asChild={true}
            side={side}
            sideOffset={
              sideOffset === undefined ? DEFAULT_SIDE_OFFSET : sideOffset
            }
            align={align}
            alignOffset={alignOffset}
            collisionPadding={COLLISION_PADDING}
            className="
              origin-radix-tooltip group
              data-[state=delayed-open]:animate-[fade-and-scale-in_50ms_ease-in] 
              data-[state=closed]:animate-[fade-and-scale-out_50ms_ease-in]
            "
            updatePositionStrategy={updatePositionStrategy}
          >
            <div>
              <div
                className={cn(
                  "px-3 py-1",
                  "text-sm font-medium",
                  "bg-elevated text-high-contrast",
                  "border border-tint",
                  "rounded-md",
                  "shadow-xs",
                  "pointer-events-none",
                  className,
                )}
              >
                {/* Main arrow */}
                {arrow && (
                  <Arrow
                    className="arrow-stroke text-elevated relative stroke-tint"
                    fill="currentColor"
                    strokeWidth={3}
                    offset={8}
                  />
                )}
                {/* Purely white arrow shifted down to hide the border */}
                {arrow && (
                  <Arrow
                    className="arrow-fill text-elevated relative -top-[2px] stroke-elevated"
                    fill="currentColor"
                    strokeWidth={3}
                    offset={8}
                  />
                )}
                <div className="flex items-center justify-center">
                  <span>{content}</span>
                  {shortcut && (
                    <div className="h-5 flex items-center">
                      <Shortcut
                        className="ml-2 text-xs -mr-2"
                        keyboard={shortcut.keyboard}
                      >
                        {shortcut.text}
                      </Shortcut>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Content>
        </Portal>
      </Root>
    ) : (
      // If no content is provided, just render the children
      // without the tooltip. This allows us to write cleaner
      // code where tooltips might be used conditionally.
      children
    )
  },
)
TooltipFn.displayName = "Tooltip"

export const Tooltip = React.memo(TooltipFn) as typeof TooltipFn

"use client"

import { DateTime } from "luxon"
import React, { createContext, useContext } from "react"

// We set up a context for the current time cursor so that we can
// ensure that all cursors get access to the same time. If they didn't
// then the cursors could be out of sync by a few pixels.
export type CurrentTimeCursorContext = {
  position: number
  now: DateTime
}
const currentTimeCursor = createContext<CurrentTimeCursorContext>({
  now: DateTime.local(),
  position: 0,
})
export const CurrentTimeCursorProvider = currentTimeCursor.Provider
export const CurrentTimeCursorConsumer = currentTimeCursor.Consumer
export const useCurrentTimeCursor = () => useContext(currentTimeCursor)

type CurrentTimeCursorProps = {
  // Whether the cursor is active (i.e. whether the line should be tinted)
  active?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const CurrentTimeCursorFn = React.forwardRef(
  (props: CurrentTimeCursorProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { className, style, active, ...rest } = props

    return (
      <CurrentTimeCursorConsumer>
        {({ position }) => (
          <>
            <div
              className={`
                w-full h-px z-40
                ${
                  active
                    ? "bg-gradient-to-r from-adaptive-tint-heavy via-adaptive-primary to-adaptive-tint-heavy"
                    : "bg-adaptive-tint-heavy"
                }
                absolute
                pointer-events-none
                ${className || ""}
              `}
              style={{
                top: `${position}%`,
                ...style,
              }}
              ref={ref}
              {...rest}
            />
          </>
        )}
      </CurrentTimeCursorConsumer>
    )
  },
)
CurrentTimeCursorFn.displayName = "CurrentTimeCursor"

export const CurrentTimeCursor = React.memo(
  CurrentTimeCursorFn,
) as typeof CurrentTimeCursorFn

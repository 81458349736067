"use client"

import React from "react"
import {
  Plate,
  PlateContent,
  PlateContentProps,
  PlateEditor,
  PlateProps,
  PlateRenderElementProps,
  RenderAfterEditable,
  TElement,
  Value,
  createPlateEditor,
  createPlugins,
  deserializeHtml,
} from "@udecode/plate-common"
import { createComboboxPlugin } from "@udecode/plate-combobox"
import { createEmojiPlugin } from "@udecode/plate-emoji"
import { serializeHtml } from "@udecode/plate-serializer-html"
import {
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
  createBoldPlugin,
  createItalicPlugin,
  createStrikethroughPlugin,
  createUnderlinePlugin,
} from "@udecode/plate-basic-marks"
import { createParagraphPlugin } from "@udecode/plate-paragraph"
import { cn } from "@daybridge/cn"
import {
  AutoformatRule,
  createAutoformatPlugin,
} from "@udecode/plate-autoformat"
import { Tooltip } from "../tooltip/Tooltip"
import { EmojiCombobox } from "./EmojiCombobox"
import { EditorToolbar } from "./toolbar/EditorToolbar"
import { EditorMarkToolbarButton } from "./toolbar/EditorMarkToolbarButton"
import { EditorToolbarGroup } from "./toolbar/EditorToolbarGroup"

export type EditorValue = Value

type EditorProps = Omit<PlateContentProps, "onChange" | "value"> &
  React.RefAttributes<HTMLDivElement> &
  Pick<PlateProps, "value" | "onChange" | "readOnly"> & {
    boldTooltip: string
    italicTooltip: string
    underlineTooltip: string
    strikethroughTooltip: string
    alwaysShowToolbar?: boolean
  }

const autoformatRules = [
  {
    mode: "mark",
    type: [MARK_BOLD, MARK_ITALIC],
    match: "***",
  },
  {
    mode: "mark",
    type: [MARK_UNDERLINE, MARK_ITALIC],
    match: "__*",
  },
  {
    mode: "mark",
    type: [MARK_UNDERLINE, MARK_BOLD],
    match: "__**",
  },
  {
    mode: "mark",
    type: [MARK_UNDERLINE, MARK_BOLD, MARK_ITALIC],
    match: "___***",
  },
  {
    mode: "mark",
    type: MARK_BOLD,
    match: "**",
  },
  {
    mode: "mark",
    type: MARK_UNDERLINE,
    match: "__",
  },
  {
    mode: "mark",
    type: MARK_ITALIC,
    match: "*",
  },
  {
    mode: "mark",
    type: MARK_ITALIC,
    match: "_",
  },
  {
    mode: "mark",
    type: MARK_STRIKETHROUGH,
    match: "~~",
  },
] as AutoformatRule[]

const plugins = createPlugins([
  createComboboxPlugin(),
  createEmojiPlugin({
    renderAfterEditable: EmojiCombobox as RenderAfterEditable,
  }),

  // Marks
  createBoldPlugin({
    component: ({
      attributes,
      nodeProps,
      children,
    }: PlateRenderElementProps) => (
      <b {...attributes} {...nodeProps}>
        {children}
      </b>
    ),
  }),
  createItalicPlugin({
    component: ({
      attributes,
      nodeProps,
      children,
    }: PlateRenderElementProps) => (
      <i {...attributes} {...nodeProps}>
        {children}
      </i>
    ),
  }),
  createUnderlinePlugin({
    component: ({
      attributes,
      nodeProps,
      children,
    }: PlateRenderElementProps) => (
      <u {...attributes} {...nodeProps}>
        {children}
      </u>
    ),
  }),
  createStrikethroughPlugin({
    component: ({
      attributes,
      nodeProps,
      children,
    }: PlateRenderElementProps) => (
      <del {...attributes} {...nodeProps}>
        {children}
      </del>
    ),
  }),

  // Basic Elements
  createParagraphPlugin({
    component: ({
      attributes,
      nodeProps,
      children,
    }: PlateRenderElementProps) => (
      <p {...attributes} {...nodeProps}>
        {children}
      </p>
    ),
  }),

  // Auto format
  createAutoformatPlugin({
    options: {
      rules: autoformatRules,
      enableUndoOnDelete: true,
    },
  }),
])

const EditorFn = React.forwardRef(
  (props: EditorProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      className,
      value,
      onChange,
      boldTooltip,
      italicTooltip,
      underlineTooltip,
      strikethroughTooltip,
      readOnly,
      alwaysShowToolbar,
      ...rest
    } = props
    const [showToolbarState, setShowToolbar] = React.useState(false)
    const showToolbar = alwaysShowToolbar || showToolbarState

    return (
      <Plate
        value={value}
        plugins={plugins}
        onChange={onChange}
        readOnly={readOnly}
      >
        <div
          onFocus={() => setShowToolbar(true)}
          onBlur={() => setShowToolbar(false)}
          className={className}
        >
          <PlateContent readOnly={readOnly} ref={ref} {...rest} />
          <div
            className={cn(
              showToolbar
                ? "h-6 opacity-100 mt-3 scale-100"
                : "h-0 my-0 opacity-0 scale-50",
              "origin-top-left",
              "overflow-hidden",
              "transition-[height,opacity,margin,transform] duration-300 ease-in-out",
            )}
            aria-hidden={!showToolbar}
          >
            <EditorToolbar>
              <EditorToolbarGroup>
                <Tooltip side="bottom" content={boldTooltip}>
                  <EditorMarkToolbarButton
                    nodeType={MARK_BOLD}
                    icon="Bold"
                    aria-label={boldTooltip}
                  />
                </Tooltip>
                <Tooltip side="bottom" content={italicTooltip}>
                  <EditorMarkToolbarButton
                    nodeType={MARK_ITALIC}
                    icon="Italic"
                    aria-label={italicTooltip}
                  />
                </Tooltip>
                <Tooltip side="bottom" content={underlineTooltip}>
                  <EditorMarkToolbarButton
                    nodeType={MARK_UNDERLINE}
                    icon="Underline"
                    aria-label={underlineTooltip}
                  />
                </Tooltip>
                <Tooltip side="bottom" content={strikethroughTooltip}>
                  <EditorMarkToolbarButton
                    nodeType={MARK_STRIKETHROUGH}
                    icon="Strikethrough"
                    aria-label={strikethroughTooltip}
                  />
                </Tooltip>
              </EditorToolbarGroup>
            </EditorToolbar>
          </div>
        </div>
      </Plate>
    )
  },
)
EditorFn.displayName = "Editor"

export const Editor = React.memo(
  EditorFn,
) as React.ForwardRefExoticComponent<EditorProps>

export const serializeEditorValue = (value?: EditorValue) => {
  if (!value) {
    return undefined
  }
  const tmpEditor = createPlateEditor({
    plugins,
  }) as PlateEditor
  return serializeHtml(tmpEditor, {
    nodes: value,
    stripDataAttributes: true,
    stripWhitespace: false,
    convertNewLinesToHtmlBr: true,
  })
}

export const deserializeEditorValue = (
  html?: string,
): EditorValue | undefined => {
  if (!html) {
    return undefined
  }

  const tmpEditor = createPlateEditor({
    plugins,
  }) as PlateEditor
  return deserializeHtml(tmpEditor, {
    element: html,
    collapseWhiteSpace: true,
  }) as TElement[]
}

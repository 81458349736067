"use client"

import { cn } from "@daybridge/cn"
import { range } from "lodash"
import React, { useEffect, useState } from "react"
import { Thumb, Root, Track } from "@radix-ui/react-slider"
import { Icon } from "../icon/Icon"

type ColorPickerProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "children"
> & {
  linear?: boolean
  showCustomOption?: boolean
  selectedHue?: number
  onHueSelect?: (hue: number) => void
}

const huePresets = range(0, 360, 30)

const ColorPickerFn = React.forwardRef(
  (props: ColorPickerProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      className,
      selectedHue,
      onHueSelect,
      linear,
      showCustomOption = true,
      ...rest
    } = props
    const hueButton = cn(
      "p-[30%]",
      "aspect-square rounded-full",
      "bg-adaptive-primary",
      "ring-1 ring-inset ring-tint",
      "hover:filter hover:brightness-110",
      "focus:filter focus:brightness-110",
      "transition-all duration-200 ease-in-out",
    )

    const [forceCustom, setForceCustom] = useState(false)
    const isCustom =
      (selectedHue !== undefined && !huePresets.includes(selectedHue)) ||
      forceCustom

    const [sliderValue, setSliderValue] = useState(selectedHue ?? 0)
    useEffect(() => {
      setSliderValue(selectedHue ?? 0)
    }, [selectedHue])

    return (
      <div ref={ref} className={className} {...rest}>
        <div
          aria-label="Color picker"
          className={cn(
            "grid",
            linear ? "grid-cols-12 gap-x-[2.5%]" : "grid-cols-6 gap-x-[5%]",
            "gap-y-1.5",
          )}
        >
          {huePresets.map((hue) => (
            <button
              key={hue}
              aria-label="Select color"
              onClick={() => {
                onHueSelect?.(hue)
                setForceCustom(false)
              }}
              className={hueButton}
              style={
                {
                  "--hue": hue,
                } as React.CSSProperties
              }
            >
              {selectedHue === hue && !forceCustom && (
                <Icon name="Tick" className="text-white" />
              )}
            </button>
          ))}
          {!isCustom && showCustomOption && (
            <button
              key={"custom"}
              aria-label="Select color"
              onClick={() => setForceCustom(true)}
              className={hueButton}
              style={
                {
                  // Multicolor gradient!
                  background: `conic-gradient(
                    oklch(66.54% 0.151 0 / 1) 0%,
                    oklch(66.54% 0.151 45 / 1) 12.5%, 
                    oklch(66.54% 0.151 90 / 1) 25%, 
                    oklch(66.54% 0.151 135 / 1) 37.5%, 
                    oklch(66.54% 0.151 180 / 1) 50%, 
                    oklch(66.54% 0.151 225 / 1) 62.5%, 
                    oklch(66.54% 0.151 270 / 1) 75%, 
                    oklch(66.54% 0.151 315 / 1) 87.5%, 
                    oklch(66.54% 0.151 360 / 1) 100%`,
                } as React.CSSProperties
              }
            >
              {isCustom && <Icon name="Tick" className="text-white" />}
            </button>
          )}
        </div>
        {isCustom && showCustomOption && (
          <div className="mt-2">
            <Root
              className="relative flex items-center select-none touch-none w-full h-5"
              value={[sliderValue]}
              onValueChange={(values) => setSliderValue(values[0])}
              onValueCommit={(values) => onHueSelect?.(values[0])}
              max={360}
              step={1}
            >
              <Track
                className={cn(
                  "bg-brand-blue-primary relative grow rounded-md h-4",
                  "ring-1 ring-inset ring-tint-heavy",
                  "shadow-inner shadow-black/20 dark:shadow-white/20",
                )}
                style={{
                  background: `
                  linear-gradient(
                    to right, 
                    oklch(66.54% 0.151 0 / 1) 0%,
                    oklch(66.54% 0.151 45 / 1) 12.5%, 
                    oklch(66.54% 0.151 90 / 1) 25%, 
                    oklch(66.54% 0.151 135 / 1) 37.5%, 
                    oklch(66.54% 0.151 180 / 1) 50%, 
                    oklch(66.54% 0.151 225 / 1) 62.5%, 
                    oklch(66.54% 0.151 270 / 1) 75%, 
                    oklch(66.54% 0.151 315 / 1) 87.5%, 
                    oklch(66.54% 0.151 360 / 1) 100%
                )`,
                }}
              />
              <Thumb className="block w-4 h-4 rounded-md ring-1 ring-offset-2 ring-black/20" />
            </Root>
          </div>
        )}
      </div>
    )
  },
)

ColorPickerFn.displayName = "ColorPicker"

export const ColorPicker = React.memo(ColorPickerFn) as typeof ColorPickerFn

"use client"

import {
  Root,
  Indicator,
  CheckboxProps as _CheckboxProps,
} from "@radix-ui/react-checkbox"
import React from "react"
import { cn } from "@daybridge/cn"
import { cva, VariantProps } from "class-variance-authority"
import { Icon } from "../icon/Icon"

type CheckboxProps = _CheckboxProps &
  VariantProps<typeof checkbox> & {
    // Additional props here...
  }

const checkbox = cva(
  [
    "relative",
    "rounded-[30%] aspect-square",
    "transition-[opacity,background,transform] duration-100 ease-in-out",
    "cursor-pointer data-[disabled]:cursor-not-allowed",
    "data-[disabled]:opacity-50",
    "group",
    "aria-checked:text-high-contrast",
    "[&_svg]:text-white",
  ],
  {
    variants: {
      theme: {
        default: [
          "data-[state=checked]:bg-tint-light",
          "[&_svg]:text-high-contrast",
        ],
        "brand-pink": ["data-[state=checked]:bg-brand-pink-primary"],
        "brand-blue": ["data-[state=checked]:bg-brand-blue-primary"],
        error: ["data-[state=checked]:bg-state-error-primary"],
        success: ["data-[state=checked]:bg-state-success-primary"],
        warning: ["data-[state=checked]:bg-state-warning-primary"],
        info: ["data-[state=checked]:bg-state-info-primary"],
        adaptive: ["data-[state=checked]:bg-adaptive-primary"],
      },
    },
    defaultVariants: {
      theme: "default",
    },
  },
)

const CheckboxFn = React.forwardRef(
  (props: CheckboxProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const { className, theme, ...rest } = props
    return (
      <Root ref={ref} className={cn(checkbox({ theme, className }))} {...rest}>
        <Indicator asChild>
          <Icon name="Tick" className="w-full p-[27%]" />
        </Indicator>
        <div
          className={cn(
            "rounded-[30%]",
            "absolute inset-0",
            "ring-1 ring-inset ring-tint-heavy",
            "pointer-events-none",
          )}
        />
      </Root>
    )
  },
)
CheckboxFn.displayName = "Checkbox"

export const Checkbox = React.memo(CheckboxFn) as typeof CheckboxFn

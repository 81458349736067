"use client"

import { range } from "lodash"

import React, { useMemo } from "react"
import { cn } from "@daybridge/cn"
import { DateTime, Duration } from "luxon"
import { TimeFormatterOptions } from "../_utils/TimeFormatterOptions"
import { CurrentTimeLabel } from "./CurrentTimeLabel"

type TimeLabelsProps = React.HTMLAttributes<HTMLDivElement> & {
  formatter: (date: DateTime, options: TimeFormatterOptions) => string
  startDayOnHour?: number
  endDayOnHour?: number
  baseDay: DateTime
  zone?: string
  hue?: number
  labelRef?: React.Ref<HTMLDivElement>
}

const TimeLabelsFn = React.forwardRef(
  (props: TimeLabelsProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      className,
      formatter,
      startDayOnHour = 0,
      endDayOnHour = 24,
      baseDay,
      zone,
      hue,
      labelRef,
      ...rest
    } = props

    const start = useMemo(
      () =>
        baseDay.startOf("day").set(
          startDayOnHour !== 0
            ? {
                hour: startDayOnHour,
              }
            : {},
        ),
      [baseDay, startDayOnHour],
    )
    const end = useMemo(() => {
      if (endDayOnHour !== 24) {
        return baseDay.startOf("day").set({
          hour: endDayOnHour,
        })
      } else {
        return baseDay.startOf("day").plus({ days: 1 })
      }
    }, [baseDay, endDayOnHour])

    // Calculate the duration, but gloss over any timezone transitions.
    const durationHours = useMemo(
      () => durationIgnoringZoneDifferences(start, end),
      [start, end],
    ).as("hours")

    // Render 1 fewer label than the number of hours we have
    const numberOfLabels = durationHours - 1

    const isNotBaseZone = zone && zone !== start.zoneName

    const labelTimes = useMemo(() => {
      return range(numberOfLabels).map((index) => {
        if (isNotBaseZone) {
          return start
            .set({ hour: start.hour + index + 1 })
            .setLocale(baseDay.locale)
            .setZone(zone || start.zoneName)
        } else {
          // The home TZ has to be treated like UTC for the purposes of rendering
          // the labels to make sure there is 1 label for every hour of the day even
          // on days that have a DST transition.
          const startUTC = start.toUTC(undefined, { keepLocalTime: true })
          return startUTC
            .set({ hour: startUTC.hour + index + 1 })
            .setLocale(baseDay.locale)
        }
      })
    }, [start, baseDay.locale, numberOfLabels, zone, isNotBaseZone])

    return (
      <div
        className={cn(
          "flex flex-col flex-1 flex-shrink-0",
          "bg-surface",
          "border-r border-tint-light",
          "relative",
          "[&_.label]:first:rounded-l-md",
          "[&_.label]:first:-ml-0",
          "[&_.label]:last:rounded-r-md",
          className,
        )}
        ref={ref}
        {...rest}
      >
        {/* Since we are rendering fewer labels than the number of hours, 
        we need to add padding to the top and bottom of the labels 
        to make sure they are centered. Do this by calculating half the height of
        a time label via a flex-basis. */}
        <div
          style={{
            flexBasis: `${50 / durationHours}%`,
          }}
        />
        {/* Render the labels */}
        {labelTimes.map((time, index) => {
          return (
            <div
              key={time.toUTC().toISO() + index.toString()}
              className="flex items-center justify-center"
              style={{
                flexBasis: `${100 / durationHours}%`,
              }}
            >
              <span className="text-center text-medium-contrast text-xs scale-[0.9] whitespace-pre-line">
                {formatter(time, DateTime.TIME_SIMPLE).replace(
                  /\s+/g,
                  time.minute === 0 ? " " : "\n",
                )}
              </span>
            </div>
          )
        })}
        {/* Corresponding end padding */}
        <div
          style={{
            flexBasis: `${50 / durationHours}%`,
          }}
        />

        <CurrentTimeLabel
          formatter={formatter}
          hue={hue}
          zone={zone}
          ref={labelRef}
        />
      </div>
    )
  },
)
TimeLabelsFn.displayName = "TimeLabels"

export const TimeLabels = React.memo(TimeLabelsFn) as typeof TimeLabelsFn

export const durationIgnoringZoneDifferences = (
  start: DateTime,
  end: DateTime,
): Duration => {
  return end.diff(start).minus({ minutes: start.offset - end.offset })
}

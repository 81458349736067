"use client"

import React, { useCallback, useState } from "react"
import { cn } from "@daybridge/cn"
import { Icon } from "../icon/Icon"

type BadgeProps = Omit<React.HTMLAttributes<HTMLDivElement>, "children"> & {
  name: string
  hue: number
  icon: string
}

const BadgeFn = React.forwardRef(
  (props: BadgeProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { className, name, hue, icon, ...rest } = props
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
    const handleMouseMove = useCallback(
      ({
        clientX,
        clientY,
        currentTarget,
      }: React.MouseEvent<HTMLDivElement>) => {
        const { left, top } = currentTarget.getBoundingClientRect()
        const x = clientX - left
        const y = clientY - top

        setMousePosition({ x, y })
      },
      [],
    )

    return (
      <div
        ref={ref}
        onMouseMove={handleMouseMove}
        style={{ "--hue": hue } as React.CSSProperties}
        className={cn(
          "relative",
          "w-fit",
          "inline-flex flex-row",
          "items-center",
          "rounded-full",
          "bg-gradient-to-b from-adaptive-solid-object-highlight to-adaptive-solid-object",
          "text-white",
          "ring-1 ring-inset ring-tint-heavy",
          "drop-shadow",
          "overflow-hidden",
          className,
        )}
        {...rest}
      >
        <div
          className={cn(
            "flex items-center justify-center rounded-full",
            "w-[calc(2em-2px)]",
            "ml-px my-px mr-[0.75em]",
            "aspect-square",
            "bg-adaptive-solid-object-highlight",
            "ring-1 ring-inset ring-white/10",
            "drop-shadow",
            "relative z-20",
          )}
        >
          <Icon
            name={icon}
            className={cn(
              "w-[1em] h-[1em]",
              "drop-shadow-[0_0_0.3em_oklch(var(--color-adaptive-low-contrast)_var(--hue)_/_1)]",
            )}
          />
        </div>
        <div className={cn("pr-[1em]", "font-semibold", "drop-shadow")}>
          {name}
        </div>
        <div
          className="absolute inset-0 z-10"
          style={{
            background: `radial-gradient(5em circle at ${mousePosition.x}px ${mousePosition.y}px, rgba(255,255,255,0.1), transparent 80%)`,
          }}
        />
      </div>
    )
  },
)
BadgeFn.displayName = "Badge"

export const Badge = React.memo(BadgeFn) as typeof BadgeFn

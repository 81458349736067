"use client"

import React from "react"
import { cn } from "@daybridge/cn"
import {
  Root,
  Image,
  Fallback,
  AvatarProps as RadixAvatarProps,
  AvatarImageProps,
  AvatarFallbackProps,
} from "@radix-ui/react-avatar"
import { Icon } from "../icon/Icon"

export type AvatarProps = {
  // Size of the avatar in pixels
  size?: number

  // Name of the user to generate initials from
  name?: string

  strokeClassName?: string
} & RadixAvatarProps &
  AvatarImageProps &
  AvatarFallbackProps

const AvatarFn = React.forwardRef(
  (props: AvatarProps, ref: React.ForwardedRef<HTMLImageElement>) => {
    const {
      className,
      name,
      size,
      delayMs,
      onLoadingStatusChange,
      strokeClassName,
      src,
      ...rest
    } = props
    return (
      <Root
        ref={ref}
        style={
          {
            // May have been set in a stack component already
            "--avatar-size": `var(--avatar-stack-size, ${size || 32}px)`,
          } as React.CSSProperties
        }
        className={cn(
          "relative z-0 w-[var(--avatar-size)] aspect-square",
          className,
        )}
        {...rest}
      >
        <div
          className={cn(
            "absolute z-10",
            "ring-1 ring-inset ring-black/10 dark:ring-white/20",
            "rounded-full",
            strokeClassName,
          )}
          style={{
            top: "calc(var(--avatar-stack, 0) * 2px)",
            left: "calc(var(--avatar-stack, 0) * 2px)",
            bottom: "calc(var(--avatar-stack, 0) * 2px)",
            right: "calc(var(--avatar-stack, 0) * 2px)",
          }}
        />
        <Image
          src={src}
          onLoadingStatusChange={onLoadingStatusChange}
          className={cn(
            "w-[var(--avatar-size)]",
            "transition ease-in-out duration-200",
            "aspect-square rounded-full",
            "max-w-none",
          )}
        />
        <Fallback
          delayMs={delayMs}
          className={cn(
            "w-[var(--avatar-size)]",
            "flex items-center justify-center",
            "transition duration-200",
            "aspect-square rounded-full ",
            "font-medium leading-none",
            "uppercase select-none",
            "bg-adaptive-background text-adaptive-high-contrast",
          )}
          style={{
            fontSize: `calc(var(--avatar-size) * 0.3)`,
          }}
          role="presentation"
          aria-hidden="true"
        >
          {generateAvatarInitials(name) || (
            <Icon name="ImageSticky" className="w-1/4" />
          )}
        </Fallback>
      </Root>
    )
  },
)
AvatarFn.displayName = "Avatar"

export const Avatar = React.memo(AvatarFn) as typeof AvatarFn

function generateAvatarInitials(name?: string): string {
  // Check if name is undefined, null, not a string, or entirely whitespace
  if (
    name === undefined ||
    name === null ||
    typeof name !== "string" ||
    name.trim() === ""
  ) {
    return ""
  }

  const words = name.trim().split(/\s+/)
  let initials = ""

  // If name has multiple words, get the first character of the first two words
  if (words.length > 1) {
    initials =
      words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
  }
  // If name is a single word, get the first two characters of the word
  else if (words[0].length > 1) {
    initials =
      words[0].charAt(0).toUpperCase() + words[0].charAt(1).toUpperCase()
  }
  // If name is a single character, just return the uppercase of that character
  else {
    initials = words[0].charAt(0).toUpperCase()
  }

  return initials
}

"use client"

import React from "react"
import { cn } from "@daybridge/cn"
import {
  Root,
  Trigger,
  Content,
  Portal,
  Arrow,
  PopoverProps as _PopoverProps,
  PopoverContentProps,
  PopoverTriggerProps,
} from "@radix-ui/react-popover"
import { ring } from "../_styles/ring"
import { elevated } from "../_styles/elevated"

export type PopoverProps = _PopoverProps &
  Omit<PopoverContentProps, "content"> & {
    content: React.ReactNode
    showArrow?: boolean
  } & Omit<PopoverTriggerProps, "content">

const PopoverFn = React.forwardRef(
  (props: PopoverProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      children,
      className,
      showArrow = true,

      // Root
      open,
      defaultOpen,
      onOpenChange,
      modal,
      content,

      onOpenAutoFocus,
      onCloseAutoFocus,
      onEscapeKeyDown,
      onPointerDownOutside,
      onFocusOutside,
      forceMount,
      side,
      sideOffset,
      align,
      alignOffset,
      avoidCollisions,
      collisionBoundary,
      collisionPadding = 8,
      arrowPadding = 16,
      sticky,
      hideWhenDetached,
      updatePositionStrategy,

      ...rest
    } = props
    return (
      <Root
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
        modal={modal}
      >
        <Trigger ref={ref} asChild {...rest}>
          {children}
        </Trigger>
        <Portal>
          <Content
            onOpenAutoFocus={onOpenAutoFocus}
            onCloseAutoFocus={onCloseAutoFocus}
            onEscapeKeyDown={onEscapeKeyDown}
            onPointerDownOutside={onPointerDownOutside}
            onFocusOutside={onFocusOutside}
            forceMount={forceMount}
            side={side}
            sideOffset={sideOffset}
            align={align}
            alignOffset={alignOffset}
            avoidCollisions={avoidCollisions}
            collisionBoundary={collisionBoundary}
            collisionPadding={collisionPadding}
            arrowPadding={arrowPadding}
            sticky={sticky}
            hideWhenDetached={hideWhenDetached}
            className={cn("origin-radix-popover", elevated, className)}
            updatePositionStrategy={updatePositionStrategy}
            onClick={(e) => {
              e.stopPropagation()
            }}
            onMouseDown={(e) => {
              e.stopPropagation()
            }}
            onMouseUp={(e) => {
              e.stopPropagation()
            }}
          >
            {content}
            <div className={ring} />

            {showArrow && (
              <>
                <Arrow
                  className="arrow-stroke text-elevated/80 stroke-tint-heavy"
                  fill="currentColor"
                  strokeWidth={3}
                  offset={8}
                />
                <Arrow
                  className="arrow-fill text-elevated relative -top-[2px] stroke-elevated"
                  fill="currentColor"
                  strokeWidth={3}
                  offset={8}
                />
              </>
            )}
          </Content>
        </Portal>
      </Root>
    )
  },
)
PopoverFn.displayName = "Popover"

export const Popover = React.memo(PopoverFn) as typeof PopoverFn

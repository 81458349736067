"use client"

import React from "react"
import { cn } from "@daybridge/cn"
import { DateTime } from "luxon"
import { TimeFormatterOptions } from "../_utils/TimeFormatterOptions"
import { CurrentTimeCursorConsumer } from "./CurrentTimeCursor"

type CurrentTimeLabelProps = React.HTMLAttributes<HTMLDivElement> & {
  formatter: (date: DateTime, options: TimeFormatterOptions) => string
  zone?: string
  hue?: number
}

const CurrentTimeLabelFn = React.forwardRef(
  (props: CurrentTimeLabelProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { zone, children, formatter, className, style, hue, ...rest } = props

    return (
      <CurrentTimeCursorConsumer>
        {({ now, position }) => (
          <div
            ref={ref}
            // If the label is too large, hang over the canvas a bit
            className={cn(
              "label",
              "w-[calc(100%+2px)]",
              "py-1",
              "-mx-px",
              "absolute text-[10px]",
              "leading-none",
              "text-center",
              "-translate-y-1/2",
              "z-[60]",
              "pointer-events-none",
              "bg-gradient-to-b",
              "whitespace-pre-line",
              "overflow-hidden",
              "ring-1 ring-inset",
              hue === undefined
                ? [
                    "from-adaptive-object-highlight",
                    "to-adaptive-object",
                    "text-adaptive-medium-contrast",
                    "ring-adaptive-tint-heavy",
                  ]
                : [
                    "from-adaptive-object-highlight",
                    "to-adaptive-object",
                    "text-adaptive-medium-contrast",
                    "ring-adaptive-tint-heavy",
                  ],
              className,
            )}
            style={{
              // Make sure it can't go off the top or bottom
              top: `clamp(15px, ${position}%, calc(100% - 10px))`,

              ...style,
            }}
            {...rest}
          >
            {formatter(now.setZone(zone || now.zone), {
              hour: "numeric",
              minute: "numeric",
            }).replace(" ", "\n")}
          </div>
        )}
      </CurrentTimeCursorConsumer>
    )
  },
)
CurrentTimeLabelFn.displayName = "CurrentTimeLabel"

export const CurrentTimeLabel = React.memo(
  CurrentTimeLabelFn,
) as typeof CurrentTimeLabelFn

"use client"

import React, { useCallback, useState } from "react"
import { cn } from "@daybridge/cn"

type HoverGlowProps = Omit<React.HTMLAttributes<HTMLDivElement>, "children"> & {
  size?: string
  opacity?: number
}

const HoverGlowFn = React.forwardRef(
  (props: HoverGlowProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { className, size = "5em", opacity = 0.1, ...rest } = props
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
    const [isHovered, setIsHovered] = useState(false)
    const handleMouseEnter = useCallback(() => {
      setIsHovered(true)
    }, [])
    const handleMouseLeave = useCallback(() => {
      setIsHovered(false)
    }, [])
    const handleMouseMove = useCallback(
      ({
        clientX,
        clientY,
        currentTarget,
      }: React.MouseEvent<HTMLDivElement>) => {
        const { left, top } = currentTarget.getBoundingClientRect()
        const x = clientX - left
        const y = clientY - top

        setMousePosition({ x, y })
      },
      [],
    )

    return (
      <div
        ref={ref}
        onMouseMove={handleMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={cn(
          "absolute inset-0 z-0",
          "transition-opacity duration-300 ease-in-out",
          isHovered ? "opacity-100" : "opacity-0",
          className,
        )}
        style={{
          background: `radial-gradient(${size} circle at ${mousePosition.x}px ${mousePosition.y}px, rgba(255,255,255,${opacity}), transparent 80%)`,
        }}
        {...rest}
      />
    )
  },
)
HoverGlowFn.displayName = "HoverGlow"

export const HoverGlow = React.memo(HoverGlowFn) as typeof HoverGlowFn

"use client"

import React, { createContext, useContext } from "react"

// Create the VirtualizerScrollingContext
export const virtualizerScrollingContext =
  createContext<React.RefObject<boolean> | null>(null)

// Create the useVirtualizerScrolling hook
export const useVirtualizerScrolling = (): React.RefObject<boolean> => {
  const virtualizerScrollingRef = useContext(virtualizerScrollingContext)
  if (!virtualizerScrollingRef) {
    throw new Error(
      "useVirtualizerScrolling must be used within a VirtualizerScrollingContextProvider",
    )
  }
  return virtualizerScrollingRef
}

export const VirtualizerScrollingContextProvider =
  virtualizerScrollingContext.Provider

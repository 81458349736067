/* eslint-disable @typescript-eslint/no-unsafe-assignment */
"use client"

import React, { useEffect } from "react"
import { Root, PopoverAnchor, Portal, Content } from "@radix-ui/react-popover"
import {
  comboboxActions,
  ComboboxContentItemProps,
  ComboboxContentProps,
  ComboboxProps,
  Data,
  NoData,
  TComboboxItem,
  useActiveComboboxStore,
  useComboboxContent,
  useComboboxContentState,
  useComboboxControls,
  useComboboxItem,
  useComboboxSelectors,
} from "@udecode/plate-combobox"
import { useEditorState, useEventEditorSelectors } from "@udecode/plate-common"
import { createVirtualRef } from "@udecode/plate-floating"
import { cn } from "@daybridge/cn"
import { BaseMenuItem } from "./BaseMenuItem"

// COLLISION_PADDING is a buffer around the edge of the screen
// to stop the menu from getting too close to the edge.
const COLLISION_PADDING = 8

export function ComboboxItem<TData extends Data = NoData>({
  combobox,
  index,
  item,
  onRenderItem,
}: ComboboxContentItemProps<TData>) {
  const { props } = useComboboxItem({ item, index, combobox, onRenderItem })

  return <BaseMenuItem {...props} />
}

export function ComboboxContent<TData extends Data = NoData>(
  props: ComboboxContentProps<TData>,
) {
  const {
    component: Component,
    items,
    portalElement,
    combobox,
    onRenderItem,
  } = props

  const editor = useEditorState()

  const filteredItems =
    useComboboxSelectors.filteredItems() as TComboboxItem<TData>[]
  const activeComboboxStore = useActiveComboboxStore()
  if (!activeComboboxStore) {
    throw new Error("activeComboboxStore is undefined")
  }

  const state = useComboboxContentState({ items, combobox })
  const { menuProps, targetRange } = useComboboxContent(state)

  return (
    <Root open>
      <PopoverAnchor
        virtualRef={createVirtualRef(editor, targetRange ?? undefined)}
      />

      <Portal container={portalElement}>
        <Content
          {...menuProps}
          sideOffset={5}
          side="bottom"
          align="start"
          className={cn(
            "backdrop-blur-lg backdrop-saturate-200",
            "origin-radix-dropdown-menu",
            "data-[state=open]:animate-[fade-and-scale-in_50ms_ease-in]",
            "data-[state=closed]:animate-[fade-and-scale-out_50ms_ease-in]",
            "max-h-[var(--radix-popover-content-available-height)]",
            "relative",
            "rounded-lg",
            "bg-elevated/90 text-high-contrast",
            "overflow-auto",
            "flex flex-col",
            "p-1",
            "rounded-lg",
            "ring-1 ring-inset ring-tint",
          )}
          onOpenAutoFocus={(event) => event.preventDefault()}
          collisionPadding={COLLISION_PADDING}
        >
          {Component ? Component({ store: activeComboboxStore }) : null}

          {filteredItems.map((item, index) => (
            <ComboboxItem
              key={item.key}
              item={item}
              combobox={combobox}
              index={index}
              onRenderItem={onRenderItem}
            />
          ))}
        </Content>
      </Portal>
    </Root>
  )
}

/**
 * Register the combobox id, trigger, onSelectItem
 * Renders the combobox if active.
 */
export function Combobox<TData extends Data = NoData>({
  id,
  trigger,
  searchPattern,
  onSelectItem,
  controlled,
  maxSuggestions,
  filter,
  sort,
  disabled: _disabled,
  ...props
}: ComboboxProps<TData>) {
  const storeItems = useComboboxSelectors.items()
  const disabled =
    _disabled ?? (storeItems.length === 0 && !props.items?.length)

  const focusedEditorId = useEventEditorSelectors.focus?.()
  const combobox = useComboboxControls()
  const activeId = useComboboxSelectors.activeId()
  const editor = useEditorState()

  useEffect(() => {
    comboboxActions.setComboboxById({
      id,
      trigger,
      searchPattern,
      controlled,
      onSelectItem,
      maxSuggestions,
      filter,
      sort,
    })
  }, [
    id,
    trigger,
    searchPattern,
    controlled,
    onSelectItem,
    maxSuggestions,
    filter,
    sort,
  ])

  if (
    !combobox ||
    !editor.selection ||
    focusedEditorId !== editor.id ||
    activeId !== id ||
    disabled
  ) {
    return null
  }

  return <ComboboxContent combobox={combobox} {...props} />
}

import React from "react"
import { cn } from "@daybridge/cn"
import { cva, VariantProps } from "class-variance-authority"
import { Icon } from "../icon/Icon"

// Define the ButtonProps type
export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof button> & {
    icon?: string | React.ReactNode
    chevron?: boolean
  }

export const button = cva(
  [
    "relative",
    "flex flex-row items-center justiy-center",
    "text-base",
    "transition-[filter,box-shadow,background-color,border,color] duration-200",
    "disabled:cursor-not-allowed",
    "ring-0 ring-offset-0 focus-visible:ring-2 focus-visible:ring-offset-2 ring-offset-surface",
    "group",
  ],
  {
    defaultVariants: {
      theme: "neutral",
      variant: "outline",
    },

    variants: {
      variant: {
        // All solid buttons
        solid: [
          "border border-tint-heavy",
          "drop-shadow dark:drop-shadow-xl",
          "disabled:dark:text-low-contrast",
        ],
        // All outline buttons
        outline: [
          "border border-tint",
          "bg-surface hover:bg-elevated active:bg-background disabled:bg-tint-light",
          "disabled:text-low-contrast",
        ],
        // All translucent buttons
        translucent: [
          "border border-transparent",
          "bg-transparent hover:bg-tint-extra-light disabled:hover:bg-transparent",
          "disabled:text-low-contrast",
        ],
        // All link buttons
        link: [
          "hover:underline",
          "rounded-[0.1em]",
          "disabled:text-low-contrast",
        ],
      },
      theme: {
        neutral: [
          "text-medium-contrast hover:text-high-contrast focus:text-high-contrast aria-expanded:text-high-contrast disabled:text-low-contrast",
          "[&>.chevron]:text-low-contrast",
        ],
        "brand-blue": [],
        "brand-pink": [],
        adaptive: [],
        success: [],
        warning: [],
        error: [],
        info: [],
      },
    },

    // Compound variants, grouped by commonalities in theme or variant
    compoundVariants: [
      // Padding and roundness for solid, outline and translucent variants
      {
        variant: ["solid", "outline", "translucent"],
        class: ["px-[1.2em] py-[0.5em]", "rounded-full"],
      },

      // Neutral theme solid variant
      {
        theme: "neutral",
        variant: "solid",
        class: [
          "bg-primary dark:bg-solid-object disabled:bg-brand-blue-tint-heavy",
        ],
      },

      // Neutral theme outline variant
      {
        theme: "neutral",
        variant: ["outline"],
        class: [
          "bg-surface disabled:bg-tint-light",
          "hover:brightness-100 hover:bg-elevated active:bg-background",
          "drop-shadow-none",
          "border-tint hover:border-tint-heavy",
        ],
      },

      // Brand-blue theme solid variant
      {
        theme: "brand-blue",
        variant: "solid",
        class: ["bg-brand-blue-primary disabled:bg-brand-blue-tint-heavy"],
      },

      // Brand-blue theme outline, translucent and link variants
      {
        theme: "brand-blue",
        variant: ["outline", "translucent", "link"],
        class: [
          "text-brand-blue-primary visited:text-brand-blue-high-contrast",
        ],
      },

      // Brand-blue theme focus rings
      {
        theme: "brand-blue",
        variant: ["solid", "outline", "translucent", "link"],
        class: ["ring-brand-blue-primary"],
      },

      // Brand-pink theme solid variant
      {
        theme: "brand-pink",
        variant: "solid",
        class: [
          "bg-brand-pink-primary dark:bg-brand-solid-object disabled:bg-brand-pink-tint-heavy",
        ],
      },

      // Brand-pink theme outline, translucent and link variants
      {
        theme: "brand-pink",
        variant: ["outline", "translucent", "link"],
        class: [
          "text-brand-pink-primary visited:text-brand-pink-high-contrast",
        ],
      },

      // Brand-pink theme focus rings
      {
        theme: "brand-pink",
        variant: ["solid", "outline", "translucent", "link"],
        class: ["ring-brand-pink-primary"],
      },

      // Adaptive theme solid variant
      {
        theme: "adaptive",
        variant: "solid",
        class: [
          "bg-adaptive-primary dark:bg-adaptive-solid-object-highlight disabled:bg-adaptive-tint-heavy",
        ],
      },

      // Adaptive theme outline, translucent and link variants
      {
        theme: "adaptive",
        variant: ["outline", "translucent", "link"],
        class: ["text-adaptive-primary visited:text-adaptive-high-contrast"],
      },

      // Adaptive theme focus rings
      {
        theme: "adaptive",
        variant: ["solid", "outline", "translucent", "link"],
        class: ["ring-adaptive-primary"],
      },

      // Success theme solid variant
      {
        theme: "success",
        variant: "solid",
        class: [
          "bg-state-success-primary dark:bg-success-solid-object disabled:bg-state-success-tint-heavy",
        ],
      },

      // Success theme outline, translucent and link variants
      {
        theme: "success",
        variant: ["outline", "translucent", "link"],
        class: [
          "text-state-success-primary visited:text-state-success-high-contrast",
        ],
      },

      // Success theme focus rings
      {
        theme: "success",
        variant: ["solid", "outline", "translucent", "link"],
        class: ["ring-state-success-primary"],
      },

      // Warning theme solid variant
      {
        theme: "warning",
        variant: "solid",
        class: [
          "bg-state-warning-primary dark:bg-warning-solid-object disabled:bg-state-warning-tint-heavy",
        ],
      },

      // Warning theme outline, translucent and link variants
      {
        theme: "warning",
        variant: ["outline", "translucent", "link"],
        class: [
          "text-state-warning-primary visited:text-state-warning-high-contrast",
        ],
      },

      // Warning theme focus rings
      {
        theme: "warning",
        variant: ["solid", "outline", "translucent", "link"],
        class: ["ring-state-warning-primary"],
      },

      // Error theme solid variant
      {
        theme: "error",
        variant: "solid",
        class: [
          "bg-state-error-primary dark:bg-error-solid-object disabled:bg-state-error-tint-heavy",
        ],
      },

      // Error theme outline, translucent and link variants
      {
        theme: "error",
        variant: ["outline", "translucent", "link"],
        class: [
          "text-state-error-primary visited:text-state-error-high-contrast",
        ],
      },

      // Error theme focus rings
      {
        theme: "error",
        variant: ["solid", "outline", "translucent", "link"],
        class: ["ring-state-error-primary"],
      },

      // Info theme solid variant
      {
        theme: "info",
        variant: "solid",
        class: [
          "bg-state-info-primary dark:bg-info-solid-object disabled:bg-state-info-tint-heavy",
        ],
      },

      // Info theme outline, translucent and link variants
      {
        theme: "info",
        variant: ["outline", "translucent", "link"],
        class: [
          "text-state-info-primary visited:text-state-info-high-contrast",
        ],
      },

      // Info theme focus rings
      {
        theme: "info",
        variant: ["solid", "outline", "translucent", "link"],
        class: ["ring-state-info-primary"],
      },

      // Common styles for all solid variants
      {
        theme: [
          "brand-blue",
          "brand-pink",
          "adaptive",
          "success",
          "warning",
          "error",
          "info",
        ],
        variant: "solid",
        class: [
          "text-white",
          "shadow-white/20 shadow-inner active:shadow-white/0",
          "dark:disabled:shadow-background/30",
          "dark:disabled:bg-background",
          "brightness-100 hover:brightness-110 active:brightness-100 disabled:hover:brightness-100",
          "[text-shadow:_0_0_2px_rgba(0,0,0,0.2)] disabled:[text-shadow:none]",
        ],
      },
    ],
  },
)

const ButtonFn = React.forwardRef(
  (props: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      children,
      className,
      style,
      theme,
      variant,
      icon,
      chevron,
      ...rest
    } = props
    const isIconOnly = !children && ((icon && !chevron) || (chevron && !icon))

    return (
      <button
        ref={ref}
        className={cn(
          button({ theme, variant }),
          isIconOnly
            ? "aspect-square flex items-center justify-center flex-shrink-0 p-0"
            : "truncate",
          className,
        )}
        style={{
          // Safari fixes
          WebkitTransform:
            "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) translateZ(0)",
          overflow: "visible",
          ...style,
        }}
        {...rest}
      >
        {icon &&
          (typeof icon === "string" ? (
            <Icon
              name={icon}
              className={cn(
                "icon",
                isIconOnly
                  ? ["w-[-webkit-fill-available]", "p-[27.5%]"]
                  : "w-[0.9em] mr-[0.5em]",
                "flex-shrink-0",
              )}
              style={{
                width: isIconOnly ? "-moz-available" : undefined,
              }}
            />
          ) : (
            icon
          ))}
        {children}
        {chevron && (
          <Icon
            name="ChevronDown"
            className={cn(
              "chevron",
              isIconOnly
                ? "w-[45%]"
                : ["w-[0.6em]", children ? "ml-[0.75em]" : "ml-[0.25em]"],
              "transition-[transform] duration-200 ease-in-out",
              "flex-shrink-0",
              "group-data-[state=open]:-rotate-180",
            )}
          />
        )}
      </button>
    )
  },
)
ButtonFn.displayName = "Button"

// Export the Button component
export const Button = React.memo(ButtonFn) as typeof ButtonFn

import { useCallback, useEffect, useState } from "react"
import MiniSearch, { SearchOptions, SearchResult } from "minisearch"
import { IndexedItem } from "./types"

export const useComboboxSearch = (items: IndexedItem[]) => {
  const [minisearch, setMinisearch] = useState<MiniSearch<IndexedItem> | null>(
    null,
  )

  // Whenever flattenedItems changes, create a new MiniSearch instance and index the items.
  useEffect(() => {
    const ms = new MiniSearch({
      searchOptions: {
        prefix: true,
        fuzzy: 0.2,
        boost: {
          title: 4,
          pathString: 2,
          description: 1,
          groupTitle: 1,
          searchStrings: 1,
        },
      },
      idField: "globalId",
      fields: [
        "title",
        "description",
        "groupTitle",
        "searchStrings",
        "pathString",
      ],
      storeFields: ["id", "globalId"],
      extractField: (item: IndexedItem, fieldName) => {
        if (fieldName === "groupTitle" && item.group?.title) {
          return item.group.title
        }
        if (fieldName === "pathString" && item.path) {
          return item.path.map((p) => p.title).join(" ")
        }
        return item[fieldName as keyof IndexedItem] as string
      },
    })

    // Add asyncronously to avoid blocking the main thread
    void ms.addAllAsync(items).then(() => {
      setMinisearch(ms)
    })
  }, [items])

  return useCallback(
    (inputValue: string, options?: SearchOptions): SearchResult[] => {
      if (!minisearch) {
        return []
      }

      const results = minisearch.search(inputValue, options)
      return results
    },
    [minisearch],
  )
}

"use client"

import React, { useCallback, useEffect } from "react"
import { cn } from "@daybridge/cn"
import { AnimatePresence, motion } from "framer-motion"
import { Icon } from "../icon/Icon"

type DropZoneProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "children" | "onDrop"
> & {
  icon?: string
  onDrop?: (files: File[]) => void
}

const DropZoneFn = React.forwardRef(
  (props: DropZoneProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { className, icon, onDrop, ...rest } = props

    const [dragCount, setDragCount] = React.useState(0)
    const dragging = dragCount > 0

    const handleDragEnter = useCallback(() => {
      setDragCount((count) => count + 1)
    }, [])
    const handleDragLeave = useCallback(() => {
      setDragCount((count) => count - 1)
    }, [])
    const handleMouseUp = useCallback(() => {
      setDragCount(0)
    }, [])
    const handleDragOver = useCallback((e: DragEvent) => {
      e.preventDefault()
    }, [])

    const handleDrop = useCallback(
      (ev: DragEvent) => {
        setDragCount(0)
        ev.preventDefault()
        if (onDrop) {
          if (!ev.dataTransfer) return

          let files: File[] = []
          if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            files = Array.from(ev.dataTransfer.items)
              .filter((item: DataTransferItem) => item.kind === "file")
              .map((item: DataTransferItem) => item.getAsFile())
              .filter((file): file is File => file !== null)
          } else {
            // Use DataTransfer interface to access the file(s)
            files = Array.from(ev.dataTransfer.files)
          }

          if (files.length > 0) {
            onDrop(files)
          }
        }
      },
      [onDrop],
    )

    useEffect(() => {
      window.addEventListener("dragenter", handleDragEnter)
      window.addEventListener("dragleave", handleDragLeave)
      window.addEventListener("mouseup", handleMouseUp)
      window.addEventListener("drop", handleDrop)
      window.addEventListener("dragover", handleDragOver)
      return () => {
        window.removeEventListener("dragenter", handleDragEnter)
        window.removeEventListener("dragleave", handleDragLeave)
        window.removeEventListener("mouseup", handleMouseUp)
        window.removeEventListener("drop", handleDrop)
        window.removeEventListener("dragover", handleDragOver)
      }
    }, [
      handleDragEnter,
      handleDragLeave,
      handleDragOver,
      handleMouseUp,
      handleDrop,
    ])

    return (
      <div
        ref={ref}
        className={cn(
          "absolute inset-0",
          "rounded-xl",
          "flex items-center justify-center",
          dragging
            ? [
                "z-50",
                "bg-state-info-background/80",
                "border border-dashed",
                "border-state-info-primary",
                "backdrop-blur",
              ]
            : ["pointer-events-none"],
          className,
        )}
        {...rest}
      >
        <AnimatePresence>
          {dragging && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex items-center justify-center w-full h-full"
            >
              <Icon
                name={icon || "Paperclip"}
                className="w-8 h-8 text-state-info-primary"
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    )
  },
)
DropZoneFn.displayName = "DropZone"

export const DropZone = React.memo(DropZoneFn) as typeof DropZoneFn

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { uniqueId as generateUniqueId } from "lodash-es"
import { SearchResult } from "minisearch"
import {
  ComboboxItem,
  ComboboxItemGroup,
  IndexedItem,
  ComboboxPage,
  IndexedItemGroup,
} from "./types"
import { ContainerProps, InputProps, ItemProps } from "./props"
import { useComboboxSearch } from "./useComboboxSearch"

export const useCombobox = (
  root: ComboboxPage,

  // `onComplete` is a callback function that is called when
  // the user has initiated a terminating action. This is useful
  // for closing popovers or other UI elements that contain the combobox.
  onComplete?: () => void,

  // `scrollRef` is a ref to the element that should be scrolled
  // if different from the container element.
  scrollRef?: React.RefObject<HTMLDivElement>,

  // Raise up the input state so that it can be controlled by the parent
  inputState?: [string, React.Dispatch<React.SetStateAction<string>>],

  // Whether or not to autofocus on open
  autoFocus = true,
) => {
  // Global unique ID for the combobox
  const uniqueId = useRef(generateUniqueId("combobox-")).current

  // Active page
  const [activeParentItem, setActiveParentItem] = useState<
    GlobalComboboxItemID | undefined
  >(undefined)

  // Index the items to make working with them easier
  const indexedItems = useMemo(
    () => flattenAndIndexV2(root, activeParentItem),
    [root, activeParentItem],
  )

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(
    undefined,
  )
  const pageTransitioning = useRef(false)
  const [pageTransitioningState, setPageTransitioningState] = useState(false)
  const [initialTransition, setInitialTransition] = useState(true)
  const transitionDelay = 300
  const currentPath = useMemo(() => {
    if (!activeParentItem) return []
    const item = indexedItems.get(activeParentItem)
    if (!item) return []
    return [...item.path, item]
  }, [activeParentItem, indexedItems])
  useEffect(() => {
    pageTransitioning.current = true
    setPageTransitioningState(true)
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      pageTransitioning.current = false
      setPageTransitioningState(false)
      setInitialTransition(false)
    }, transitionDelay)
  }, [activeParentItem])
  useEffect(() => {
    // If after the items change the active parent item is no longer in the list of items,
    // reset the active parent item to undefined.
    if (activeParentItem && !indexedItems.has(activeParentItem)) {
      setActiveParentItem(undefined)
    }
  }, [indexedItems, activeParentItem])
  const clearPath = useCallback(() => {
    setActiveParentItem(undefined)
  }, [])

  // Active menu
  const [menuOpen, setMenuOpen] = React.useState<string | undefined>(undefined)

  // Input
  const [internalInputValue, setInternalInputValue] = useState("") // Current input value
  const inputValue = inputState ? inputState[0] : internalInputValue
  const setInputValue = inputState ? inputState[1] : setInternalInputValue

  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    // When the active parent item changes, reset the input value
    setInputValue("")
    if (autoFocus || !initialTransition) {
      inputRef.current?.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeParentItem, setInputValue])

  // Container
  const containerRef = useRef<HTMLUListElement>(null)

  // Internally managed group collapsing and expansion for when
  // collapsed values/callbacks are not provided.
  const [collapsedGroups, setCollapsedGroups] = useState<
    Record<string, boolean>
  >({})
  const setGroupCollapsed = useCallback(
    (groupId: string, collapsed: boolean) => {
      setCollapsedGroups((prev) => ({ ...prev, [groupId]: collapsed }))
    },
    [],
  )

  // Item filtering
  const allItems = useMemo(
    () => Array.from(indexedItems.values()),
    [indexedItems],
  )
  const itemsForSearchIndex = useMemo(
    () =>
      allItems.filter((item) => {
        if (
          item.children?.items &&
          item.children.items.length > 0 &&
          item.children.items.every((i) => React.isValidElement(i))
        ) {
          // This item's children are all React elements, we won't index it.
          return false
        }
        return item.includeInSearchResults
      }),
    [allItems],
  )
  const filter = useComboboxSearch(itemsForSearchIndex)
  const itemIsNotHigherInHierarchy = useCallback(
    <T extends Pick<IndexedItem, "id" | "globalId">>(result: T) => {
      const item = indexedItems.get(result.globalId)
      if (!item) {
        return false
      }

      // Don't include items that are higher in the hierarchy
      if (!hasPathPrefix(item.path, currentPath)) {
        return false
      }

      return true
    },
    [indexedItems, currentPath],
  )

  // Page to render
  const pageToRender = useMemo(() => {
    const currentParentPage = activeParentItem
      ? indexedItems.get(activeParentItem)?.children
      : undefined
    return currentParentPage ?? root
  }, [activeParentItem, indexedItems, root])

  // We compute a list of items that are actually shown (and not in a collapsed group).
  // We will need this to compute the highlighted item.
  // This won't be enough to render the list becasue group data is discarded.
  const itemsToShow: IndexedItem[] = useMemo(() => {
    const customFilterBehavior =
      currentPath.length > 0
        ? currentPath[currentPath.length - 1].children?.filterBehavior
        : root.filterBehavior

    if (inputValue) {
      const defaultFilterOutput = filter(inputValue, {
        filter: itemIsNotHigherInHierarchy as (sr: SearchResult) => boolean,
      })
      const mathingDefaultFilterItems = defaultFilterOutput
        .map((result) => result.globalId as string)
        .map((id) => indexedItems.get(id))
        .filter(Boolean) as IndexedItem[]

      if (customFilterBehavior === "none") {
        // Fall through to no filter behavior
      } else if (customFilterBehavior) {
        const result = customFilterBehavior(
          inputValue,
          allItems.filter((item) => itemIsNotHigherInHierarchy(item)),
          currentPath,
          mathingDefaultFilterItems,
        )
        return result.flatMap((item) => {
          if ("groupId" in item) {
            return item.items.map((i) => ({
              ...i,
              group: item,
            }))
          }
          return [item]
        })
      } else {
        return mathingDefaultFilterItems
      }
    }

    // No input, show all items
    return pageToRender.items
      .flatMap((item) => {
        if ("groupId" in item) {
          if (item.collapsible) {
            if (item.collapsed || collapsedGroups[item.groupId]) {
              return []
            }
          }

          if (React.isValidElement(item)) return []

          return item.items
            .filter((i) => !React.isValidElement(i))
            .map((i) => {
              return indexedItems.get(
                uniqueIDForItem(currentPath, (i as ComboboxItem).id),
              )
            })
        }
        return [
          indexedItems.get(
            uniqueIDForItem(currentPath, (item as ComboboxItem).id),
          ),
        ]
      })
      .filter(Boolean) as IndexedItem[]
  }, [
    inputValue,
    indexedItems,
    pageToRender,
    currentPath,
    collapsedGroups,
    root.filterBehavior,
    allItems,
    filter,
    itemIsNotHigherInHierarchy,
  ])

  const itemsAndGroupsToRender = useMemo((): (
    | IndexedItem
    | IndexedItemGroup
    | React.ReactElement
  )[] => {
    if (inputValue) {
      // When search results are present we flatten all items and don't show them
      // as being part of a group.
      return itemsToShow
    }

    return pageToRender.items
      .map((item) => {
        if ("groupId" in item) {
          return {
            ...item,
            items: item.items.map((i) => {
              if (React.isValidElement(i)) {
                return i
              }
              return indexedItems.get(
                uniqueIDForItem(currentPath, (i as ComboboxItem).id),
              )
            }),
          }
        }
        if (React.isValidElement(item)) {
          return item
        }
        return indexedItems.get(
          uniqueIDForItem(currentPath, (item as ComboboxItem).id),
        )
      })
      .filter(Boolean) as IndexedItem[]
  }, [itemsToShow, inputValue, indexedItems, pageToRender, currentPath])

  // Highlighted item
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0)
  const highlightCause = useRef<"input" | "key" | "mouse" | "page-transition">(
    "page-transition",
  )
  const highlightItemAtIndex = useCallback(
    (index: number) => {
      let indexToHighlight = index
      if (index < 0) {
        indexToHighlight = itemsToShow.length - 1
      } else if (index >= itemsToShow.length) {
        indexToHighlight = 0
      }
      setHighlightedIndex(indexToHighlight)
    },
    [setHighlightedIndex, itemsToShow],
  )
  const highlightNextItem = useCallback(() => {
    highlightItemAtIndex(highlightedIndex + 1)
    highlightCause.current = "key"
  }, [highlightItemAtIndex, highlightedIndex])
  const highlightPreviousItem = useCallback(() => {
    highlightItemAtIndex(highlightedIndex - 1)
    highlightCause.current = "key"
  }, [highlightItemAtIndex, highlightedIndex])
  const highlightFirstItem = useCallback(() => {
    highlightItemAtIndex(0)
    highlightCause.current = "key"
  }, [highlightItemAtIndex])
  const highlightLastItem = useCallback(() => {
    highlightItemAtIndex(itemsToShow.length - 1)
    highlightCause.current = "key"
  }, [highlightItemAtIndex, itemsToShow])
  const highlightItem = useCallback(
    (
      item: IndexedItem,
      cause: "key" | "mouse" | "input" | "page-transition",
    ) => {
      const index = itemsToShow.findIndex((i) => i.globalId === item.globalId)
      if (index === -1) return
      highlightCause.current = cause
      highlightItemAtIndex(index)
    },
    [itemsToShow, highlightItemAtIndex],
  )
  useEffect(() => {
    if (highlightedIndex === 0) {
      // Used to ensure no delay on the usual case of highlighting the first item
      // on a page transition.
      ;(scrollRef || containerRef).current?.scrollTo(0, 0)
      return
    }

    if (pageTransitioning.current) {
      // Changing the highlighted index while the page is transitioning
      // casues animation glitches, so we delay the change until the
      // transition is complete.
      setTimeout(() => {
        if (!containerRef.current) return
        const element = containerRef.current.querySelector(
          `[data-index="${highlightedIndex}"]`,
        ) as HTMLElement
        element?.scrollIntoView({ block: "nearest" })
      }, transitionDelay)
    } else {
      if (!containerRef.current) return
      const element = containerRef.current.querySelector(
        `[data-index="${highlightedIndex}"]`,
      ) as HTMLElement
      if (highlightCause.current === "mouse") return
      element?.scrollIntoView({ block: "nearest" })
    }
  }, [highlightedIndex, scrollRef])
  useEffect(() => {
    // When items change, reset the highlighted index if needed
    if (highlightedIndex >= itemsToShow.length) {
      highlightItemAtIndex(0)
      highlightCause.current = "input"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsToShow])
  const temporaryHighlightItem = useRef<IndexedItem | undefined>(undefined)
  useEffect(() => {
    // When the active parent item changes, reset the highlighted index
    const page = activeParentItem
      ? indexedItems.get(activeParentItem)?.children
      : root
    if (!page) {
      highlightItemAtIndex(0)
    }

    if (temporaryHighlightItem.current) {
      // Set item after everything has settled
      // This could be improved
      setTimeout(() => {
        temporaryHighlightItem.current &&
          highlightItem(temporaryHighlightItem.current, "page-transition")
        temporaryHighlightItem.current = undefined
      }, 20)
    } else if (page?.defaultHighlightedValue) {
      const globalId = uniqueIDForItem(
        currentPath,
        page.defaultHighlightedValue,
      )
      const item = indexedItems.get(globalId)
      if (item) {
        // Set item after everything has settled
        // This could be improved
        setTimeout(() => highlightItem(item, "page-transition"), 20)
      } else {
        highlightItemAtIndex(0)
      }
    } else {
      highlightItemAtIndex(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeParentItem])
  useEffect(() => {
    // When the input value changes, reset the highlighted index
    if (temporaryHighlightItem.current) {
      return
    }

    highlightItemAtIndex(0)
    highlightCause.current = "input"

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  // Completion logic
  const onCompleteHandler = useCallback(() => {
    // Invoke the `onComplete` callback - this is useful for closing popovers
    onComplete?.()

    // Reset the path
    setActiveParentItem(undefined)

    // This is needed in case the parent item was already undefined
    setInputValue("")

    // This is needed in case the input value was already empty
    highlightItemAtIndex(0)
  }, [onComplete, highlightItemAtIndex, setInputValue])

  // Item selection
  const onItemSelect = useCallback(
    (
      e: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent,
      item?: IndexedItem,
    ) => {
      if (!item) return
      // Don't allow acitons if no items are shown
      if (itemsToShow.length === 0) return

      // If the item is disabled, do nothing
      if (item.disabled) return

      if (item.children && item.expandChildrenAs?.mode !== "menu") {
        // If the item has children, go down one level
        setActiveParentItem(item.globalId)
        return
      }

      if (item.onSelect) {
        if (item.disabled) return

        // If the item has an `onSelect` callback, invoke it
        void Promise.resolve(item.onSelect(e, item)).then((result) => {
          if (typeof result === "string") {
            setActiveParentItem(result)
            setInputValue("")
            return
          } else if (
            (!item.group?.selectionMode ||
              item.group.selectionMode === "radio") &&
            result !== false
          ) {
            // If the callback doesn't return `false`, invoke the `onComplete` callback
            onCompleteHandler()
            return
          }
        })
      } else if (
        item.children?.items &&
        item.children.items.length > 0 &&
        item.expandChildrenAs?.mode === "menu"
      ) {
        setMenuOpen(item.globalId)
      }

      // This covers the case where the user searches and an item that would ordinarily
      // be shown in a menu is shown in a combobox instead.
      const parent =
        item.path.length > 0 ? item.path[item.path.length - 1] : undefined
      if (parent && parent.expandChildrenAs?.mode === "menu") {
        if (item.path.length > 1) {
          const grandParent = item.path[item.path.length - 2]
          setActiveParentItem(grandParent.globalId)
          setInputValue("")
        } else {
          setActiveParentItem(undefined)
          setInputValue("")
        }
        temporaryHighlightItem.current = parent
      }

      // Refocus the input
      if (pageTransitioning.current) {
        setTimeout(() => {
          inputRef.current?.focus()
        }, transitionDelay)
      } else {
        inputRef.current?.focus()
      }
    },
    [onCompleteHandler, itemsToShow.length, setActiveParentItem, setInputValue],
  )

  const returnTo = useCallback((item?: IndexedItem) => {
    if (!item) {
      setActiveParentItem(undefined)
      return
    }

    if (item.children && item.expandChildrenAs?.mode === "menu") {
      if (item.path.length > 0) {
        const parent = item.path[item.path.length - 1]
        returnTo(parent)
        return
      } else {
        setActiveParentItem(undefined)
        return
      }
    }

    setActiveParentItem(item.globalId)
  }, [])

  // Keyboard handling
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (pageTransitioning.current) {
        // Don't handle keyboard events while loading
        return
      }

      switch (e.key) {
        case "ArrowDown":
          highlightNextItem()
          e.preventDefault()
          e.stopPropagation()
          break
        case "ArrowUp":
          highlightPreviousItem()
          e.preventDefault()
          e.stopPropagation()
          break
        case "Home":
          highlightFirstItem()
          e.preventDefault()
          e.stopPropagation()
          break
        case "End":
          highlightLastItem()
          e.preventDefault()
          e.stopPropagation()
          break
        case "Escape":
          e.preventDefault()
          e.stopPropagation()

          // If there is input, clear it
          if (inputValue) {
            setInputValue("")
            break
          }

          // If there is an active parent item, go up one level
          if (activeParentItem) {
            returnTo(indexedItems.get(activeParentItem)?.path.slice(-1)[0])
          }

          // Otherwise, invoke the `onComplete` callback
          // to close the combobox
          if (onComplete) {
            onComplete()
            break
          }
          break
        case "Backspace":
          // If the input is empty and there is an active parent item,
          // go up one level
          if (!inputValue && activeParentItem) {
            e.preventDefault()
            e.stopPropagation()

            returnTo(indexedItems.get(activeParentItem)?.path.slice(-1)[0])
          }
          break
        case "Enter":
          // Complete the path
          onItemSelect(e, itemsToShow[highlightedIndex])
          e.preventDefault()
          e.stopPropagation()
          break
        case "Tab": {
          const i = itemsToShow[highlightedIndex]
          if (!i) return

          if (
            i.children?.items &&
            i.children.items.length > 0 &&
            i.expandChildrenAs?.mode === "menu"
          ) {
            e.preventDefault()
            e.stopPropagation()
            setMenuOpen(itemsToShow[highlightedIndex].globalId)
          }
        }
      }
    },
    [
      highlightNextItem,
      highlightPreviousItem,
      highlightFirstItem,
      highlightLastItem,
      onItemSelect,
      onComplete,
      itemsToShow,
      highlightedIndex,
      inputValue,
      activeParentItem,
      indexedItems,
      returnTo,
      setInputValue,
    ],
  )

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (pageTransitioning.current) {
        // Don't handle keyboard events while loading
        return
      }

      switch (e.key) {
        case "ArrowRight": {
          const i = itemsToShow[highlightedIndex]
          if (!i) return

          if (
            inputRef.current &&
            inputRef.current.selectionStart === inputRef.current.value.length &&
            i.children?.items &&
            i.children.items.length > 0 &&
            i.expandChildrenAs?.mode !== "menu"
          ) {
            onItemSelect(e, itemsToShow[highlightedIndex])
            e.preventDefault()
            e.stopPropagation()
          } else if (
            inputRef.current &&
            inputRef.current.selectionStart === inputRef.current.value.length &&
            i.children?.items &&
            i.children.items.length > 0 &&
            i.expandChildrenAs?.mode === "menu"
          ) {
            e.preventDefault()
            e.stopPropagation()
            setMenuOpen(itemsToShow[highlightedIndex].globalId)
          }
          break
        }
        case "ArrowLeft": {
          if (
            inputRef.current &&
            inputRef.current.selectionStart === 0 &&
            inputRef.current.value.length === 0 &&
            activeParentItem
          ) {
            e.preventDefault()
            e.stopPropagation()

            returnTo(indexedItems.get(activeParentItem)?.path.slice(-1)[0])
          }
          break
        }
      }
    },
    [
      onItemSelect,
      itemsToShow,
      highlightedIndex,
      inputRef,
      pageTransitioning,
      activeParentItem,
      indexedItems,
      returnTo,
    ],
  )

  const getBreadcrumbProps = useCallback(
    (item: IndexedItem) => {
      return {
        key: item.globalId,
        onClick:
          activeParentItem !== item.globalId ? () => returnTo(item) : undefined, // Already active, do nothing
        "aria-current":
          activeParentItem === item.globalId ? ("page" as const) : undefined,
        "aria-live": "polite" as const,
        children: item.children?.breadcrumbTitle ?? item.title,
      }
    },
    [activeParentItem, returnTo],
  )

  const inputProps: InputProps = useMemo(
    () => ({
      ref: inputRef,
      onChange: (e) => setInputValue(e.target.value),
      onKeyDown: handleKeyDown,
      onKeyUp: handleKeyUp,
      value: inputValue,
      placeholder: pageToRender.prompt ?? root.prompt,
      role: "combobox",
      "aria-haspopup": "listbox",
      "aria-expanded": "true",
      "aria-autocomplete": "list" as const,
      "aria-controls": `${uniqueId}-listbox`,
      "aria-activedescendant":
        uniqueId + "/" + itemsToShow[highlightedIndex]?.globalId,
      "aria-busy": pageToRender.loading ? ("true" as const) : undefined,
      "data-1p-ignore": true,
      "data-lpignore": true,
      maxLength: pageToRender.maxInputLength,
    }),
    [
      handleKeyDown,
      handleKeyUp,
      itemsToShow,
      highlightedIndex,
      inputValue,
      uniqueId,
      pageToRender,
      root.prompt,
      setInputValue,
    ],
  )

  const containerProps = useMemo(
    (): ContainerProps => ({
      ref: containerRef,
      id: `${uniqueId}-listbox`,
      role: "listbox",
      "aria-busy": pageToRender.loading ? "true" : undefined,
    }),
    [uniqueId, pageToRender],
  )

  const getGroupProps = useCallback(
    (group: IndexedItemGroup) => {
      return {
        id: uniqueId + "/" + group.groupId,
        key: group.groupId,
        "aria-label": group.title,
        defaultOpen: true,
        open:
          group.collapsible && group.collapsed !== undefined
            ? !group.collapsed
            : !collapsedGroups[group.groupId],
        onOpenChange:
          group.collapsible && group.onCollapsedChange
            ? (open: boolean) => group.onCollapsedChange?.(group.groupId, !open)
            : (open: boolean) => setGroupCollapsed(group.groupId, !open),
        "aria-busy": pageToRender.loading ? ("true" as const) : undefined,
        "aria-multiselectable":
          group.selectionMode === "checkbox" || group.selectionMode === "switch"
            ? ("true" as const)
            : undefined,
        role: "group",
      }
    },
    [uniqueId, pageToRender, collapsedGroups, setGroupCollapsed],
  )

  const getItemProps = useCallback(
    (item: IndexedItem): ItemProps => {
      const index = itemsToShow.indexOf(item)

      return {
        id: uniqueId + "/" + item.globalId,
        key: item.globalId,
        "data-id": item.globalId,
        "data-index": index,
        "data-highlighted": index === highlightedIndex ? "true" : undefined,
        "aria-label": item.title,
        "aria-selected": item.selected ? "true" : undefined,
        "aria-disabled": item.disabled ? "true" : undefined,
        role: "option",
        tabIndex: -1,
        onMouseMove: () => highlightItem(item, "mouse"),
        onClick: (e) => onItemSelect(e, item),
      }
    },
    [highlightItem, onItemSelect, uniqueId, itemsToShow, highlightedIndex],
  )

  return {
    id: uniqueId,
    inputRef,
    inputValue,

    containerRef,

    getBreadcrumbProps,
    inputProps,
    containerProps,
    getGroupProps,
    getItemProps,

    initialTransition: pageTransitioningState && initialTransition,
    pageTransitioning: pageTransitioningState && !initialTransition,

    path: currentPath,
    clearPath,

    itemsAndGroups: itemsAndGroupsToRender,
    displayedItems: itemsToShow,
    loading: pageToRender.loading,

    highlightedIndex,

    menuOpen,
    setMenuOpen,
    showingSearchResults: inputValue.length > 0,
  }
}

type GlobalComboboxItemID = string
const uniqueIDForItem = (
  path: ComboboxItem[],
  itemId: string,
): GlobalComboboxItemID => {
  // Generate a unique ID for the item based on its path and own ID
  return path
    .map((p) => p.id)
    .concat(itemId)
    .join("/")
}

const flattenAndIndexV2 = (
  rootPage: ComboboxPage,
  activeItemID: GlobalComboboxItemID | undefined,
): Map<GlobalComboboxItemID, IndexedItem> => {
  const map = new Map<GlobalComboboxItemID, IndexedItem>()

  const processItems = (
    items: (ComboboxItem | ComboboxItemGroup | React.ReactElement)[],
    path: IndexedItem[],
    group?: ComboboxItemGroup,
  ): void => {
    items.forEach(
      (
        itemOrElement: ComboboxItem | ComboboxItemGroup | React.ReactElement,
      ) => {
        if (React.isValidElement(itemOrElement)) {
          return
        }

        const item = itemOrElement as ComboboxItem | ComboboxItemGroup
        if ("groupId" in item) {
          // It's a group, so we process its items
          return processItems(item.items, path, item)
        }

        if (!item.id) {
          console.warn(
            "Combo: An item was found without an ID. This item will not be indexed.",
          )
          return
        }

        const globalID = uniqueIDForItem(path, item.id)

        const indexedItem = {
          ...item,
          globalId: globalID,
          path: [...path],
          group: group,
          includeInSearchResults: true,
        }

        const positionOfActiveItemInPath = [...path, indexedItem].findIndex(
          (p) => p.globalId === activeItemID,
        )
        const activeItemFromPath =
          positionOfActiveItemInPath !== -1 && path[positionOfActiveItemInPath]

        if (
          item.maxSearchDepth !== undefined &&
          path.length > 0 &&
          activeItemID &&
          activeItemID !== globalID &&
          !activeItemID.startsWith(globalID) &&
          !activeItemFromPath
        ) {
          // The item has a max search depth, and this item is on a path,
          // but the parent could not be found. The active parent is on a different
          // path, so we skip this item.
          return
        }

        const activeDepth = positionOfActiveItemInPath + 1
        const itemDepth = path.length
        const depth = itemDepth - activeDepth

        if (item.maxSearchDepth !== undefined && item.maxSearchDepth < depth) {
          // The item has a max search depth, and this item is deeper than that.
          // We skip this item.
          return
        }

        // It's an item, construct its unique ID and add it to the map
        const parent = path.length > 0 ? path[path.length - 1] : undefined
        const includeInSearchResults =
          // Include in the search index only if the filter behavior is not "none"
          // AND any parents are not excluded from the search index
          !parent
            ? rootPage.filterBehavior !== "none"
            : parent.children?.filterBehavior !== "none" &&
              parent.includeInSearchResults !== false

        indexedItem.includeInSearchResults = includeInSearchResults

        map.set(globalID, indexedItem)

        // If the item has children, recursively process them as well
        if (item.children && item.children.items) {
          processItems(item.children.items, path.concat(indexedItem))
        }
      },
    )
  }

  // Start processing with the root page items
  processItems(rootPage.items, [])

  return map
}

export const hasPathPrefix = (
  path: IndexedItem[],
  prefix: IndexedItem[],
): boolean => {
  if (prefix.length > path.length) return false
  for (let i = 0; i < prefix.length; i++) {
    if (path[i].globalId !== prefix[i].globalId) return false
  }
  return true
}

export const pathsAreEqual = (
  path1: IndexedItem[],
  path2: IndexedItem[],
): boolean => {
  if (path1.length !== path2.length) return false
  for (let i = 0; i < path1.length; i++) {
    if (path1[i].globalId !== path2[i].globalId) return false
  }
  return true
}

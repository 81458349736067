import React, { FC } from "react"

/**
 * BoldSubstring makes part of its children bold if it matches a query.
 */
const BoldSubstring: FC<{ query?: string; children?: React.ReactNode }> = ({
  query,
  children,
}) => {
  if (!children) {
    return null
  }

  if (!query || typeof children !== "string") {
    return <>{children}</>
  }

  // Convert title and query to lowercase to make the search case-insensitive
  const startIndex = children.toLowerCase().indexOf(query.toLowerCase())

  // If the query doesn't exist in the title, return the original title
  if (startIndex === -1) {
    return <>{children}</>
  }

  // The part of the title before the query
  const beforeQuery = children.substring(0, startIndex)

  // The part of the title that matches the query
  const matchedQuery = children.substring(startIndex, startIndex + query.length)

  // The part of the title after the query
  const afterQuery = children.substring(startIndex + query.length)

  // Return the title, but with the query wrapped in a span that applies a bold font weight
  return (
    <>
      {beforeQuery}
      <span className="font-bold">{matchedQuery}</span>
      {afterQuery}
    </>
  )
}

export default BoldSubstring

"use client"

import React, { ComponentProps } from "react"
import { cn } from "@daybridge/cn"
import { DropdownMenuItem } from "@radix-ui/react-dropdown-menu"
import { Icon } from "../icon/Icon"
import { Avatar } from "../avatar/Avatar"
import BoldSubstring from "../bold-substring/BoldSubstring"
import { Checkbox } from "../checkbox/Checkbox"
import { ColorDot } from "../color-dot/ColorDot"

export type BaseMenuItemProps = {
  icon?: string | React.ReactNode
  avatar?: {
    src?: string
    name?: string
  }
  checked?: boolean
  colorDot?: boolean
  hue?: number
  description?: string
  children?: React.ReactNode
  disableBoldTitle?: boolean
  searchString?: string
}

type BaseMenuItemComponentProps = Omit<
  ComponentProps<typeof DropdownMenuItem>,
  "children" | "color"
> &
  BaseMenuItemProps

const BaseMenuItemFn = React.forwardRef(
  (
    props: BaseMenuItemComponentProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const {
      children,
      className,
      checked,
      icon,
      avatar,
      description,
      disableBoldTitle,
      hue,
      colorDot,
      onSelect,
      searchString = "",
      ...rest
    } = props

    return (
      <div
        ref={ref}
        className={cn(
          "flex flex-row items-start",
          "px-2.5 py-1.5",
          "text-base",
          "text-high-contrast",
          "group-aria-selected/item:bg-tint-light",
          "data-[highlighted=true]:bg-tint-light",
          "hover:bg-tint-light",
          "focus:bg-tint-light",
          "rounded-md",
          "transition-[background] duration-200",
          "cursor-pointer",
          "group/inner",
          className,
        )}
        aria-selected={false}
        {...rest}
      >
        {checked !== undefined && (
          <Checkbox
            checked={checked}
            className="w-4 mt-0.5 flex-shrink-0 mr-2"
          />
        )}
        {colorDot !== undefined && (
          <ColorDot
            hue={hue}
            className="dot w-2.5 mt-[5px] mr-2 flex-shrink-0"
          />
        )}
        {avatar ? (
          <Avatar
            className={cn("mr-2", "flex-shrink-0")}
            src={avatar.src}
            name={avatar.name}
            size={24}
          />
        ) : icon && typeof icon === "string" ? (
          <Icon
            name={icon}
            className={cn(
              "w-2.5 mt-[5px] mr-2.5",
              "flex-shrink-0",
              hue !== undefined
                ? "opacity-50 group-data-[highlighted]:/itemopacity-100 group-aria-selected/item:opacity-100 group-hover/inner:opacity-100 group-focus/inner-opacity-100 text-adaptive-primary"
                : "text-low-contrast group-data-[highlighted]/item:text-high-contrast group-aria-selected/item:text-high-contrast group-hover/inner:text-high-contrast group-focus/inner:text-high-contrast",
              "transition-color duration-200",
            )}
            style={
              {
                "--hue": hue,
              } as React.CSSProperties
            }
          />
        ) : icon ? (
          icon
        ) : null}
        {children && (
          <div className="flex flex-col items-start flex-1 truncate min-w-0">
            <div
              className={cn(
                "w-full truncate",
                avatar && !description ? "mt-0.5" : "",
              )}
            >
              {disableBoldTitle || typeof children !== "string" ? (
                children
              ) : (
                <BoldSubstring query={searchString}>{children}</BoldSubstring>
              )}
            </div>
            {description && (
              <div className="w-full pb-1 truncate text-sm text-medium-contrast">
                {description}
              </div>
            )}
          </div>
        )}
      </div>
    )
  },
)
BaseMenuItemFn.displayName = "BaseMenuItem"

export const BaseMenuItem = React.memo(BaseMenuItemFn) as typeof BaseMenuItemFn

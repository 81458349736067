import React, { ForwardRefExoticComponent } from "react"

export type ComboboxItemID = string
export type ComboboxGroupID = string

export type ComboboxPage = {
  // Items on the page
  items: (ComboboxItem | ComboboxItemGroup | React.ReactElement)[]

  // Whether the page should be treated as loading
  loading?: boolean

  // Text to show in the input when this page is visible
  prompt?: string

  // Title to show in the breadcrumb when this page is visible.
  // Not shown for root page.
  breadcrumbTitle?: string

  // The filter behaviour that should be used when this page is active. If
  // a filter behaviour is defined for this page then none the items on it
  // will be included in the default search index.
  filterBehavior?:
    | ((
        input: string,
        items: IndexedItem[],
        path: IndexedItem[],
        defaultFilterOutput: IndexedItem[],
      ) => (IndexedItem | IndexedItemGroup)[])
    | "none"
    | undefined

  /**
   * `maxInputLength` is an optional number that can be used to limit the number of characters
   * that can be entered into the input. If not provided, there is no limit.
   */
  maxInputLength?: number

  /**
   * `defaultHighlightedValue` is the ID of the item that should be highlighted by default when
   * the page is first loaded. If not provided, the first item will be highlighted.
   */
  defaultHighlightedValue?: string
}

export type ComboboxItemGroup = {
  // `groupId` is a unique identifier for each combobox item group.
  groupId: ComboboxGroupID

  // `title` is the title of the item group that will be displayed in the combobox.
  title?: string

  // `collapsible` determines whether or not the group can be collapsed.
  collapsible?: boolean

  // `collapsed` determines whether or not the group is collapsed
  collapsed?: boolean

  // `onCollapseToggle` is a callback that will be called when the group is collapsed or expanded
  onCollapsedChange?: (groupId: ComboboxGroupID, collapsed: boolean) => void

  // `onReorder` is a callback that will be called when the items in the group are reordered.
  // Leave undefined to disable reordering.
  onReorder?: (groupId: ComboboxGroupID, itemIds: string[]) => void

  // `selectionMode` determines how items in the group are selected, if applicable.
  // - Radio: A single tick next to one of the items in the group. Only one item can be selected.
  // - Checkbox: A checkbox next to each item in the group. Multiple items can be selected.
  // - Switch: A toggle switch next to each item in the group. Multiple items can be selected.
  selectionMode?: "radio" | "checkbox" | "switch"

  // `items` is an array of ComboboxItem representing the items in the group.
  items: (ComboboxItem | React.ReactElement)[]
}

export type ComboboxItem = {
  // Unique identifier for each combobox item.
  id: ComboboxItemID

  // `title` is the title of the item that will be displayed in the combobox,
  // it's also indexed for searching.
  title: string

  // Whether or not this item is disabled.
  disabled?: boolean

  // Whether the item should have a line through its title
  strikethrough?: boolean

  // Whether or not this item is selected.
  selected?: boolean

  // Callback for item selection
  // Can return false (meaning do not close)
  // Can return a string (meaning go to page with ID)
  onSelect?: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent,
    item: ComboboxItem,
    parent?: ComboboxItem,
    grandparent?: ComboboxItem,
  ) => void | false | string | Promise<void | false | string>

  // Child pages
  children?: ComboboxPage

  // `expandChildrenAs` determines how the children of this item should be displayed.
  // If left undefined, the children will be displayed the same way as the parent.
  // Ignored on root page.
  expandChildrenAs?: {
    mode: "menu" | "combobox"
    component: // Component to inject
    | ForwardRefExoticComponent<{
          root: ComboboxPage | undefined
          [key: string]: unknown
        }>
      | undefined
    icon?: string
  }

  // `searchStrings` is an optional array of strings that will be indexed for searching.
  searchStrings?: string[]

  // If `maxSearchDepth` is specified then this item will only show up in search results if
  // the indexer has to traverse at most this many levels of the item hierarchy to reach it.
  // - 0 means only show in search results if searching the same level as this item.
  // - 1 means only show in search results if searching the same level or one level
  //      above this item (the parent),
  // - Undefined means show in search results regardless of search depth.
  maxSearchDepth?: number
}

export type IndexedItem = ComboboxItem & {
  globalId: string
  path: IndexedItem[]
  group?: ComboboxItemGroup
  includeInSearchResults: boolean
}

export type IndexedItemGroup = Omit<ComboboxItemGroup, "items"> & {
  items: IndexedItem[]
}

"use client"

import React, { ComponentProps } from "react"
import { cn } from "@daybridge/cn"
import {
  useMarkToolbarButton,
  useMarkToolbarButtonState,
} from "@udecode/plate-utils"
import { Button } from "../../button/Button"
import { EditorToolbarToggleGroup } from "./EditorToolbarToggleGroup"
import { EditorToolbarToggleItem } from "./EditorToolbarToggleItem"

type EditorMarkToolbarButtonProps = Omit<
  ComponentProps<typeof EditorToolbarToggleItem>,
  "value"
> &
  Omit<React.HTMLAttributes<HTMLButtonElement>, "children"> & {
    clear?: string
    nodeType: string
    icon: string
  }

const EditorMarkToolbarButtonFn = React.forwardRef(
  (
    props: EditorMarkToolbarButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const { className, style, clear, nodeType, icon, ...rest } = props
    const state = useMarkToolbarButtonState({ clear, nodeType })
    const { props: buttonProps } = useMarkToolbarButton(state)

    return (
      <EditorToolbarToggleGroup type="single" asChild>
        <EditorToolbarToggleItem
          value="single"
          enabled={buttonProps.pressed}
          onClick={buttonProps.onClick}
          contentEditable={false}
          style={{ ...style, userSelect: "none" }}
          asChild
        >
          <Button
            ref={ref}
            {...rest}
            theme="adaptive"
            variant={buttonProps.pressed ? "solid" : "translucent"}
            icon={icon}
            className={cn("w-5", className)}
          />
        </EditorToolbarToggleItem>
      </EditorToolbarToggleGroup>
    )
  },
)
EditorMarkToolbarButtonFn.displayName = "EditorMarkToolbarButton"

export const EditorMarkToolbarButton = React.memo(
  EditorMarkToolbarButtonFn,
) as typeof EditorMarkToolbarButtonFn

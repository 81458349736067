"use client"

import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react"
import { DateTime } from "luxon"

// Type definitions for context values
type DateRangeRefContextType = {
  dateRangeRef: React.MutableRefObject<[DateTime, DateTime]>
  setDateRange: (newRange: [DateTime, DateTime]) => void
}
type DateRangeReadContextType = {
  dateRange: [DateTime, DateTime]
}
type DateRangeWriteContextType = {
  setDateRange: (newRange: [DateTime, DateTime]) => void
}

// Create the contexts
const DisplayedDateRangeReferenceContext = createContext<
  DateRangeRefContextType | undefined
>(undefined)
const DisplayedDateRangeReadContext = createContext<
  DateRangeReadContextType | undefined
>(undefined)
const DisplayedDateRangeWriteContext = createContext<
  DateRangeWriteContextType | undefined
>(undefined)

// Combined Provider component
export const DisplayedDateRangeProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [dateRange, setDateRangeState] = useState<[DateTime, DateTime]>([
    DateTime.now(),
    DateTime.now().plus({ days: 1 }),
  ])
  const dateRangeRef = useRef<[DateTime, DateTime]>(dateRange)

  const setDateRange = useCallback((newRange: [DateTime, DateTime]) => {
    setDateRangeState(newRange)
    dateRangeRef.current = newRange
  }, [])

  // Effect to keep ref in sync with state
  useEffect(() => {
    dateRangeRef.current = dateRange
  }, [dateRange])

  // Memoize context values to ensure stability
  const refContextValue = useMemo(
    () => ({ dateRangeRef, setDateRange }),
    [dateRangeRef, setDateRange],
  )
  const readContextValue = useMemo(() => ({ dateRange }), [dateRange])
  const writeContextValue = useMemo(() => ({ setDateRange }), [setDateRange])

  return (
    <DisplayedDateRangeReferenceContext.Provider value={refContextValue}>
      <DisplayedDateRangeWriteContext.Provider value={writeContextValue}>
        <DisplayedDateRangeReadContext.Provider value={readContextValue}>
          {children}
        </DisplayedDateRangeReadContext.Provider>
      </DisplayedDateRangeWriteContext.Provider>
    </DisplayedDateRangeReferenceContext.Provider>
  )
}

// Hooks for consuming the contexts
export const useDisplayedDateRangeReference = () => {
  const context = useContext(DisplayedDateRangeReferenceContext)
  if (!context) {
    throw new Error(
      "useDisplayedDateRangeReference must be used within a DisplayedDateRangeProvider",
    )
  }
  return context
}

export const useDisplayedDateRangeRead = () => {
  const context = useContext(DisplayedDateRangeReadContext)
  if (!context) {
    throw new Error(
      "useDisplayedDateRangeRead must be used within a DisplayedDateRangeProvider",
    )
  }
  return context
}

export const useDisplayedDateRangeWrite = () => {
  const context = useContext(DisplayedDateRangeWriteContext)
  if (!context) {
    throw new Error(
      "useDisplayedDateRangeWrite must be used within a DisplayedDateRangeProvider",
    )
  }
  return context
}

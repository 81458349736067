"use client"

import React from "react"
import { cn } from "@daybridge/cn"
import {
  Root,
  Trigger,
  Portal,
  Overlay,
  Content,
  DialogProps,
  DialogContentProps,
  DialogTriggerProps,
} from "@radix-ui/react-dialog"
import { ring } from "../_styles/ring"
import { elevated } from "../_styles/elevated"

export type ModalProps = DialogProps &
  Omit<DialogContentProps, "content"> &
  Omit<DialogTriggerProps, "content"> & {
    content: React.ReactNode
  }

const ModalFn = React.forwardRef(
  (props: ModalProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      children,
      className,
      content,

      // Root props
      defaultOpen,
      open,
      // eslint-disable-next-line @typescript-eslint/unbound-method
      onOpenChange,
      modal,

      // Content props
      onOpenAutoFocus,
      onCloseAutoFocus,
      onEscapeKeyDown,
      onPointerDownOutside,
      onInteractOutside,

      ...rest
    } = props
    return (
      <Root
        defaultOpen={defaultOpen}
        open={open}
        onOpenChange={onOpenChange}
        modal={modal}
      >
        {children && (
          <Trigger ref={ref} asChild {...rest}>
            {children}
          </Trigger>
        )}
        <Portal>
          <Overlay
            className={cn(
              "data-[state=open]:animate-[fade-in_75ms_ease-in-out]",
              "data-[state=closed]:animate-[fade-out_75ms_ease-in-out]",
              "fixed inset-0 bg-black/10 dark:bg-black/50",
              "overflow-auto",
            )}
          >
            <Content
              onOpenAutoFocus={onOpenAutoFocus}
              onCloseAutoFocus={onCloseAutoFocus}
              onEscapeKeyDown={onEscapeKeyDown}
              onPointerDownOutside={onPointerDownOutside}
              onInteractOutside={onInteractOutside}
              className={cn(
                "fixed top-[15vh] left-[50%] transform -translate-x-1/2",
                "w-fit",
                "max-w-[calc(100vw-2rem)]",
                "origin-radix-popover",
                elevated,
                "bg-elevated/95 dark:bg-elevated/95",
                className,
              )}
            >
              {content}
              <div className={ring} />
            </Content>
          </Overlay>
        </Portal>
      </Root>
    )
  },
)
ModalFn.displayName = "Modal"

export const Modal = React.memo(ModalFn) as typeof ModalFn

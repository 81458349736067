"use client"

import { ToastProvider as TP, ToastViewport } from "@radix-ui/react-toast"
import React, {
  createContext,
  ForwardedRef,
  RefObject,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import { v4 } from "uuid"
import { Toast, ToastParams, ToastTheme } from "./Toast"

// Context to pass down to the app
const toastMethodsContext = createContext<ToastMethods>({} as ToastMethods)
export const ToastMethodProvider = toastMethodsContext.Provider
export const useToast = () => useContext(toastMethodsContext)

export interface ToastMethods {
  neutral: (params: ToastParams) => Toast
  brandBlue: (params: ToastParams) => Toast
  brandPink: (params: ToastParams) => Toast
  success: (params: ToastParams) => Toast
  error: (params: ToastParams) => Toast
  info: (params: ToastParams) => Toast
  warning: (params: ToastParams) => Toast
  fromPromise: (
    promise: Promise<unknown>,
    loadingParams: Omit<
      ToastParams,
      "duration" | "icon" | "preventManualDismissal"
    >,
    successParams: ToastParams,
    errorParams: ToastParams,
  ) => Toast
  remove: (id: string) => void
}

interface ToastProviderProps {
  children: React.ReactNode
}

const ToastProviderFn: React.FC<ToastProviderProps> = (
  props: ToastProviderProps,
) => {
  const { children } = props

  // Keep track of current toasts
  const [toasts, setToasts] = useState<Toast[]>([])

  // Keep track of the corresponding DOM element for each toast. This is used
  // to calculate the height of each one.
  const [toastRefs, setToastRefs] = useState<
    Record<string, ForwardedRef<HTMLLIElement>>
  >({})

  // Whenever toasts changes, compute the cumulative height of each toast
  const heights = useMemo(() => {
    const heights: Record<string, number> = {}
    let totalHeight = 0
    for (const toast of toasts) {
      const ref = toastRefs[toast.id] as RefObject<HTMLLIElement>
      if (ref && ref.current) {
        const height = ref.current.clientHeight
        heights[toast.id] = totalHeight
        totalHeight += height + 8
      }
    }
    return heights
  }, [toasts, toastRefs])

  const onMount = useCallback(
    (id: string, ref: ForwardedRef<HTMLLIElement>) => {
      setToastRefs((prevRefs: Record<string, ForwardedRef<HTMLLIElement>>) => ({
        ...prevRefs,
        [id]: ref,
      }))
    },
    [],
  )

  const onClose = useCallback(
    (id: string) => {
      const node = toastRefs[id] as RefObject<HTMLLIElement>
      const callback = () => {
        setToasts((prevToasts) => prevToasts.filter((t) => t.id !== id))
        setToastRefs((prevRefs) => {
          const { [id]: _, ...rest } = prevRefs
          return rest
        })
      }

      // Sometimes the animationend event doesn't fire, so we set a timeout
      // to ensure the toast is definitely removed.
      const cancel = setTimeout(callback, 250)

      node.current?.addEventListener("animationend", () => {
        callback()
        // If the event does run, cancel the timeout
        clearTimeout(cancel)
      })
    },
    [toastRefs],
  )

  // Callback to append a new toast to the list
  const addToast = useCallback((theme: ToastTheme, params: ToastParams) => {
    const id = params.idempotencyKey || v4()
    const toast = { id, theme, ...params }

    setToasts((prevToasts) => {
      // If the toast is already in the list, replace it with the new one
      if (prevToasts.some((t) => t.id === id)) {
        return prevToasts.map((t) => (t.id === id ? toast : t))
      }
      return [toast, ...prevToasts]
    })
    return toast
  }, [])

  // Callback to replace a toast with new data
  const replaceToast = useCallback(
    (id: string, theme: ToastTheme, newToastData: ToastParams) => {
      setToasts((prevToasts) =>
        prevToasts.map((toast) =>
          toast.id === id ? { id: toast.id, theme, ...newToastData } : toast,
        ),
      )
    },
    [],
  )

  // Callback to remove a toast
  const removeToast = useCallback((id: string) => {
    setToasts((prevToasts) => prevToasts.filter((t) => t.id !== id))
  }, [])

  const neutral = useCallback(
    (params: ToastParams) => addToast("neutral", params),
    [addToast],
  )

  const brandBlue = useCallback(
    (params: ToastParams) => addToast("brandBlue", params),
    [addToast],
  )

  const brandPink = useCallback(
    (params: ToastParams) => addToast("brandPink", params),
    [addToast],
  )

  const success = useCallback(
    (params: ToastParams) => addToast("success", params),
    [addToast],
  )
  const error = useCallback(
    (params: ToastParams) => addToast("error", params),
    [addToast],
  )
  const info = useCallback(
    (params: ToastParams) => addToast("info", params),
    [addToast],
  )
  const warning = useCallback(
    (params: ToastParams) => addToast("warning", params),

    [addToast],
  )

  const fromPromise = useCallback(
    (
      promise: Promise<unknown>,
      loadingParams: ToastParams,
      successParams: ToastParams,
      errorParams: ToastParams,
    ) => {
      const loadingToast = addToast("loading", {
        ...loadingParams,
        duration: Infinity,
        preventManualDismissal: true,
      })

      promise
        .then(() => {
          // replace loading toast with success toast
          replaceToast(loadingToast.id, "success", {
            ...successParams,
            duration: successParams.duration || 4000, // Needed to overwrite the infinite duration
          })
        })
        .catch(() => {
          // replace loading toast with error toast
          replaceToast(loadingToast.id, "error", {
            ...errorParams,
            duration: errorParams.duration || 4000, // Needed to overwrite the infinite duration
          })
        })

      return loadingToast
    },
    [addToast, replaceToast],
  )

  const methods = useMemo(
    () => ({
      neutral,
      brandBlue,
      brandPink,
      success,
      error,
      info,
      warning,
      fromPromise,
      remove: removeToast,
    }),
    [
      neutral,
      brandBlue,
      brandPink,
      success,
      error,
      info,
      warning,
      fromPromise,
      removeToast,
    ],
  )

  return (
    <TP duration={4000}>
      <ToastMethodProvider value={methods}>
        {children}
        {toasts.map((toast, index) => (
          <Toast
            key={toast.id}
            {...toast}
            index={index}
            offset={heights[toast.id]}
            visible={index <= 3}
            onMount={(ref) => onMount(toast.id, ref)}
            onClose={() => onClose(toast.id)}
          />
        ))}
        <ToastViewport
          className="
            w-96 z-20
            fixed flex flex-col justify-end 
            overflow-visible
            bottom-0 right-0
            group/toaster
            hover:h-[var(--total-height)]
            !focus-visible:ring-0
          "
          style={
            {
              "--total-height": `${
                (heights[toasts[toasts.length - 1]?.id] || 0) + 8
              }px`,
            } as React.CSSProperties
          }
        />
      </ToastMethodProvider>
    </TP>
  )
}
ToastProviderFn.displayName = "ToastProvider"

export const ToastProvider = React.memo(
  ToastProviderFn,
) as typeof ToastProviderFn

"use client"

import { useRef, useCallback, useEffect } from "react"

export const useScrollInteraction = (
  scrollRef: React.RefObject<HTMLElement>,
  timeout = 1000,
  onScrollStop?: () => void,
): React.RefObject<boolean> => {
  const scrollingRef = useRef<boolean>(false)

  // Ref to hold the timeout for scrolling event
  const scrollTimeout = useRef<NodeJS.Timeout | undefined>(undefined)

  // Handle all scrolling events, including manual events
  const handleScroll = useCallback(() => {
    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current)
    }
    scrollingRef.current = true

    scrollTimeout.current = setTimeout(() => {
      scrollingRef.current = false
      onScrollStop?.()
    }, timeout)
  }, [timeout, onScrollStop])

  // Cleanup on unmount
  useEffect(() => {
    const current = scrollRef.current
    // Add event listeners
    if (current) {
      current.addEventListener("wheel", handleScroll, { passive: true })
    }

    return () => {
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current)
      }

      // Remove event listeners
      if (current) {
        current.removeEventListener("scroll", handleScroll)
      }
    }
  }, [scrollRef, handleScroll])

  return scrollingRef
}

"use client"

import React, { useCallback, useEffect, useMemo, useState } from "react"
import { cn } from "@daybridge/cn"
import { range } from "lodash"
import { VirtualItem, useVirtualizer } from "@tanstack/react-virtual"
import { useRefWithBackup } from "../_utils/useRefWithBackup"
import { useScrollInteraction } from "./useScrollInteraction"
import { VirtualizerScrollingContextProvider } from "./context/VirtualizerScrollingContext"

export type VirtualizedViewProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "height" | "children"
> & {
  orientation: "horizontal" | "vertical"
  onLandOnIndex: (index: number) => void
  onVisibleRangeChange?: (range: [number, number]) => void
  itemSizes: number[]
  windowSize?: React.CSSProperties["height" | "width"]
  shouldForceSnap?: (index: number) => boolean
  scrollPadding?: number
  edgeMargins?: number
  windowOffset?: number
  children: (items: VirtualItem[]) => React.ReactNode
}

const VirtualizedViewFn = React.forwardRef(
  (props: VirtualizedViewProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      itemSizes,
      orientation,
      windowSize = "100%",
      windowOffset = 0,
      shouldForceSnap,
      scrollPadding = 0,
      edgeMargins = 0,
      onLandOnIndex,
      onVisibleRangeChange,
      children,
      className,
      style,
      ...rest
    } = props

    // Only necessary to compute this once since it's used to determine
    // the offset on first render only
    const initialOffset = useMemo(() => {
      return itemSizes.slice(0, itemSizes.length / 2).reduce((a, b) => a + b, 0)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [initializationComplete, setInitializationComplete] = useState(false)

    const scrollRef = useRefWithBackup<HTMLDivElement>(ref)
    const getScrollElement = useCallback(() => {
      return scrollRef.current
    }, [scrollRef])

    const h = orientation === "horizontal"

    const virtualizer = useVirtualizer({
      count: itemSizes.length,
      getScrollElement,
      estimateSize: useCallback((index) => itemSizes[index], [itemSizes]),
      horizontal: h,
      initialOffset,
      initialRect: { width: 2500, height: 2500 },
      overscan: 5,
    })

    const onScrollStop = useCallback(() => {
      if (!virtualizer.range) {
        return
      }

      onLandOnIndex(virtualizer.range.startIndex)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onLandOnIndex])

    const scrollingRef = useScrollInteraction(scrollRef, 1000, onScrollStop)

    useEffect(() => {
      if (!virtualizer.range) {
        return
      }
      onVisibleRangeChange?.([
        virtualizer.range.startIndex,
        virtualizer.range.endIndex,
      ])

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      onVisibleRangeChange,
      virtualizer.range?.startIndex,
      virtualizer.range?.endIndex,
    ])

    useEffect(() => {
      virtualizer.measure()
      virtualizer.scrollToIndex(itemSizes.length / 2, { align: "start" })
      virtualizer.measure()

      if (!initializationComplete) {
        setInitializationComplete(true)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemSizes])

    const items = virtualizer.getVirtualItems()

    const itemStyles = useMemo(() => {
      return items.reduce((acc, item) => {
        acc[`--offset-${item.index}`] = `${
          scrollPadding +
          (initializationComplete ? item.start : item.start - initialOffset)
        }px`
        acc[`--size-${item.index}`] = `${item.size}px`
        return acc
      }, {} as Record<string, string>)
    }, [items, initializationComplete, initialOffset, scrollPadding])

    /**
     * Make CSS variables available to the children who may need them
     * for positioning or other calculations.
     */
    const styleWithVars = useMemo(
      () => ({
        "--window-size": windowSize,
        "--window-offset": `${windowOffset}px`,
        "--edge-margin": `${edgeMargins}px`,
        ...itemStyles,
        ...style,
      }),
      [style, windowSize, windowOffset, edgeMargins, itemStyles],
    )

    const totalSize = virtualizer.getTotalSize()
    const innerStyles = useMemo(
      () => ({
        width: h ? `${totalSize}px` : "100%",
        height: h ? "calc(var(--window-size))" : `${totalSize}px`,
        marginTop: h ? "var(--window-offset)" : 0,
        marginLeft: !h ? "var(--window-offset)" : 0,
        position: "relative" as const,
      }),
      [h, totalSize],
    )

    const priorSizes = useMemo(() => {
      return itemSizes.reduce((acc, _, i) => {
        if (i === 0) {
          return [0]
        }
        return [...acc, acc[i - 1] + itemSizes[i - 1]]
      }, [] as number[])
    }, [itemSizes])

    const snapAnchors = useMemo(() => {
      return range(0, itemSizes.length).map((i) => {
        const priorSize = priorSizes[i]
        return (
          <div
            key={i}
            data-index={i}
            style={{
              position: "absolute",
              top: h ? 0 : priorSize,
              left: h ? priorSize : 0,
              width: h ? itemSizes[i] : "100%",
              height: h ? "100%" : itemSizes[i],
            }}
            data-snap-always={shouldForceSnap ? shouldForceSnap(i) : false}
          />
        )
      })
    }, [h, priorSizes, itemSizes, shouldForceSnap])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const c = useMemo(() => children(items), [children, items, itemSizes])

    return (
      <VirtualizerScrollingContextProvider value={scrollingRef}>
        <div
          ref={scrollRef}
          className={cn(
            "w-full h-full",
            h ? "snap-x" : "snap-y",
            "snap-mandatory",
            "overflow-auto",
            "no-scrollbars",
            "will-change-scroll",
            className,
          )}
          style={styleWithVars}
          {...rest}
        >
          <div
            className="transition-[margin,height] duration-200 ease-in-out will-change-contents"
            style={innerStyles}
          >
            {c}

            <div
              className={cn(
                "absolute top-0 left-0 pointer-events-none",
                "transition-[height] duration-200 ease-in-out",
                h
                  ? [
                      "w-full h-[calc(var(--window-size)+var(--window-offset,0)+(2*var(--edge-margin,0)))]",
                    ]
                  : ["w-full h-full"],

                "[&>*]:snap-start [&>[data-snap-always=true]]:snap-always",
              )}
            >
              {snapAnchors}
            </div>
          </div>
        </div>
      </VirtualizerScrollingContextProvider>
    )
  },
)
VirtualizedViewFn.displayName = "VirtualizedView"

export const VirtualizedView = React.memo(
  VirtualizedViewFn,
) as typeof VirtualizedViewFn

import React from "react"

/**
 * `InputProps` define the property structure for a combobox input element.
 * It includes the essential HTML input attributes relevant to combobox functionality,
 * accessibility attributes for ARIA support, and a ref attribute for direct DOM access.
 */
export type InputProps = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  | "role"
  | "onChange"
  | "onKeyDown"
  | "onBlur"
  | "value"
  | "placeholder"
  | "maxLength"
  | "min"
  | "max"
  | "type"
> &
  Pick<
    React.AriaAttributes,
    | "aria-autocomplete"
    | "aria-controls"
    | "aria-activedescendant"
    | "aria-haspopup"
    | "aria-expanded"
    | "aria-busy"
  > &
  React.RefAttributes<HTMLInputElement>

/**
 * `ItemProps` encapsulate the properties for each item (list item) in the combobox.
 * It combines the standard list item attributes with ARIA attributes for accessibility
 * and additional data attributes (`data-id`, `data-index`) for managing state and selection.
 */
export type ItemProps = Pick<
  React.LiHTMLAttributes<HTMLLIElement>,
  "id" | "role" | "onMouseMove" | "onClick" | "tabIndex"
> &
  Pick<React.AriaAttributes, "aria-selected" | "aria-label" | "aria-disabled"> &
  React.RefAttributes<HTMLLIElement> & {
    key: string
    "data-id": string
    "data-index": number
    "data-highlighted"?: "true"
  }

/**
 * `ContainerProps` are used for defining properties of the container element
 * that wraps combobox items, typically a UL element. It includes relevant HTML attributes
 * for the list and ref attributes for direct manipulation of the DOM element.
 */
export type ContainerProps = Pick<
  React.HTMLAttributes<HTMLUListElement>,
  "id" | "role"
> &
  Pick<React.AriaAttributes, "aria-multiselectable" | "aria-busy"> &
  React.RefAttributes<HTMLUListElement>

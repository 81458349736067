"use client"

import React, { useCallback } from "react"
import { cn } from "@daybridge/cn"
import {
  Root,
  Trigger,
  Portal,
  Content,
  DropdownMenuProps,
  DropdownMenuTriggerProps,
  DropdownMenuContentProps,
  Item,
  Group,
  Separator,
  Label,
  CheckboxItem,
  Sub,
  SubTrigger,
  SubContent,
  Arrow,
} from "@radix-ui/react-dropdown-menu"
import { ComboboxItem } from "../combobox/ComboboxItem"
import { MenuItem, MenuItemGroup, MenuPage } from "../_types/menu"
import { Icon } from "../icon/Icon"
import { ring } from "../_styles/ring"
import { groupTitle } from "../_styles/group-title"
import { elevated } from "../_styles/elevated"

const contentStyle = cn(
  // Structure
  "min-w-[10rem] max-w-[20rem]",

  // Animation
  "origin-radix-dropdown-menu",

  "p-1",
  elevated,
)

const arrows = () => (
  <>
    <Arrow
      className="arrow-stroke text-elevated/80 stroke-tint-heavy"
      fill="currentColor"
      strokeWidth={3}
      offset={8}
    />
    <Arrow
      className="arrow-fill text-elevated relative -top-[2px] stroke-elevated"
      fill="currentColor"
      strokeWidth={3}
      offset={8}
    />
  </>
)

export type DropdownProps = DropdownMenuProps &
  DropdownMenuTriggerProps &
  DropdownMenuContentProps & {
    root?: MenuPage
    onContentKeyDown?: (event: React.KeyboardEvent) => void
  }

const DropdownFn = React.forwardRef(
  (props: DropdownProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      children,
      className,

      defaultOpen,
      open,
      // eslint-disable-next-line @typescript-eslint/unbound-method
      onOpenChange,
      modal,
      dir,

      loop = true,
      onCloseAutoFocus,
      onEscapeKeyDown,
      onPointerDownOutside,
      onFocusOutside,
      onInteractOutside,
      side,
      sideOffset = 4,
      align = "start",
      alignOffset,
      avoidCollisions,
      collisionBoundary,
      collisionPadding,
      arrowPadding = 16,
      sticky,
      hideWhenDetached,
      onContentKeyDown,

      onMouseDown,
      onMouseUp,
      onClick,

      root,
      ...rest
    } = props

    const onComplete = useCallback(() => {
      onOpenChange?.(false)
    }, [onOpenChange])

    if (!root) {
      return children
    }

    return (
      <Root
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
        modal={modal}
        dir={dir}
      >
        <Trigger ref={ref} {...rest} asChild>
          {children}
        </Trigger>
        <Portal>
          <Content
            className={cn(contentStyle, className)}
            loop={loop}
            onCloseAutoFocus={onCloseAutoFocus}
            onEscapeKeyDown={onEscapeKeyDown}
            onPointerDownOutside={onPointerDownOutside}
            onFocusOutside={onFocusOutside}
            onInteractOutside={onInteractOutside}
            side={side}
            sideOffset={sideOffset}
            align={align}
            alignOffset={alignOffset}
            avoidCollisions={avoidCollisions}
            collisionBoundary={collisionBoundary}
            collisionPadding={collisionPadding}
            arrowPadding={arrowPadding}
            sticky={sticky}
            hideWhenDetached={hideWhenDetached}
            onKeyDown={onContentKeyDown}
            updatePositionStrategy="always"
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onClick={onClick}
          >
            {renderItems(root.items, onComplete)}
            <div className={ring} />
            {arrows()}
          </Content>
        </Portal>
      </Root>
    )
  },
)
DropdownFn.displayName = "Dropdown"

export const Dropdown = React.memo(DropdownFn) as typeof DropdownFn

const renderItems = (
  items: (MenuItem | MenuItemGroup | React.ReactElement)[],
  onComplete?: () => void,
): (React.ReactElement | null)[] => {
  return items.map((item, index) => {
    if (React.isValidElement(item)) {
      return item
    } else if ("groupId" in item) {
      if (!item.items || item.items.length === 0) return null
      return (
        <Group key={index}>
          {/* Top Separator */}
          {index > 0 && (
            <Separator className={cn("h-px", "-mx-1 my-1", "bg-tint-light")} />
          )}

          {/* Group Title */}
          {item.title && (
            <Label className={groupTitle}>
              {item.icon && typeof item.icon === "string" && (
                <Icon name={item.icon} className={cn("w-2.5 h-2.5 mr-1")} />
              )}
              <div className="flex-1 truncate leading-none">{item.title}</div>
            </Label>
          )}
          {item.items.map((i) => {
            if (React.isValidElement(i)) return i
            return renderItem(i as MenuItem, item, onComplete)
          })}
          {/* Bottom Separator if needed */}
          {items.length > 1 &&
            index < items.length - 1 &&
            !("groupId" in items[index + 1]) && (
              <Separator
                className={cn("h-px", "-mx-1 my-1", "bg-tint-light")}
              />
            )}
        </Group>
      )
    } else {
      return renderItem(item as MenuItem)
    }
  })
}

const SubWrapper = React.forwardRef(function SubMenu(
  props: {
    children: React.ReactElement
    item: MenuItem
    onComplete?: () => void
  },
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { children, item, onComplete, ...rest } = props
  const expandAsOther =
    item.expandChildrenAs?.mode !== "menu" &&
    item.children &&
    item.expandChildrenAs?.component

  return (
    <Sub key={item.id}>
      <SubTrigger asChild ref={ref} {...rest}>
        {children}
      </SubTrigger>
      <Portal>
        <SubContent
          className={cn(contentStyle, expandAsOther && ["w-80", "p-0"])}
          sideOffset={-4}
          alignOffset={-4}
        >
          {expandAsOther &&
          item.expandChildrenAs?.mode !== "menu" &&
          item.children &&
          item.expandChildrenAs?.component ? (
            <item.expandChildrenAs.component
              root={item.children}
              onComplete={onComplete}
            />
          ) : (
            renderItems(item.children?.items || [])
          )}
          <div className={ring} />
          {arrows()}
        </SubContent>
      </Portal>
    </Sub>
  )
})

const renderItem = (
  item: MenuItem,
  group?: MenuItemGroup,
  onComplete?: () => void,
) => {
  let WrapperComponent
  if (item.children?.items && item.children.items.length > 0) {
    WrapperComponent = SubWrapper
  } else if (group?.selectionMode) {
    WrapperComponent = CheckboxItem
  } else {
    WrapperComponent = Item
  }

  return (
    <WrapperComponent
      key={item.id}
      item={item}
      onComplete={
        item.children?.items && item.children.items.length > 0
          ? onComplete
          : undefined
      }
      checked={item.selected}
      disabled={item.disabled}
      textValue={item.title}
      asChild
      onSelect={(event) => {
        void item.onSelect?.(
          event as unknown as
            | React.MouseEvent<HTMLLIElement, MouseEvent>
            | React.KeyboardEvent,
          item,
        )

        if (group?.selectionMode) {
          event.preventDefault()
        }
      }}
    >
      <ComboboxItem
        mode="controlled"
        title={item.title}
        description={item.description}
        icon={item.icon}
        disabled={item.disabled}
        theme={item.hue !== undefined ? "adaptive" : undefined}
        style={
          {
            "--hue": item.hue ?? undefined,
          } as React.CSSProperties
        }
        selectionMode={group?.selectionMode}
        aria-selected={group?.selectionMode && item.selected}
        hasSubMenu={!!item.children}
      />
    </WrapperComponent>
  )
}

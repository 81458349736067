import React from "react"
import { cn } from "@daybridge/cn"
import { VariantProps, cva } from "class-variance-authority"
import { Icon } from "../icon/Icon"
import { Checkbox } from "../checkbox/Checkbox"
import { Switch } from "../switch/Switch"
import BoldSubstring from "../bold-substring/BoldSubstring"
import { Label } from "../label/Label"
import { Avatar } from "../avatar/Avatar"

type ComboboxItemProps = React.HTMLAttributes<HTMLLIElement> & {
  mode?: "controlled" | "uncontrolled"
  title: string
  description?: React.ReactNode
  avatarUrl?: string | null
  avatarInitials?: string
  icon?: string | React.ReactNode
  rightContent?: React.ReactNode
  hasSubMenu?: boolean
  disabled?: boolean
  strikethrough?: boolean
  selectionMode?: "radio" | "checkbox" | "switch"
  path?: { id: string; title: string; hue?: number }[]
  searchStr?: string
  renaming?: boolean
  onRename?: (newName: string) => void
  onRenameCancel?: () => void
  maxNameLength?: number
  showDragHandle?: boolean
} & VariantProps<typeof comboboxItem> & {
    // Additional props here...
  }

const comboboxItem = cva(
  [
    "group/item",
    "relative",
    "flex flex-row items-center",
    "px-2.5 py-2",
    "text-base",
    "rounded-lg",
    "border border-transparent",
    "ring-inset ring-elevated/30",
    "transition-[background,color] duration-200 ease-in-out",
  ],
  {
    variants: {
      theme: {
        default: [],
        adaptive: [],
      },
      mode: {
        controlled: [],
        uncontrolled: [],
      },
      disabled: {
        true: ["opacity-50"],
      },
    },
    compoundVariants: [
      {
        mode: "controlled",
        theme: "default",
        className: [
          "text-medium-contrast data-[highlighted]:text-high-contrast",
          "data-[highlighted]:bg-tint-light",
          "data-[highlighted]:border-tint-light",
          "data-[highlighted]:ring-1",
        ],
      },
      {
        mode: "uncontrolled",
        theme: "default",
        className: [
          "text-medium-contrast hover:text-high-contrast focus-visible:text-high-contrast",
          "hover:bg-tint-light focus-visible:bg-tint-light",
          "hover:border-tint-light focus-visible:border-tint-light",
          "hover:ring-1 focus-visible:ring-1",
        ],
      },
      {
        mode: "controlled",
        theme: "adaptive",
        className: [
          "text-medium-contrast data-[highlighted]:text-adaptive-high-contrast",
          "data-[highlighted]:bg-adaptive-tint-light",
          "data-[highlighted]:border-adaptive-tint-light",
          "data-[highlighted]:ring-1",
        ],
      },
      {
        mode: "uncontrolled",
        theme: "adaptive",
        className: [
          "text-medium-contrast hover:text-adaptive-high-contrast focus-visible:text-adaptive-high-contrast",
          "hover:bg-adaptive-tint-light focus-visible:bg-adaptive-tint-light",
          "hover:border-adaptive-tint-light focus-visible:border-adaptive-tint-light",
          "hover:ring-1 focus-visible:ring-1",
        ],
      },
    ],
    defaultVariants: {
      theme: "default",
    },
  },
)

const ComboboxItemFn = React.forwardRef(
  (props: ComboboxItemProps, ref: React.ForwardedRef<HTMLLIElement>) => {
    const {
      children,
      className,
      mode = "uncontrolled",
      "aria-selected": ariaSelected,
      title,
      description,
      avatarUrl,
      avatarInitials,
      rightContent,
      icon,
      hasSubMenu,
      disabled,
      strikethrough,
      selectionMode,
      theme,
      path,
      searchStr,
      renaming,
      onRename,
      onRenameCancel,
      maxNameLength,
      showDragHandle,
      ...rest
    } = props

    const renameRef = React.useRef<HTMLInputElement>(null)
    // When the input is rendered, focus it
    React.useEffect(() => {
      if (renaming && renameRef.current) {
        setTimeout(() => {
          renameRef.current?.select()
          renameRef.current?.scrollIntoView()
        }, 10)
      }
    }, [renaming])

    return (
      // eslint-disable-next-line jsx-a11y/role-supports-aria-props, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
      <li
        ref={ref}
        {...rest}
        aria-selected={ariaSelected}
        aria-disabled={disabled}
        className={cn(comboboxItem({ theme, mode, disabled }), className)}
      >
        {selectionMode === "checkbox" && (
          <div
            className={cn(
              "self-stretch flex",
              description ? "items-start pt-px" : "items-center",
            )}
          >
            <Checkbox
              checked={ariaSelected === "true" || ariaSelected === true}
              className={cn(
                "w-[18px] h-[18px]",
                "pointer-events-none",
                "mr-2.5",
              )}
              theme={theme === "adaptive" ? "adaptive" : undefined}
              tabIndex={-1}
              aria-hidden={true}
              disabled={disabled}
            />
          </div>
        )}
        {renaming && onRename ? (
          <input
            ref={renameRef}
            type="text"
            className={cn(
              "flex flex-1",
              "h-5",
              "mr-2 -mt-px",
              "bg-elevated",
              "px-2",
              "ring-1 ring-inset",
              theme === "adaptive"
                ? "ring-adaptive-tint-heavy"
                : "ring-tint-heavy",
              "rounded-md",
              "pointer-events-auto",
              "placeholder:text-low-contrast",
            )}
            defaultValue={title}
            maxLength={maxNameLength}
            onClick={(e) => {
              e.stopPropagation()
            }}
            onBlur={() => {
              onRenameCancel?.()
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onRename(e.currentTarget.value)
                e.stopPropagation()
              } else if (e.key === "Escape") {
                onRenameCancel?.()
                e.stopPropagation()
              }
            }}
            placeholder={title}
          />
        ) : (
          <>
            {(icon || (searchStr && searchStr.length > 0)) && (
              <div className={cn("flex items-start", "self-stretch")}>
                {typeof icon === "string" ? (
                  <Icon
                    name={icon}
                    className={cn(
                      "w-3 h-3 mt-[4px]",
                      searchStr && searchStr.length > 0 && "ml-0.5 mr-2",
                      theme === "adaptive"
                        ? "text-adaptive-medium-contrast"
                        : [
                            "text-medium-contrast",
                            mode === "controlled"
                              ? "group-data-[highlighted]/item:text-high-contrast"
                              : [
                                  "group-hover/item:text-high-contrast",
                                  "group-aria-expanded/item:text-high-contrast",
                                ],
                          ],
                      "group-data-[highlighted]/item:animate-[wiggle_500ms_ease-in-out]",
                      "transition-[color] duration-200 ease-in-out",
                    )}
                  />
                ) : icon === undefined && !avatarUrl && !avatarInitials ? (
                  <div className={cn("w-3 h-3", "ml-0.5 mr-2")} />
                ) : (
                  icon
                )}
              </div>
            )}
            {(avatarUrl !== undefined || avatarInitials !== undefined) && (
              <div className={cn("flex items-start", "self-stretch", "mr-2")}>
                <Avatar
                  size={34}
                  src={avatarUrl ?? undefined}
                  name={avatarInitials || title}
                />
              </div>
            )}
            <div
              className={cn(
                "flex",
                "flex-col",
                "flex-1",
                (icon || searchStr) && !avatarUrl && !avatarInitials && "ml-2",
                "min-w-0",
              )}
            >
              <div
                className={cn(
                  "flex flex-row items-start",
                  "flex-wrap",
                  "cursor-default",
                  "truncate",
                  "-mb-1",
                )}
              >
                {path && path.length > 0
                  ? path.map((p) => (
                      <React.Fragment key={p.id}>
                        <Label
                          className={cn("text-sm", "max-w-[150px]", "mb-1")}
                          theme={p.hue !== undefined ? "adaptive" : undefined}
                          style={
                            {
                              "--hue": p.hue !== undefined ? p.hue : undefined,
                            } as React.CSSProperties
                          }
                        >
                          <BoldSubstring query={searchStr}>
                            {p.title}
                          </BoldSubstring>
                        </Label>
                        <Icon
                          name="ChevronRight"
                          className={cn(
                            "mt-[7px]",
                            "mx-1.5",
                            "w-1.5",
                            "text-low-contrast",
                          )}
                        />
                      </React.Fragment>
                    ))
                  : null}
                <span
                  className={cn(
                    "mb-1",
                    "max-w-full truncate",
                    strikethrough && [
                      "line-through",
                      "decoration-2",
                      theme === "adaptive"
                        ? "decoration-adaptive-medium-contrast/50 dark:decoration-adaptive-medium-contrast"
                        : "decoration-medium-contrast/50 dark:decoration-medium-contrast",
                    ],
                  )}
                >
                  <BoldSubstring query={searchStr}>{title}</BoldSubstring>
                </span>
              </div>
              {description && (
                <div
                  className={cn(
                    "text-medium-contrast text-xs",
                    "break-words",
                    "line-clamp-2",
                    "mb-0.5",
                    path && path.length > 0 && "mt-1",
                  )}
                >
                  <BoldSubstring query={searchStr}>{description}</BoldSubstring>
                </div>
              )}
            </div>
          </>
        )}

        {!renaming && rightContent}

        {selectionMode === "switch" && !renaming && !hasSubMenu ? (
          <Switch
            checked={ariaSelected === "true" || ariaSelected === true}
            className={cn("w-6", "pointer-events-none", "ml-6")}
            tabIndex={-1}
            theme={theme === "adaptive" ? "adaptive" : undefined}
            disabled={disabled}
          />
        ) : selectionMode === "radio" &&
          (ariaSelected === "true" || ariaSelected === true) ? (
          <Icon
            name={"Tick"}
            className={cn(
              "w-2.5 h-2.5",
              "ml-3 mr-2",
              theme === "adaptive"
                ? "text-adaptive-medium-contrast"
                : "text-medium-contrast",
              ariaSelected === "true" || ariaSelected === true
                ? "opacity-100 group-aria-disabled/item:opacity-50"
                : "opacity-5",
              "transition-opacity duration-200 ease-in-out",
            )}
          />
        ) : null}
        {showDragHandle && !renaming && (
          <Icon
            name="HandleVertical"
            className={cn(
              "w-2.5 h-2.5",
              "mx-2",
              "opacity-30 group-data-[highlighted]/item:opacity-100 group-hover/item:opacity-100",
              "cursor-grab",
              theme === "adaptive"
                ? "text-adaptive-low-contrast"
                : "text-medium-contrast",
              "transition-opacity duration-200 ease-in-out",
            )}
          />
        )}
        {hasSubMenu && !disabled && (
          <Icon
            name="ChevronRight"
            className={cn(
              "mr-1.5",
              "ml-5",
              "chevron w-2 h-2",
              "transition-[color,margin] duration-200 ease-in-out",
              theme === "adaptive"
                ? "text-adaptive-medium-contrast/80"
                : "text-medium-contrast",

              mode === "controlled"
                ? [
                    "group-data-[highlighted]/item:mr-0.5",
                    "group-data-[highlighted]/item:ml-6",
                    theme === "adaptive"
                      ? "group-data-[highlighted]/item:text-adaptive-medium-contrast"
                      : "group-data-[highlighted]/item:text-high-contrast",
                  ]
                : [
                    "group-hover/item:mr-0.5 group-aria-expanded/item:mr-0.5",
                    "group-hover/item:ml-6 group-aria-expanded/item:ml-6",
                    theme === "adaptive"
                      ? "group-hover/item:text-adaptive-medium-contrast group-aria-expanded/item:text-adapive-medium-contrast"
                      : "group-hover/item:text-high-contrast group-aria-expanded/item:text-high-contrast",
                  ],
            )}
          />
        )}
        {children}
      </li>
    )
  },
)
ComboboxItemFn.displayName = "ComboboxItem"

export const ComboboxItem = React.memo(ComboboxItemFn) as typeof ComboboxItemFn

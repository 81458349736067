"use client"

import React, {
  MutableRefObject,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
} from "react"
import { Root, Title, Description, Action, Close } from "@radix-ui/react-toast"
import { cn } from "@daybridge/cn"
import { cva } from "class-variance-authority"
import { Spinner } from "../spinner/Spinner"
import { Icon } from "../icon/Icon"

export type ToastTheme =
  | "neutral"
  | "loading"
  | "brandBlue"
  | "brandPink"
  | "success"
  | "warning"
  | "error"
  | "info"

const toastStyles = cva(
  [
    "w-80 absolute bottom-8 left-8 right-8 -z-[var(--index)]",
    "hidden data-[visible=true]:flex",
    "flex-col",
    "bg-elevated/90 backdrop-blur-sm",
    "group/toast",
    "pointer-events-auto",
    "rounded-xl ring ring-1 ring-inset",
    "shadow-sm dark:shadow-lg",
    "origin-bottom",
    "transition-all duration-100 ease-in-out",
    "-translate-y-[var(--y)] group-hover/toaster:-translate-y-[var(--y-expanded)]",
    "scale-[calc(-1_*_var(--index)_*_0.05_+_1)] ",
    "group-hover/toaster:scale-100",
    "data-[swipe=move]:transition-none",
    "data-[swipe=end]:transition-none",
    "data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)]",
    "data-[state=open]:data-[visible=true]:animate-[toast-fade-in-up_100ms_ease-in]",
    "data-[swipe=end]:animate-[toast-fade-out-right_100ms_ease-in]",
    "data-[state=closed]:animate-[toast-fade-out-right_100ms_ease-in]",
    "data-[swipe=end]:group-hover/toaster:animate-[toast-fade-out-right-expanded_100ms_ease-in]",
    "data-[state=closed]:group-hover/toaster:animate-[toast-fade-out-right-expanded_100ms_ease-in]",
  ],
  {
    variants: {
      theme: {
        neutral: [
          "bg-gradient-to-b from-object-highlight to-object",
          "text-medium-contrast",
          "ring-tint-heavy",
        ],
        loading: [
          "bg-gradient-to-b from-object-highlight to-object",
          "text-medium-contrast",
          "ring-tint-heavy",
        ],
        brandBlue: [
          "bg-gradient-to-b from-brand-blue-object-highlight to-brand-blue-object",
          "text-brand-blue-medium-contrast",
          "ring-brand-blue-tint-heavy",
        ],
        brandPink: [
          "bg-gradient-to-b from-brand-pink-object-highlight to-brand-pink-object",
          "text-brand-pink-medium-contrast",
          "ring-brand-pink-tint-heavy",
        ],
        success: [
          "bg-gradient-to-b from-state-success-object-highlight to-state-success-object",
          "text-state-success-medium-contrast",
          "ring-state-success-tint-heavy",
        ],
        warning: [
          "bg-gradient-to-b from-state-warning-object-highlight to-state-warning-object",
          "text-state-warning-medium-contrast",
          "ring-state-warning-tint-heavy",
        ],
        error: [
          "bg-gradient-to-b from-state-error-object-highlight to-state-error-object",
          "text-state-error-medium-contrast",
          "ring-state-error-tint-heavy",
        ],
        info: [
          "bg-gradient-to-b from-state-info-object-highlight to-state-info-object",
          "text-state-info-medium-contrast",
          "ring-state-info-tint-heavy",
        ],
      },
    },
    defaultVariants: {
      theme: "neutral",
    },
  },
)

// Each toast has a unique ID and a type
export type Toast = {
  id: string
  theme?: ToastTheme
} & ToastParams

// ToastParams are the controllable properties of a toast
export type ToastParams = {
  title: React.ReactNode
  message?: React.ReactNode
  icon?: string
  duration?: number
  action?: {
    label: string
    onClick: () => void
  }
  preventManualDismissal?: boolean
  onDismiss?: () => void
  idempotencyKey?: string
}

type ToastProps = Toast & {
  index: number
  offset: number
  visible: boolean
  onMount?: (ref: React.RefObject<HTMLLIElement>) => void
  onClose?: () => void
}

const ToastFn = React.forwardRef(
  (props: ToastProps, ref: React.ForwardedRef<HTMLLIElement>) => {
    const {
      id,
      theme,
      onClose,
      onMount,
      title,
      message,
      icon,
      duration,
      action,
      preventManualDismissal,
      onDismiss,
      index,
      offset,
      visible,
      ...rest
    } = props

    // Create a ref if none was passed in
    const innerRef = React.useRef<HTMLLIElement>(null)
    useImperativeHandle(ref, () => innerRef.current as HTMLLIElement)

    // When the toast is mounted, pass a ref back to the provider
    // so that we can keep track of each toast that is mounted and
    // measure their heights.
    useLayoutEffect(() => {
      onMount &&
        innerRef &&
        onMount(innerRef as MutableRefObject<HTMLLIElement>)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const preventDrag = useCallback(
      (e: Event) => {
        if (preventManualDismissal) {
          ;(e as MouseEvent).preventDefault()
        }
      },
      [preventManualDismissal],
    )

    return (
      <Root
        data-visible={visible}
        className={cn(toastStyles({ theme }))}
        style={
          {
            "--index": index,
            "--y": "calc(var(--index) * 12px)",
            "--y-expanded": `${offset}px`,
          } as React.CSSProperties
        }
        ref={innerRef}
        duration={duration}
        onOpenChange={(open) => {
          if (!open) {
            // OnDismiss is the user callback
            onDismiss && onDismiss()
            // Onclose is the internal system callback
            onClose && onClose()
          }
        }}
        {...rest}
        onSwipeStart={preventDrag}
        onSwipeMove={preventDrag}
        onSwipeEnd={preventDrag}
        onSwipeCancel={preventDrag}
      >
        <div className="px-4 py-3 flex-shrink-0 flex flex-row items-start space-x-3">
          {theme !== "loading" ? (
            <Icon
              name={icon || defaultIcon(theme)}
              className={cn(
                "w-3 mt-1",
                "flex-shrink-0 ",
                "animate-[wiggle_2s_ease-in-out]",
              )}
            />
          ) : (
            <Spinner className="w-3 mt-1 flex-shrink-0" />
          )}
          <div className="flex-1 flex flex-col">
            <Title className="text-base font-semibold">{props.title}</Title>
            {message && (
              <Description className="text-sm line-clamp-4 mb-0.5">
                {props.message}
              </Description>
            )}
          </div>
        </div>
        {action && (
          <Action
            onClick={action.onClick}
            altText={action.label}
            className={cn(
              "w-full h-8 ",
              "flex items-center justify-center ",
              "hover:bg-tint-light",
              "border-t border-tint",
              "text-sm",
              "rounded-b-xl",
              "text-high-contrast",
            )}
          >
            {action.label}
          </Action>
        )}
        {!preventManualDismissal && (
          <Close
            className={cn(
              "absolute -top-2 -right-2 ",
              "w-6 h-6 ",
              "hidden group-hover/toast:flex group-focus/toast:flex focus:flex",
              "items-center justify-center",
              "rounded-full ",
              "bg-elevated ",
              "border border-tint-heavy",
            )}
          >
            <Icon name="Cross" className="w-2.5 h-2.5 text-medium-contrast" />
          </Close>
        )}
      </Root>
    )
  },
)
ToastFn.displayName = "Toast"

const defaultIcon = (theme?: ToastTheme) => {
  switch (theme) {
    case "success":
      return "Tick"
    case "error":
      return "Cross"
    case "info":
      return "SpeechBubble"
    case "warning":
      return "Warning"
    case "brandBlue":
    case "brandPink":
      return "Star"
    default:
      return "SpeechBubble"
  }
}

export const Toast = React.memo(ToastFn) as typeof ToastFn

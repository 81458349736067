"use client"

import React, { useCallback, useEffect, useState } from "react"
import { DateTime } from "luxon"

/**
 * A React component that displays a relative time value based on a given DateTime object.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {DateTime} props.dateTime - The DateTime object to display as a relative time value.
 * @param {string} [props.justNowText="Just now"] - The text to display when the time difference is less than 30 seconds.
 * @param {string} [props.style="long"] - The style to use for displaying the relative time.
 *
 * @returns {string} The relative time value.
 */
export const RelativeTime = React.memo(
  ({
    dateTime,
    justNowText = "Just now",
    style = "long",
  }: {
    dateTime: DateTime
    justNowText?: string
    style?: "narrow" | "long" | "short"
  }) => {
    // Initialize state with the server-rendered relative time value.

    // Function to calculate the relative time string to display.
    const calculateRelativeTime = useCallback(() => {
      const currentTime = DateTime.local()
        .setZone(dateTime.zoneName)
        .setLocale(dateTime.locale)
      const diffInSeconds = Math.abs(
        currentTime.diff(dateTime, "seconds").seconds,
      )

      if (diffInSeconds < 10) return justNowText

      if (diffInSeconds < 60) {
        const roundedSeconds = Math.round(diffInSeconds / 5) * 5
        const roundedDateTime = currentTime.minus({ seconds: roundedSeconds })
        return roundedDateTime.toRelative({ base: currentTime, style })
      }

      return dateTime.toRelative({ base: currentTime, style })
    }, [dateTime, justNowText, style])

    const [relativeTime, setRelativeTime] = useState<string | null>(() => {
      return calculateRelativeTime()
    })

    useEffect(() => {
      // Function to calculate the dynamic refresh interval based on the time difference.
      const getRefreshInterval = () => {
        const diffInSeconds = Math.abs(
          DateTime.local().diff(dateTime, "seconds").seconds,
        )
        if (diffInSeconds < 60) return 2500
        if (diffInSeconds < 3600) return 30000
        if (diffInSeconds < 86400) return 600000
        return 3600000
      }

      // Function to update the relative time string in the state.
      const updateRelativeTime = () => {
        const newRelativeTime = calculateRelativeTime()
        setRelativeTime((prev) => {
          if (prev !== newRelativeTime) {
            return newRelativeTime
          }
          return prev
        })
      }

      const refreshInterval = getRefreshInterval()
      const intervalId = setInterval(updateRelativeTime, refreshInterval)

      // Clean up the interval when the component is unmounted or the dependencies change.
      return () => clearInterval(intervalId)
    }, [dateTime, justNowText, style, calculateRelativeTime])

    // Return the relative time value as a string.
    return <>{relativeTime}</>
  },
)
RelativeTime.displayName = "RelativeTime"

"use client"

import { cn } from "@daybridge/cn"
import React, { useEffect, useState } from "react"

interface ShortcutProps {
  children: React.ReactNode
  className?: string
  keyboard?: string | string[] | string[][]
}

const ShortcutFn = React.forwardRef(
  (props: ShortcutProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { children, className, keyboard, ...rest } = props

    // Create a reducer to keep track of which of the keys in the
    // `keyboard` prop are currently pressed.
    const [pressed, setPressed] = useState<string[]>([])

    useEffect(() => {
      if (!keyboard) {
        return
      }

      const keys1D = Array.isArray(keyboard) ? keyboard : [keyboard]
      if (!keys1D.length) {
        return
      }

      // Make 2D if not already
      const keys = (keys1D[0].length ? keys1D : [keys1D]) as string[][]

      // Loop over and register handlers for each key
      const cancel = keys.map((key) => {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (key.includes(event.key)) {
            setPressed((pressed) => [...pressed, event.key])
          }
        }

        const handleKeyUp = (event: KeyboardEvent) => {
          if (key.includes(event.key)) {
            setPressed([])
          }
        }

        document.addEventListener("keydown", handleKeyDown)
        document.addEventListener("keyup", handleKeyUp)

        return () => {
          document.removeEventListener("keydown", handleKeyDown)
          document.removeEventListener("keyup", handleKeyUp)
        }
      })

      return () => {
        cancel.forEach((fn) => fn())
      }
    }, [keyboard])

    // If keyboard is a string then allPressed is true if the string is
    // equal to the pressed keys, otherwise it's false.
    // If keyboard is an array then allPressed is true if the array
    // contains the pressed keys, otherwise it's false.
    // If keyboard is a 2D array then allPressed is true if any of the
    // 1D arrays in the 2D array contain the pressed keys, otherwise it's
    // false.
    const allPressed =
      keyboard === undefined
        ? false
        : typeof keyboard === "string"
        ? pressed.join("") === keyboard
        : Array.isArray(keyboard)
        ? keyboard.some((key) => {
            if (typeof key === "string") {
              return pressed.join("") === key
            } else if (Array.isArray(key)) {
              return key.every((k) => pressed.includes(k))
            } else {
              return false
            }
          })
        : false

    return (
      <kbd
        ref={ref}
        // Note that we use `em` units for the padding and margin so that the
        // size of the keyboard shortcut is relative to the font size of the
        // surrounding text, and everything scales nicely.
        className={cn(
          "inline-block",
          "text-brand-blue-medium-contrast",
          "border border-brand-blue-primary/50",
          allPressed
            ? "bg-brand-blue-primary/30 transform translate-y-[2px]"
            : "bg-brand-blue-primary/10 border-b-[3px]",
          "font-sans font-semibold",
          "shadow-sm",
          "py-[0.2em] px-[0.4em] mx-[0.15em]",
          "rounded-[0.5em]",
          "transition-all duration-50 ease-in",
          className,
        )}
        {...rest}
      >
        {children}
      </kbd>
    )
  },
)
ShortcutFn.displayName = "Shortcut"

export const Shortcut = React.memo(ShortcutFn) as typeof ShortcutFn

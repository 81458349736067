import { ToggleItem, ToolbarToggleItemProps } from "@radix-ui/react-toolbar"
import { VariantProps, cva } from "class-variance-authority"
import React from "react"

import { cn } from "@daybridge/cn"

type EditorToolbarToggleItemProps = React.HTMLAttributes<HTMLButtonElement> &
  ToolbarToggleItemProps &
  VariantProps<typeof editorToolbarToggleItem> & {
    // Additional props here...
  }

const editorToolbarToggleItem = cva("", {
  variants: {
    theme: {
      primary: [],
    },
    enabled: {
      true: [],
      false: [],
    },
  },
  defaultVariants: {
    theme: "primary",
  },
})

const EditorToolbarToggleItemFn = React.forwardRef(
  (
    props: EditorToolbarToggleItemProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const { children, className, theme, enabled, ...rest } = props

    return (
      <ToggleItem
        ref={ref}
        className={cn(editorToolbarToggleItem({ theme, enabled }), className)}
        {...rest}
      >
        {children}
      </ToggleItem>
    )
  },
)
EditorToolbarToggleItemFn.displayName = "EditorToolbarToggleItem"

export const EditorToolbarToggleItem = React.memo(
  EditorToolbarToggleItemFn,
) as typeof EditorToolbarToggleItemFn

"use client"

import React, { useCallback, useMemo, useRef } from "react"
import {
  ImageCropper,
  ImageCropperImperativeHandle,
} from "../image-cropper/ImageCropper"
import { Dropdown } from "../dropdown/Dropdown"
import { Avatar } from "../avatar/Avatar"
import { Button } from "../button/Button"

type AvatarPickerProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "children" | "onError" | "onSelect"
> & {
  // `onSelect` is called when the user selects an avatar. The dataUrl is the
  // base64 encoded image data.
  onSelect?: (dataUrl: Blob | null) => void

  // `onError` is called when an error occurs. The message is a human readable
  // error message.
  onError?: (message: string) => void

  size?: number

  avatar?: {
    size?: number
    name?: string
    src?: string
  }

  t: (
    key:
      | "cancel"
      | "crop_and_save"
      | "browser_cropping_error"
      | "size_error"
      | "upload_avatar"
      | "remove_avatar",
    args?: Record<string, string | number>,
  ) => string
}

/**
 * AvatarPicker is a component that allows the user to select an avatar.
 */
const AvatarPickerFn = React.forwardRef(
  (props: AvatarPickerProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      className,
      avatar,
      onSelect,
      onError,
      size = 512,
      t,
      ...rest
    } = props

    const inputRef = useRef<HTMLInputElement>(null)
    const cropperRef = useRef<HTMLDivElement & ImageCropperImperativeHandle>(
      null,
    )

    // Open UI file picker when the user clicks on the avatar
    const openFilePicker = useCallback(() => {
      inputRef.current?.click()
    }, [])

    // Callback when the user selects a file
    const onSelectFile = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
          const reader = new FileReader()
          reader.addEventListener("load", () => {
            if (!reader.result) return // todo: throw err
            const image = new Image()
            image.src = reader.result.toString()
            image.onload = () => {
              if (image.height < size || image.width < size) {
                onError?.(
                  t("size_error", {
                    size,
                  }),
                )
              } else {
                cropperRef.current?.crop(reader.result?.toString() || "")
              }
            }
            // Reset the input value so that the onChange handler is triggered
            // again when another file is selected.
            if (inputRef.current) inputRef.current.value = ""
          })
          reader.readAsDataURL(e.target.files[0])
        }
      },
      [onError, cropperRef, size, t],
    )

    return (
      <div
        ref={ref}
        className={`
          w-fit
          relative
          ${className || ""}
        `}
        {...rest}
      >
        <input
          tabIndex={-1} // Make sure we can't invoke the input manually.
          className="opacity-0 fixed pointer-events-none"
          ref={inputRef}
          type="file"
          accept="image/*"
          onChange={onSelectFile}
        />
        <Avatar {...avatar} role="button" onClick={openFilePicker} />
        <Dropdown
          side="right"
          align="start"
          root={useMemo(
            () => ({
              items: [
                {
                  id: "upload",
                  icon: "ImageSticky",
                  title: t("upload_avatar"),
                  onSelect: () => openFilePicker(),
                },
                {
                  id: "remove",
                  icon: "TrashEmpty",
                  title: t("remove_avatar"),
                  onSelect: () => onSelect?.(null),
                },
              ],
            }),
            [openFilePicker, onSelect, t],
          )}
        >
          <Button
            icon={avatar?.src ? "Pencil" : "Plus"}
            style={{
              width: avatar?.size ? avatar.size / 3.5 : 32,
            }}
            className="absolute bottom-0 right-0 ring-4 ring-surface"
          />
        </Dropdown>

        <ImageCropper
          ref={cropperRef}
          size={size}
          onSave={(image) => onSelect?.(image)}
          onError={onError}
          t={t}
        />
      </div>
    )
  },
)
AvatarPickerFn.displayName = "AvatarPicker"

export const AvatarPicker = React.memo(AvatarPickerFn) as typeof AvatarPickerFn

import { cn } from "@daybridge/cn"

export const elevated = cn(
  // Structure
  "relative",

  // Rounding
  "rounded-xl",

  // Background
  "backdrop-blur-lg backdrop-saturate-200",
  "bg-elevated/90",

  // Shadow
  "shadow-sm dark:shadow-xl",
  "ring-1 ring-tint-heavy dark:ring-background/70",

  // Animation
  "data-[state=open]:animate-[fade-and-scale-in_50ms_ease-in-out]",
  "data-[state=closed]:animate-[fade-and-scale-out_50ms_ease-in-out]",
)

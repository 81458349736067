"use client"

import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { DateTime } from "luxon"
import { range } from "lodash"
import { cn } from "@daybridge/cn"
import { VirtualItem } from "@tanstack/react-virtual"
import { TimeFormatterOptions } from "../_utils/TimeFormatterOptions"
import { VirtualizedView } from "./VirtualizedView"
import { TimeLabels } from "./TimeLabels"
import {
  useNavigationDateRead,
  useNavigationDateWrite,
} from "./context/NavigationDateContext"
import { useDisplayedDateRangeWrite } from "./context/DisplayedDateRangeContext"

export type TimelineVirtualizedViewItem = {
  key: string | number
  date: DateTime
  index: number
  active: boolean
  highlight: boolean
  collapsed?: boolean
}

type TimelineVirtualizedViewProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "children"
> & {
  now: DateTime
  formatter: (date: DateTime, options: TimeFormatterOptions) => string
  displayZones?: { id: string }[]
  count?: number
  dayWidth: number
  collapsedDayWidth: number
  dayIsCollapsed?: (date: DateTime) => boolean
  dayWidthOverrides?: Record<string, number>
  headerForDay?: (item: TimelineVirtualizedViewItem) => React.ReactNode
  trayContent?: (
    firstDateRendered: DateTime,
    lastDateRendered: DateTime,
  ) => React.ReactNode
  trayHeight?: number
  scrollPadding?: number
  timeZoneWidth?: number
  edgeMargins?: number
  beforeContent?: React.ReactNode
  afterContent?: React.ReactNode
  startDayOnHour?: number
  endDayOnHour?: number
  height?: React.CSSProperties["height"]
  children: (items: TimelineVirtualizedViewItem[]) => React.ReactNode
}

const TimelineVirtualizedViewFn = React.forwardRef(
  (
    props: TimelineVirtualizedViewProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const {
      now,
      formatter,
      displayZones,
      children,
      count = 180,
      height,
      headerForDay,
      dayWidth,
      collapsedDayWidth,
      dayIsCollapsed,
      dayWidthOverrides,
      trayContent,
      beforeContent,
      afterContent,
      edgeMargins = 0,
      timeZoneWidth = 48,
      scrollPadding,
      trayHeight = 0,
      startDayOnHour = 0,
      endDayOnHour = 24,
      ...rest
    } = props

    const { navigationDate, recentlyManuallyChanged } = useNavigationDateRead()
    const { setNavigationDate } = useNavigationDateWrite()

    const { setDateRange } = useDisplayedDateRangeWrite()

    const items = useMemo(() => {
      return range(-count / 2, count / 2).map((i) => {
        return navigationDate.startOf("day").plus({ days: i })
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigationDate.toISODate()])

    const itemCollapsedStatuses = useMemo(() => {
      return items.map((item) => {
        return dayIsCollapsed?.(item)
      })
    }, [items, dayIsCollapsed])

    const itemSizes = useMemo(() => {
      return items.map((date, index) => {
        return itemCollapsedStatuses[index]
          ? collapsedDayWidth
          : dayWidthOverrides?.[date.toISODate()] || dayWidth
      })
    }, [
      items,
      itemCollapsedStatuses,
      dayWidthOverrides,
      dayWidth,
      collapsedDayWidth,
    ])

    const onLandOnIndex = useCallback(
      (index: number) => {
        if (index === count / 2) {
          // Already centered
          return
        }
        setNavigationDate(items[index])
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [items],
    )

    const onVisibleRangeChange = useCallback(
      (range: [number, number]) => {
        setDateRange?.([items[range[0]], items[range[1]]])
      },
      [items, setDateRange],
    )

    const shouldForceSnap = useCallback(
      (index: number) => {
        return items[index].weekday === 1
      },
      [items],
    )

    // Make sure the current time is always visible on page load
    const currentTimeLabelRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
      if (!currentTimeLabelRef.current) {
        return
      }
      currentTimeLabelRef.current.scrollIntoView({
        block: "center",
      })
    }, [currentTimeLabelRef])

    const TOP_GUTTER_HEIGHT = 48 + trayHeight

    const render = useCallback(
      (virtualItems: VirtualItem[]) => {
        const mappedItems: TimelineVirtualizedViewItem[] = virtualItems.map(
          (item) => ({
            ...item,
            highlight:
              navigationDate.hasSame(items[item.index], "day") &&
              recentlyManuallyChanged,
            collapsed: itemCollapsedStatuses[item.index],
            active: now.hasSame(items[item.index], "day"),
            key: items[item.index].toISODate(),
            date: items[item.index],
          }),
        )

        return (
          <>
            <div
              className={cn(
                "sticky z-50 top-0 left-0",
                "w-full",
                "bg-surface",
                "transition-[height,margin] duration-200 ease-in-out",
              )}
              style={{
                height: `calc(var(--window-offset))`,
                marginTop: `calc(-1 * var(--window-offset))`,
                // 1px bottom drop shadow
                boxShadow: "0 1px 0 oklch(var(--color-tint-light) / 0.06)",
              }}
            >
              {trayContent?.(items[0], items[items.length - 1])}
              {headerForDay &&
                mappedItems.map((item) => {
                  return headerForDay(item)
                })}
            </div>

            {beforeContent}

            {displayZones && displayZones.length > 0 && (
              <div
                className={cn(
                  "sticky z-40 left-0",
                  "flex flex-row items-center justify-center",
                )}
                style={{
                  width: displayZones?.length * timeZoneWidth,
                  height: "100%",
                  top: 0,
                }}
              >
                {displayZones.map((zone, i) => (
                  <TimeLabels
                    key={zone.id}
                    startDayOnHour={startDayOnHour}
                    endDayOnHour={endDayOnHour}
                    className="flex-shrink-0 h-full"
                    formatter={formatter}
                    zone={zone.id}
                    baseDay={navigationDate}
                    labelRef={i === 0 ? currentTimeLabelRef : undefined}
                  />
                ))}
              </div>
            )}

            {afterContent}

            {children(mappedItems)}
          </>
        )
      },
      [
        children,
        items,
        formatter,
        now,
        displayZones,
        navigationDate,
        trayContent,
        beforeContent,
        afterContent,
        startDayOnHour,
        endDayOnHour,
        headerForDay,
        itemCollapsedStatuses,
        recentlyManuallyChanged,
        timeZoneWidth,
      ],
    )

    return (
      <VirtualizedView
        orientation="horizontal"
        itemSizes={itemSizes}
        edgeMargins={edgeMargins}
        onLandOnIndex={onLandOnIndex}
        onVisibleRangeChange={onVisibleRangeChange}
        shouldForceSnap={shouldForceSnap}
        windowSize={height}
        windowOffset={TOP_GUTTER_HEIGHT}
        scrollPadding={scrollPadding}
        ref={ref}
        {...rest}
      >
        {render}
      </VirtualizedView>
    )
  },
)
TimelineVirtualizedViewFn.displayName = "TimelineVirtualizedView"

export const TimelineVirtualizedView = React.memo(
  TimelineVirtualizedViewFn,
) as typeof TimelineVirtualizedViewFn

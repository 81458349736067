import { cn } from "@daybridge/cn"
import React from "react"

type ColorDotProps = Omit<React.HTMLAttributes<HTMLDivElement>, "children"> & {
  hue?: number
}

const ColorDotFn = React.forwardRef(
  (props: ColorDotProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { hue, className, style, ...rest } = props
    return (
      <div
        ref={ref}
        className={cn(
          "aspect-square",
          "rounded-full",
          "bg-adaptive-primary",
          "border border-tint",
          "hover:filter hover:brightness-110",
          "focus:filter focus:brightness-110",
          "transition-all duration-200 ease-in-out",
          className,
        )}
        style={
          {
            "--hue": hue,
            ...style,
          } as React.CSSProperties
        }
        {...rest}
      />
    )
  },
)
ColorDotFn.displayName = "ColorDot"

export const ColorDot = React.memo(ColorDotFn) as typeof ColorDotFn

"use client"

import React from "react"
import { Root, ToolbarProps } from "@radix-ui/react-toolbar"
import { cva, VariantProps } from "class-variance-authority"
import { cn } from "@daybridge/cn"
import { useEditorReadOnly } from "@udecode/plate-common"

type EditorToolbarProps = React.HTMLAttributes<HTMLDivElement> &
  ToolbarProps &
  VariantProps<typeof editorToolbar> & {
    // Additional props here...
  }

const editorToolbar = cva([
  "w-fit",
  "p-0.5",
  "bg-tint-light",
  "rounded-md",
  "ring-1 ring-tint-light ring-inset",
])

const EditorToolbarFn = React.forwardRef(
  (props: EditorToolbarProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { children, className, style, ...rest } = props
    const readOnly = useEditorReadOnly()

    if (readOnly) return null

    return (
      <Root
        ref={ref}
        contentEditable={false}
        style={{ ...style, userSelect: "none" }}
        className={cn(editorToolbar({ className }))}
        {...rest}
      >
        {children}
      </Root>
    )
  },
)
EditorToolbarFn.displayName = "EditorToolbar"

export const EditorToolbar = React.memo(
  EditorToolbarFn,
) as typeof EditorToolbarFn

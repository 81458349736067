import { cva, VariantProps } from "class-variance-authority"

import React from "react"
import { cn } from "@daybridge/cn"
import { Icon } from "../icon/Icon"

export type LabelProps = React.HTMLAttributes<HTMLSpanElement> &
  VariantProps<typeof label> & {
    icon?: string
  }

const label = cva(
  [
    "inline-flex flex-row items-center justify-start",
    "py-[0.2em] px-[0.5em]",
    "rounded-[0.4em]",
    "shadow-sm",
    "text-base",
    "truncate",
    "ring-1 ring-inset",
    "bg-gradient-to-b",
    "dark:text-white",
  ],
  {
    variants: {
      theme: {
        neutral: [
          "from-object-highlight to-object",
          "ring-tint-extra-heavy",
          "text-medium-contrast",
        ],
        adaptive: [
          "from-adaptive-object-highlight to-adaptive-object",
          "ring-adaptive-tint-extra-heavy",
          "text-adaptive-medium-contrast",
        ],
        "brand-blue": [
          "from-brand-blue-object-highlight to-brand-blue-object",
          "ring-brand-blue-tint-extra-heavy",
          "text-brand-blue-medium-contrast",
        ],
        "brand-pink": [
          "from-brand-pink-object-highlight to-brand-pink-object",
          "ring-brand-pink-tint-extra-heavy",
          "text-brand-pink-medium-contrast",
        ],
        success: [
          "from-state-success-object-highlight to-state-success-object",
          "ring-state-success-tint-extra-heavy",
          "text-state-success-medium-contrast",
        ],
        warning: [
          "from-state-warning-object-highlight to-state-warning-object",
          "ring-state-warning-tint-extra-heavy",
          "text-state-warning-medium-contrast",
        ],
        error: [
          "from-state-error-object-highlight to-state-error-object",
          "ring-state-error-tint-extra-heavy",
          "text-state-error-medium-contrast",
        ],
        info: [
          "from-state-info-object-highlight to-state-info-object",
          "ring-state-info-tint-extra-heavy",
          "text-state-info-medium-contrast",
        ],
      },
    },
    defaultVariants: {
      theme: "neutral",
    },
  },
)

const LabelFn = React.forwardRef(
  (props: LabelProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { children, className, theme, icon, ...rest } = props
    return (
      <div ref={ref} className={cn(label({ theme, className }))} {...rest}>
        {icon && <Icon name={icon} className={cn("w-[0.75em] mr-[0.5em]")} />}
        <div className="flex-1 truncate">{children}</div>
      </div>
    )
  },
)
LabelFn.displayName = "Label"

export const Label = React.memo(LabelFn) as typeof LabelFn
